import { ResolvedPatientGroup, ResolvedSurgeryTypeGroup, ScheduledSurgery } from '~/store/selectors'
import { isNotNullish, isNullish } from '~/utils/guards'

function isSurgeryInSurgeryTypeGroup(surgery: ScheduledSurgery, surgeryTypeGroup?: ResolvedSurgeryTypeGroup | null): boolean {
    return isNotNullish(surgery.surgeryType?.id) && isNotNullish(surgeryTypeGroup?.resolvedSurgeryTypes[surgery.surgeryType?.id])
}

function isSurgeryInPatientGroup(surgery: ScheduledSurgery, patientGroup?: ResolvedPatientGroup | null): boolean {
    return isSurgeryInSurgeryTypeGroup(surgery, patientGroup?.surgeryTypeGroup)
}

export function isSurgeryInAnyPatientGroup(surgery: ScheduledSurgery, patientGroups: ResolvedPatientGroup[]): boolean {
    return patientGroups.some(patientGroup => isSurgeryInPatientGroup(surgery, patientGroup))
}

/**
 * Returns a list of all surgeries that belong to the given patient group.
 * @param scheduledSurgeries a list of all surgeries booked in the block
 * @param patientGroup the patient group to compare with
 * @returns list of all surgeries that belong to the given patient group, or all surgeries if no patient group is given
 */
export function getSurgeriesBelongingToPatientGroup(scheduledSurgeries: ScheduledSurgery[], patientGroup?: ResolvedPatientGroup | null): ScheduledSurgery[] {
    return scheduledSurgeries.filter(surgery => isNullish(patientGroup) || isSurgeryInPatientGroup(surgery, patientGroup))
}

export function getPatientGroupToDisplay(surgery: ScheduledSurgery, patientGroup?: ResolvedPatientGroup | null): ResolvedPatientGroup | null {
    if (isNullish(patientGroup) || isNullish(patientGroup.surgeryTypeGroup)) {
        return null
    }

    if (patientGroup.surgeryTypeGroup.display_children === true) {
        for (const childSurgeryTypeGroup of patientGroup.surgeryTypeGroup.resolvedChildren) {
            if (isSurgeryInSurgeryTypeGroup(surgery, childSurgeryTypeGroup)) {
                return {
                    surgeryTypeGroup: childSurgeryTypeGroup,
                    ageGroup: patientGroup.ageGroup,
                }
            }
        }
    }

    if (isSurgeryInPatientGroup(surgery, patientGroup)) {
        return patientGroup
    }

    return null
}
