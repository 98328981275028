import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { isNotNullish } from '~/utils/guards'
import { Dictionary } from '~/utils/utils'

import { DipsSurgeryType, HospitalSurgeryType, selectEntities, SurgeryTypeGroup, SurgeryTypeGroupHierarchy } from './entities'
import { HospitalSurgeryTypeId } from './hospitalSurgeryTypes'
import { getSurgeryTypeGroupOutParentChildKey, SurgeryTypeGroupId } from './surgeryTypeGroups'

export type SurgeryTypeMetadata = {
    weight?: number
}

/**
 * Resolves the weight of each surgery type in the group, taking into account that the hierarchy may override the weight.
 */
function resolveSurgeryTypeGroup(
    surgeryTypeGroup: SurgeryTypeGroup,
    hierarchy: Dictionary<SurgeryTypeGroupHierarchy>,
    weight: number | null | undefined = undefined
): Record<HospitalSurgeryTypeId, SurgeryTypeMetadata> {
    const results: Record<HospitalSurgeryTypeId, SurgeryTypeMetadata> = {}

    for (const child of surgeryTypeGroup.children) {
        // Check if the hierarchy overrides the weight
        const key = getSurgeryTypeGroupOutParentChildKey(surgeryTypeGroup.id, child.id)
        weight = hierarchy[key]?.weight ?? weight

        // Recursively resolve the child
        const childResults = resolveSurgeryTypeGroup(child, hierarchy, weight)
        for (const hospitalSurgeryTypeId in childResults) {
            const metadata = childResults[hospitalSurgeryTypeId]
            if (metadata) {
                results[hospitalSurgeryTypeId] = metadata
            }
        }
    }

    return surgeryTypeGroup.surgeryTypes.reduce((acc, surgeryType) => {
        // Check if the weight was overridden in the hierarchy
        acc[surgeryType.hospital_surgery_type_id] = {
            weight: isNotNullish(weight) ? weight : surgeryType.weight,
        }
        return acc
    }, results)
}

export const selectSurgeryTypes = createSelector(
    selectEntities,
    ({ dipsSurgeryTypes, hospitalSurgeryTypes, surgeryTypeGroups, surgeryTypeGroupHierarchies }) => {
        const hierarchy = keyBy(surgeryTypeGroupHierarchies, hierarchy =>
            getSurgeryTypeGroupOutParentChildKey(hierarchy.parent_surgery_type_group_id, hierarchy.child_surgery_type_group_id)
        )

        const resolvedSurgeryTypeGroups: Record<SurgeryTypeGroupId, Record<HospitalSurgeryTypeId, SurgeryTypeMetadata>> = {}
        surgeryTypeGroups.reduce((resolved, surgeryTypeGroup) => {
            resolved[surgeryTypeGroup.id] = resolveSurgeryTypeGroup(surgeryTypeGroup, hierarchy)
            return resolved
        }, resolvedSurgeryTypeGroups)

        const resolvedHospitalSurgeryTypes: Record<HospitalSurgeryTypeId, Record<SurgeryTypeGroupId, SurgeryTypeMetadata>> = {}
        for (const _surgeryTypeGroupId in resolvedSurgeryTypeGroups) {
            const surgeryTypeGroupId = Number(_surgeryTypeGroupId)
            const hospitalSurgeryTypes = resolvedSurgeryTypeGroups[surgeryTypeGroupId]

            for (const hospitalSurgeryTypeId in hospitalSurgeryTypes) {
                const surgeryTypeId = Number(hospitalSurgeryTypeId)
                const metadata = hospitalSurgeryTypes[surgeryTypeId]
                if (metadata) {
                    const acc = resolvedHospitalSurgeryTypes[surgeryTypeId] || {}
                    acc[surgeryTypeGroupId] = metadata
                    resolvedHospitalSurgeryTypes[surgeryTypeId] = acc
                }
            }
        }

        const grouping = {
            unimported: [] as DipsSurgeryType[],
            deprecated: [] as HospitalSurgeryType[],
            orphans: [] as DipsSurgeryType[],
            inGroups: [] as DipsSurgeryType[],
        }

        for (const dipsSurgeryType of dipsSurgeryTypes) {
            const dipsId = Number(dipsSurgeryType.id)
            const hospitalSurgeryType = hospitalSurgeryTypes.find(hospitalSurgeryType => hospitalSurgeryType.hospital_surgery_type_id === dipsId)

            if (hospitalSurgeryType) {
                if (resolvedHospitalSurgeryTypes[dipsId]) {
                    grouping.inGroups.push(dipsSurgeryType)
                } else {
                    grouping.orphans.push(dipsSurgeryType)
                }
            } else {
                grouping.unimported.push(dipsSurgeryType)
            }
        }

        for (const hospitalSurgeryType of hospitalSurgeryTypes) {
            if (!dipsSurgeryTypes.find(dipsSurgeryType => Number(dipsSurgeryType.id) === hospitalSurgeryType.hospital_surgery_type_id)) {
                grouping.deprecated.push(hospitalSurgeryType)
            }
        }

        return {
            bySurgeryGroupId: (id: SurgeryTypeGroupId) => {
                return resolvedSurgeryTypeGroups[id]
            },
            byHospitalSurgeryTypeId: (id: HospitalSurgeryTypeId) => {
                return resolvedHospitalSurgeryTypes[id]
            },
            byGrouping: (status: 'unimported' | 'orphan' | 'in-groups'): DipsSurgeryType[] => {
                if (status === 'unimported') {
                    return grouping.unimported.sort((a, b) => Number(a.id) - Number(b.id))
                }
                if (status === 'orphan') {
                    return grouping.orphans.sort((a, b) => Number(a.id) - Number(b.id))
                }
                if (status === 'in-groups') {
                    return grouping.inGroups.sort((a, b) => Number(a.id) - Number(b.id))
                }
                return []
            },

            byDeprecatedHospitalSurgeryType: () => {
                return grouping.deprecated
            },
        }
    }
)
