import { Dayjs } from 'dayjs'

import { Id, TableCell } from '~/components'
import { day } from '~/utils/extendedDayjs'

export type DatePractitionerCell = {
    date: Dayjs
    practitionerId: number
}

export function selectedCellsToDatePractitionerCells(selectedCells: TableCell<{ id: Id }, { id: Id }>[]): DatePractitionerCell[] {
    return selectedCells.map(cell => ({
        date: day(cell.column),
        practitionerId: Number(cell.row),
    }))
}
