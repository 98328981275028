/**
 * @param totalPages total number of pages
 * @param selectedPage the selected page (one-based)
 * @returns an array containing the page numbers to display
 */
export function getPageNumbersToDisplay({ totalPages, selectedPage }: { totalPages: number; selectedPage: number }) {
    if (totalPages <= 7) {
        return Array.from({ length: totalPages }, (_, i) => i + 1)
    }

    const potentialPageNumbers = [1, selectedPage - 1, selectedPage, selectedPage + 1, totalPages]
    if (selectedPage < 5) {
        potentialPageNumbers.push(1, 2, 3, 4, 5)
    }
    if (selectedPage === totalPages - 3) {
        potentialPageNumbers.push(totalPages - 1)
    }
    if (selectedPage >= totalPages - 1) {
        potentialPageNumbers.push(totalPages - 2, totalPages - 3)
    }

    return [...new Set(potentialPageNumbers.filter(i => 0 < i && i <= totalPages))].sort((a, b) => a - b)
}
