import { Placement } from '@floating-ui/react'
import { ComponentProps } from 'react'

import { TextInput } from '../TextInput'
import { Option } from './OptionsList'

export function getFilteredOptions<T extends string | number>(options: Option<T>[], search: string) {
    return search.length > 0 ? options.filter(option => option.label.toLowerCase().includes(search.toLowerCase())) : options
}

export type SelectProps<T extends string | number> = {
    options: Option<T>[]
    placeholder?: string
    isLoading?: boolean
    allowClear?: boolean
    placement?: Placement
    size?: ComponentProps<typeof TextInput>['size']
}
