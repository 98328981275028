import { FilterDropdown, LocationFilter } from '~/components'
import { selectMasterSchedulerFilters } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

export const Filter = () => {
    const removeSingleFilter = useStore(state => state.appFilters.actions.removeSingleFilter)
    const emptyFilters = useStore(state => state.appFilters.actions.emptyFilters)
    const filters = useStore(selectMasterSchedulerFilters)

    return (
        <div className="flex items-center">
            <FilterDropdown
                clearFilters={emptyFilters}
                onDisplayItemRemove={removeSingleFilter}
                displayItems={filters.map(filter => ({ id: filter.id, label: filter.label })) ?? []}
            >
                <LocationFilter />
            </FilterDropdown>
        </div>
    )
}
