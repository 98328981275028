import { flip, offset, Placement, shift } from '@floating-ui/react'
import clsx from 'clsx'
import { ComponentProps, MouseEvent, useEffect, useRef, useState } from 'react'

import { CloseOutlined, DownOutlined } from '~/icons'
import { isNullish } from '~/utils/guards'

import { Popover } from '../../Popover'
import { Show } from '../../Show'
import { Spinner } from '../../Spinner'
import { Tag } from '../../Tag'
import { inputStyles, selectSize, TextInput } from '../TextInput'
import { Option, OptionsList } from './OptionsList'
import { getFilteredOptions } from './utils'

type Props = {
    values: string[]
    onChange: (value: string) => void
    options: Option<string>[]
    emptyFilter: () => void

    placeholder?: string
    isLoading?: boolean
    placement?: Placement
    size?: ComponentProps<typeof TextInput>['size']
}

export function MultipleSelect({ placeholder, emptyFilter, values, onChange, size = 'md', options, placement = 'bottom-start', isLoading }: Props) {
    const inputRef = useRef<HTMLInputElement>(null)

    const [search, setSearch] = useState('')
    const [open, setOpen] = useState(false)

    const filteredOptions = getFilteredOptions(options, search)

    useEffect(() => {
        if (open) requestAnimationFrame(() => inputRef.current?.focus())
    }, [open])

    useEffect(() => {
        function handleKeyDown(e: KeyboardEvent) {
            if (e.key === 'Backspace' && search === '' && open) {
                const lastValue = values[values.length - 1]

                if (lastValue) {
                    onChange(lastValue)
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
    }, [onChange, open, search, values])

    function handleChange(value: string) {
        onChange(value)
        inputRef.current?.focus()
        setSearch('')
    }

    function removeValues(e?: MouseEvent) {
        e?.stopPropagation()
        emptyFilter()
        setOpen(false)
    }

    return (
        <Popover
            open={open}
            onOpenChange={setOpen}
            placement={placement}
            middleware={[offset(5), shift({ padding: 8 }), flip()]}
            trigger={
                <div className={clsx(inputStyles, selectSize[size], 'flex w-full cursor-pointer items-center')} onClick={() => setOpen(true)}>
                    <Show condition={!isLoading} fallback={<Spinner size="sm" />}>
                        <div className="w-full truncate">
                            <div className="flex flex-wrap gap-1">
                                {values?.map(value => (
                                    <Tag key={value} defaultCursor>
                                        <div className="whitespace-normal">{options.find(option => option.value === value)?.label}</div>
                                        <CloseOutlined
                                            onClick={(e?: MouseEvent) => {
                                                e?.stopPropagation()
                                                onChange(value)
                                            }}
                                            className="h-4 w-4 cursor-pointer"
                                        />
                                    </Tag>
                                ))}

                                <input
                                    ref={inputRef}
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                    id="search_options"
                                    placeholder={placeholder}
                                    className="w-12 flex-1 focus:outline-none"
                                />
                            </div>
                        </div>

                        <Show condition={!isNullish(values) && values?.length !== 0} fallback={<DownOutlined className="h-5 w-5 shrink-0" />}>
                            <CloseOutlined onClick={removeValues} className="ml-1 h-5 w-5 shrink-0 cursor-pointer text-gray-700 hover:text-gray-400" />
                        </Show>
                    </Show>
                </div>
            }
        >
            <div className="max-h-56 w-72 overflow-auto">
                <OptionsList options={filteredOptions} values={values ?? null} onChange={handleChange} />
            </div>
        </Popover>
    )
}
