import { Dayjs } from 'dayjs'

const cache: Map<number, number> = new Map()

export function dayOfWeek(date: Dayjs): number {
    const key = date.valueOf()

    if (cache.has(key)) {
        return cache.get(key)!
    }

    const day = date.day()
    cache.set(key, day)

    return day
}
