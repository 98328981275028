import { useMemo } from 'react'

import { getPageItems } from './getPageItems'
import { getPageNumbersToDisplay } from './getPageNumbersToDisplay'

export function usePagination<T>(items: T[], selectedPage: number, itemsPerPage: number) {
    const totalItems = items.length
    const totalPages = Math.ceil(totalItems / itemsPerPage)

    const pageItems = useMemo(() => getPageItems(items, selectedPage, itemsPerPage), [items, selectedPage, itemsPerPage])
    const pageNumbersToDisplay = useMemo(() => getPageNumbersToDisplay({ totalPages, selectedPage }), [totalPages, selectedPage])

    const offsetStart = (selectedPage - 1) * itemsPerPage + 1
    const offsetEnd = Math.min(selectedPage * itemsPerPage, totalItems)

    return {
        selectedPage: selectedPage > totalPages ? totalPages : selectedPage,
        totalItems,
        totalPages,
        pageItems,
        pageNumbersToDisplay,
        offsetStart,
        offsetEnd,
    }
}
