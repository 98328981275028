import { memo } from 'react'
import { twMerge } from 'tailwind-merge'

const typeToStylesMap = {
    fuchsia: {
        none: 'text-fuchsia-500 border-fuchsia-500',
        some: 'text-fuchsia-500 border-fuchsia-500 bg-fuchsia-100',
        all: 'text-white border-fuchsia-500 bg-fuchsia-500',
    },
    emerald: {
        none: 'text-emerald-500 border-emerald-500',
        some: 'text-emerald-500 border-emerald-500 bg-emerald-100',
        all: 'text-white border-emerald-500 bg-emerald-500',
    },
    orange: {
        none: 'text-orange-400 border-orange-400',
        some: 'text-orange-400 border-orange-400 bg-orange-100',
        all: 'text-white border-orange-400 bg-orange-400',
    },
    indigo: {
        none: 'text-indigo-400 border-indigo-400',
        some: 'text-indigo-400 border-indigo-400 bg-indigo-100',
        all: 'text-white border-indigo-400 bg-indigo-400',
    },
    yellow: {
        none: 'text-yellow-400 border-yellow-400',
        some: 'text-yellow-400 border-yellow-400 bg-yellow-100',
        all: 'text-white border-yellow-400 bg-yellow-400',
    },
    sky: {
        none: 'text-sky-500 border-sky-500',
        some: 'text-sky-500 border-sky-500 bg-sky-100',
        all: 'text-white border-sky-500 bg-sky-500',
    },
    gray: {
        none: 'text-gray-400 border-gray-400',
        some: 'text-gray-400 border-gray-400 bg-gray-100',
        all: 'text-white border-gray-400 bg-gray-400',
    },
    blue: {
        none: 'text-blue-900 border-blue-900',
        some: 'text-blue-900 border-blue-900 bg-blue-100',
        all: 'text-white border-blue-900 bg-blue-900',
    },
    red: {
        none: 'text-red-400 border-red-400',
        some: 'text-red-400 border-red-400 bg-red-100',
        all: 'text-white border-red-400 bg-red-400',
    },
} as const
type ColorType = keyof typeof typeToStylesMap

type ButtonProps = JSX.IntrinsicElements['button']

type TagProps = {
    colorType: ColorType
    fillType: 'none' | 'some' | 'all'
    isLoading?: boolean
    children?: React.ReactNode
}

const baseStyles = 'w-full min-w-[64px] cursor-pointer rounded border py-0.5 font-medium'

export const ButtonTag = memo(({ colorType, fillType, className, children, isLoading = false, ...buttonProps }: TagProps & ButtonProps) => {
    const style = typeToStylesMap[colorType][fillType]

    return (
        <button
            className={twMerge(
                `${style} ${baseStyles} disabled:pointer-events-none disabled:opacity-50 ${isLoading ? 'animate-pulse' : ''} ${!!buttonProps.onClick ? 'active:scale-95' : ''}`,
                className
            )}
            {...buttonProps}
        >
            {children}
        </button>
    )
})
