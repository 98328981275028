export function subtractAll(originalSet: Set<number>, toBeRemovedSet: Set<number>) {
    toBeRemovedSet.forEach(obj => Set.prototype.delete(obj), originalSet)
    return originalSet
}
/** Clones the collection, but without the element at the specified index. */
export function filterAt<T>(collection: T[], indexToRemove: number): T[] {
    const result = [...collection]
    result.splice(indexToRemove, 1)
    return result
}
/** Finds an element in the collection matching the predicate and returns it. If not found, the element is added to the collection and returned. */
export function findOrAppend<T>(collection: T[], predicate: (element: T, index: number) => boolean, element: T): T {
    const index = collection.findIndex(predicate)
    const result = index >= 0 ? collection[index] : element
    if (result) {
        return result
    } else {
        collection.push(element)
        return element
    }
}

/**
 * Cluster a list of numbers into a string.
 *
 * For example:
 *
 * - [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] => '[1:10]'
 */
export function cluster(numbers: number[]): string {
    if (numbers.length === 0) return '[]'

    numbers = [...new Set(numbers.sort((a, b) => a - b))]

    const compressed: string[] = []
    let start = numbers[0]
    let end = start

    for (let i = 1; i < numbers.length; i++) {
        if (Number(numbers[i]) - Number(end) === 1) {
            end = numbers[i]
        } else {
            if (start === end) {
                compressed.push(Number(start).toString())
            } else {
                compressed.push(`${start}:${end}`)
            }

            start = numbers[i]
            end = start
        }
    }

    if (start === end) {
        compressed.push(Number(start).toString())
    } else {
        compressed.push(`${start}:${end}`)
    }

    return `[${compressed.join(',')}]`
}

/**
 * Array.includes wrapper with type narrowing.
 */
export function includes<U, T extends U>(arr: ReadonlyArray<T>, searchElement: U): searchElement is T {
    return arr.includes(searchElement as T)
}

type Groupable = Record<string, unknown>
type Iteratee<T> = (item: T) => string | string[]
type GroupingResult<T> = Record<string, T[]>
/**
 * Groups an array of items based on a specified iteratee function, which can return either a single string or an array of strings.
 * Each key in the resulting object represents a group, and its value is an array of items belonging to that group.
 *
 * @example
 * const data = [
 *   { name: 'Alice', locations: [{ city: 'New York' }, { city: 'Los Angeles' }] },
 *   { name: 'Bob', locations: [{ city: 'New York' }] },
 *   { name: 'Charlie', locations: [{ city: 'Los Angeles' }] },
 * ];
 *
 * // Group by multiple keys:
 * const result = groupByMany(data, (item) => item.locations.map((location) => location.city));
 *
 * // Result:
 * // {
 * //   'New York': [
 * //     { name: 'Alice', locations: [{ city: 'New York' }, { city: 'Los Angeles' }] },
 * //     { name: 'Bob', locations: [{ city: 'New York' }] },
 * //   ],
 * //   'Los Angeles': [
 * //     { name: 'Alice', locations: [{ city: 'New York' }, { city: 'Los Angeles' }] },
 * //     { name: 'Charlie', locations: [{ city: 'Los Angeles' }] },
 * //   ],
 * // }
 *
 * // Group by single key:
 * const resultSingle = groupByMany(data, (item) => item.name);
 *
 * // Result:
 * // {
 * //   'Alice': [{ name: 'Alice', locations: [{ city: 'New York' }, { city: 'Los Angeles' }] }],
 * //   'Bob': [{ name: 'Bob', locations: [{ city: 'New York' }] }],
 * //   'Charlie': [{ name: 'Charlie', locations: [{ city: 'Los Angeles' }] }],
 * // }
 */
export function groupByMany<T extends Groupable>(array: T[], iteratee: Iteratee<T>) {
    const result: GroupingResult<T> = {}

    array.forEach(item => {
        let keys = iteratee(item)

        if (!Array.isArray(keys)) {
            keys = [keys]
        }

        keys.forEach(key => {
            if (!result[key]) {
                result[key] = []
            }
            result[key]?.push(item)
        })
    })

    return result
}
