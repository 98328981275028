import { BaseInputTemplateProps } from '@rjsf/utils'

import { TextInput } from '../../Inputs/TextInput'

export const NumberInput = ({ id, onChange, value }: BaseInputTemplateProps) => (
    <TextInput
        className="w-0 flex-grow text-right"
        size="sm"
        placeholder="0"
        id={id}
        name={id}
        onChange={e => onChange(e.target.value)}
        value={value ?? ''}
        data-1p-ignore /* disable 1password prompt */
    />
)
