import { useState } from 'react'

import { DateLocationCell } from '~/app/pages/SurgeonScheduler/LocationTable/utils'
import { createLocationSchedule, updateLocationSchedule } from '~/store/entity.api'
import { LocationSchedule, selectGetLocationSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { format as dayjsFormat } from '~/utils/extendedDayjs'
import { HOSPITAL_TIMEZONE } from '~/utils/extendedDayjs/config'
import { getNonEmptyString } from '~/utils/utils'

type Props = { location: DateLocationCell }

const upsertComment = (selectedCell: DateLocationCell, locationSchedule?: LocationSchedule | null, newComment?: string) => {
    if (locationSchedule) {
        if (locationSchedule.comment === newComment) return

        return updateLocationSchedule(locationSchedule.id, {
            ...locationSchedule,
            comment: newComment,
        })
    }
    return createLocationSchedule({
        comment: newComment,
        location_id: selectedCell.locationId,
        tzid: HOSPITAL_TIMEZONE,
        all_day: true,
        start_time: dayjsFormat(selectedCell.date),
        end_time: dayjsFormat(selectedCell.date.add(1, 'day')),
    })
}

export const useLocationComments = ({ location }: Props) => {
    const locationSchedule = useStore(selectGetLocationSchedules).byCell(location)
    const [comment, setComment] = useState(locationSchedule?.comment ?? '')

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    // provides and override to allow explicit saving
    const updateComment = async (commentToSave?: string) => {
        const newComment = getNonEmptyString(commentToSave ?? comment)

        setIsLoading(true)
        setIsSuccess(false)

        try {
            await upsertComment(location, locationSchedule, newComment)
            setComment(newComment ?? '')
            setIsLoading(false)
            setIsSuccess(true)
        } catch (error) {
            console.error(error)
            setIsLoading(false)
            return
        }
    }

    const deleteComment = async () => {
        if (!locationSchedule) return
        const prompt = window.confirm('Er du sikker på at du vil fjerne kommentaren?')

        if (!prompt) return
        setIsLoading(true)
        setIsSuccess(false)

        try {
            setComment('')

            await updateLocationSchedule(locationSchedule.id, {
                ...locationSchedule,
                comment: undefined,
            })
            setIsLoading(false)
            setIsSuccess(true)
        } catch (error) {
            console.error(error)
            setIsLoading(false)
            return
        }
    }

    return { isLoading, isSuccess, updateComment, deleteComment, comment, setComment }
}
