import { useNavigate } from 'react-router-dom'

import { PageKey, pages, Path } from '~/app/routes'
import { RadioButtons } from '~/components'
import { useStore } from '~/store/store'
import { trackEvent } from '~/utils/analytics'

type Props = {
    pageKey: PageKey
}

export const ViewSwitcher = ({ pageKey }: Props) => {
    const navigate = useNavigate()
    const activeView = useStore(state => state.app.activeViews[pageKey])
    const pageViews = pages[pageKey].views
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    function handleOnchange(path: Path) {
        navigate(path)

        if (pageKey === 'WAITING_LIST') {
            setSelectedPage(1)
        }

        if (path === '/operational-planner/availability') {
            void trackEvent('OP_SWITCH_AVAILABLE_TIMES_VIEW__CLICK')
        }
    }

    return (
        <RadioButtons
            options={Object.values(pageViews).map(({ path, title }) => ({ label: title, value: path }))}
            value={activeView}
            onChange={path => handleOnchange(path)}
            size="md"
        />
    )
}
