import { PillsSelect } from '~/components'
import { selectStatusValues, Status, statusesMap } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

const statusOptions = Object.entries(statusesMap).map(([key, value]) => ({
    label: `${value.tooltip} (${value.label})`,
    value: key as Status,
}))

export const StatusFilter = () => {
    const statusValues = useStore(selectStatusValues)
    const toggleFilter = useStore(state => state.appFilters.actions.toggleFilter)

    return (
        <>
            <div className="hidden" data-test="status-filter" />
            <PillsSelect
                title="Aktiviteter"
                values={statusValues}
                options={statusOptions}
                onChange={({ value, label }) => toggleFilter({ type: 'status', value, label })}
            />
        </>
    )
}
