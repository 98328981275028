import { Dayjs } from 'dayjs'

import { add } from './addAndSubtract'
import { getFridayInWeek } from './getFridayInWeek'
import { isWeekend } from './isWeekend'

type GridMonthStartEndDays = {
    start: Dayjs
    end: Dayjs
}

/**
 * Retrieves the start and end dates of a grid month based on a given date.
 * The grid month consists of the weeks that have at least one weekday in the month.
 *
 * @param {Dayjs} date - The date used to determine the month.
 * @returns {GridMonthStartEndDays} - An object containing the start and end dates of the grid month.
 */
export function getGridMonthStartEndDays(date: Dayjs): GridMonthStartEndDays {
    const startOfMonth = date.startOf('month')
    const endOfMonth = date.endOf('month')

    const startOfGridMonth = isWeekend(startOfMonth) ? add(startOfMonth, 1, 'week').startOf('isoWeek') : startOfMonth.startOf('isoWeek')
    const endOfGridMonth = getFridayInWeek(endOfMonth)

    return {
        start: startOfGridMonth,
        end: endOfGridMonth,
    }
}
