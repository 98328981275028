import { MouseEventHandler, ReactNode } from 'react'

type Props = {
    label: string | ReactNode
    firstColumn?: boolean
    onClick?: MouseEventHandler
}

export const TableHeader = ({ label, onClick, firstColumn }: Props) => (
    <th
        scope="col"
        className={`sticky top-0 select-none whitespace-nowrap bg-white py-3 text-left font-semibold text-[#4138C2] ${firstColumn ? 'pl-4' : 'px-6'}`}
        onClick={onClick}
    >
        <span className="cursor-pointer hover:text-[#231AA2]">{label}</span>
    </th>
)
