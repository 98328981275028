import { Button, Show } from '~/components'
import { useAutoHide } from '~/hooks/useAutoHide'

type Props = {
    comment: string
    setComment: (comment: string) => void
    updateComment: () => void
    deleteComment: () => void
    isLoading: boolean
    isSuccess: boolean
}

export const Comment = ({ deleteComment, comment, setComment, updateComment, isSuccess, isLoading }: Props) => {
    const [showSavedMessage, setShowSavedMessage] = useAutoHide(1000)

    function handleSaveComment() {
        updateComment()
        setShowSavedMessage()
    }

    return (
        <div>
            <p className="!mb-1 font-medium">Kommentar</p>

            <textarea
                className="h-20 w-full rounded-lg border border-gray-300 p-2"
                placeholder="Skriv her"
                value={comment}
                onChange={e => setComment(e.target.value)}
            />

            <div className="flex items-center justify-end space-x-2">
                <Show condition={isSuccess && showSavedMessage}>
                    <span className="ml-2 font-medium text-green-500">Lagret</span>
                </Show>

                <Button disabled={isLoading} size="sm" color="delete" onClick={deleteComment}>
                    Fjerne
                </Button>
                <Button disabled={isLoading} size="sm" onClick={handleSaveComment}>
                    Lagre
                </Button>
            </div>
        </div>
    )
}
