import dayjs from 'dayjs'

import { AgeGroup, Tag, TagColor } from '~/components'
import { ClockCircleOutlined } from '~/icons'
import { OccupancyData } from '~/store/selectors'
import { useStore } from '~/store/store'
import {
    compareEvaluationsByPatientGroupNames,
    DurationRuleEvaluation,
    formatTotalKnifeTime,
    isCountBasedRuleEvaluation,
    isDurationRuleEvaluation,
    isOverbooked,
    RuleEvaluation,
} from '~/store/utils/blockEvaluation'
import { compareSurgeryTypeGroups } from '~/store/utils/resolvedPatientGroups'
import { formatDuration } from '~/utils/extendedDayjs'
import { isNotNullish } from '~/utils/guards'

function getOverbookedDuration(evaluation: DurationRuleEvaluation) {
    const maxTotalDuration = evaluation.maxValue?.asMilliseconds()
    const totalDurationUsed = evaluation.value.asMilliseconds()

    if (isNotNullish(maxTotalDuration)) {
        const totalDurationOverbooked = totalDurationUsed - maxTotalDuration
        return formatDuration(dayjs.duration(totalDurationOverbooked).toISOString())
    }
    return 'Ukjent tid'
}

const iconStyle = 'w-4 h-4'

type Props = {
    occupancyData: OccupancyData
    'data-test': string // of format "<room_code>-<yyyy-MM-dd>"
}

export const BookingsList = ({ occupancyData: { evaluations, mismatchedSurgeries, bookedSurgeries }, 'data-test': data_test }: Props) => {
    const featureFlags = useStore(state => state.featureFlags)

    function getE2EHelper(evaluation: RuleEvaluation) {
        return `${data_test}-${evaluation.status.toLocaleLowerCase()}`
    }

    function getTagColor(evaluation: RuleEvaluation): TagColor {
        if (featureFlags.operationalPlannerOverbookedCountEvaluations && isOverbooked(evaluation)) return 'overbooked'
        return isDurationRuleEvaluation(evaluation) ? 'no-bg' : 'primary'
    }

    const countEvaluations = evaluations.filter(isCountBasedRuleEvaluation)
    const durationEvaluations = evaluations.filter(isDurationRuleEvaluation)
    if (durationEvaluations.length > 1) {
        console.warn('Multiple duration evaluations')
    }
    const durationEvaluation = durationEvaluations.length === 1 ? durationEvaluations[0] : undefined
    const totalKnifeTime = formatTotalKnifeTime(bookedSurgeries)

    return (
        <div className="flex flex-col gap-2">
            <>
                {
                    // 1. Display count-based capacities
                    countEvaluations.sort(compareEvaluationsByPatientGroupNames).map((evaluation, key) => {
                        const surgeryCounts = Array.from(evaluation.value.entries())

                        if (surgeryCounts.length === 0) return null

                        const isOverbookedEvaluation = featureFlags.operationalPlannerOverbookedCountEvaluations && isOverbooked(evaluation)
                        return (
                            <div key={key} className="flex flex-wrap gap-2">
                                {surgeryCounts
                                    .map(([, data]) => data)
                                    .sort((a, b) => compareSurgeryTypeGroups(a.patientGroup, b.patientGroup))
                                    .map((data, index) => (
                                        <Tag
                                            color={getTagColor(evaluation)}
                                            data-test={`${getE2EHelper(evaluation)}-count`}
                                            key={index}
                                            data-tooltip={isOverbookedEvaluation ? 'Overbooket pasientgruppe' : undefined}
                                            data-tooltip-placement="left"
                                        >
                                            {isOverbookedEvaluation && <ClockCircleOutlined className={iconStyle} />}
                                            {data.bookingIds.length} {data.patientGroup.surgeryTypeGroup?.display_name}
                                            <AgeGroup ageGroupCode={data.patientGroup.ageGroup?.age_group_code} />
                                        </Tag>
                                    ))}
                            </div>
                        )
                    })
                }
            </>
            <>
                {
                    // 2. Display mismatched surgeries
                    mismatchedSurgeries.length > 0 && (
                        <div className="flex flex-wrap gap-2">
                            {mismatchedSurgeries.filter(isNotNullish).map((bookedSurgery, key) => (
                                <Tag data-test={`${data_test}-mismatched`} key={key} data-tooltip="Avvik fra kapasitetsplan" data-tooltip-placement="left">
                                    {bookedSurgery.surgeryType?.name}
                                </Tag>
                            ))}
                        </div>
                    )
                }
            </>
            <div className="flex gap-2">
                {
                    // 3. Display overbooked duration
                    durationEvaluation && isOverbooked(durationEvaluation) && (
                        <Tag
                            color="overbooked"
                            data-test={`${getE2EHelper(durationEvaluation)}-duration`}
                            data-tooltip="Overbooket tid"
                            data-tooltip-placement="left"
                        >
                            <ClockCircleOutlined className={iconStyle} />
                            {getOverbookedDuration(durationEvaluation)}
                        </Tag>
                    )
                }
                {
                    // 4. Display total knife time duration
                    totalKnifeTime && (
                        <Tag color="no-bg" data-test={`${(durationEvaluation && getE2EHelper(durationEvaluation)) || ''}-duration`}>
                            {`Knivtid: ${totalKnifeTime}`}
                        </Tag>
                    )
                }
            </div>
        </div>
    )
}
