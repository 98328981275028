import { getToday } from '~/utils/extendedDayjs'

import { Patient } from '../dipsApi'
import { AgeGroup } from '../selectors/entities'

export const getPatientAgeGroup = (patient: Patient, ageGroups: AgeGroup[]): AgeGroup | undefined => {
    const age = patient?.birthDate ? getToday().diff(patient.birthDate, 'year') : 0
    const ageGroup = ageGroups.find(group => {
        if (group.minimum_age && age >= group.minimum_age) return true
        if (group.maximum_age && age < group.maximum_age) return true
        else return false
    })
    // fallback to unspecified if no group is found
    return ageGroup ?? ageGroups.find(group => group.age_group_code === 'unspecified')
}
