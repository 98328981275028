import { Dayjs } from 'dayjs'

import { HolidayTag, Show } from '~/components'
import { selectActiveFilteredLocations, selectGetBlockSchedules, selectSurgeryTypeGroup } from '~/store/selectors'
import { useStore } from '~/store/store'
import { format, isHoliday } from '~/utils/extendedDayjs'

import { calculatePatientCounts, formatTotalCounts } from './utils'

type Props = {
    date: Dayjs
}

export const DayColumn = ({ date }: Props) => {
    const label = format(date, 'dddd DD.MM')

    const getSurgeryTypeGroup = useStore(selectSurgeryTypeGroup)
    const getBlockSchedules = useStore(selectGetBlockSchedules)
    const activeFilteredLocationIds = useStore(selectActiveFilteredLocations).map(location => location.id)

    const blockSchedules = getBlockSchedules.byDate(date)
    const patientCounts = calculatePatientCounts(blockSchedules, activeFilteredLocationIds, getSurgeryTypeGroup)

    const hasUnresolvedCounts = patientCounts?.unresolvedLocations ? patientCounts?.unresolvedLocations?.length > 0 : false

    return (
        <div className="flex flex-col gap-1">
            <div className="whitespace-nowrap capitalize">{label}</div>

            <Show condition={!isHoliday(date)} fallback={<HolidayTag />}>
                <div className="whitespace-nowrap text-xs text-indigo-700">{formatTotalCounts(patientCounts)} pasienter</div>
                <Show condition={hasUnresolvedCounts}>
                    <div className="whitespace-nowrap text-xs text-indigo-700">(ikke inkl. {patientCounts?.unresolvedLocations.join(', ')})</div>
                </Show>
            </Show>
        </div>
    )
}
