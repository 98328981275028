import clsx from 'clsx'
import { ComponentProps, forwardRef } from 'react'

export type TextInputSize = 'sm' | 'md' | 'lg'

export const inputSize: Record<TextInputSize, string> = {
    sm: 'px-2 py-0',
    md: 'px-3 py-1',
    lg: 'px-4 py-2',
}

// The chevron icon itself has 6px padding right & left, so we need to adjust the padding of the input accordingly
export const selectSize: Record<TextInputSize, string> = {
    sm: 'pl-2 pr-[2px] py-0',
    md: 'pl-3 pr-[6px] py-1',
    lg: 'pl-4 pr-[10px] py-2',
}

type Props = Omit<ComponentProps<'input'>, 'size'> & {
    size?: TextInputSize
}

export const inputStyles = clsx('rounded border shadow-sm hover:border-diBlue-700')

export const TextInput = forwardRef<HTMLInputElement, Props>(({ className, size = 'md', ...props }, ref) => (
    <input ref={ref} className={clsx(className, inputStyles, 'focus:border-diBlue-700 focus:outline-none', inputSize[size])} {...props} />
))
