import { Dayjs } from 'dayjs'

import { getOnlyDate } from '~/utils/extendedDayjs'
import { isNotNullish } from '~/utils/guards'

export function getDateKey(date: Dayjs | string | undefined | null): string {
    return isNotNullish(date) ? getOnlyDate(date) : 'dateless'
}

export function getDateAndLocationIdKey(date: Dayjs | string | undefined | null, locationId: number): string {
    return `${locationId} :: ${getDateKey(date)}`
}

export function getDateAndPractitionerIdKey(date: Dayjs | string | undefined | null, practitionerId: number): string {
    return `${practitionerId} :: ${getDateKey(date)}`
}
