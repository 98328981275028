import { OccupancyData } from '~/store/selectors'
import { OccupancyStatus, summarizeCapacityEvaluations } from '~/store/utils/blockEvaluation'

/** Gets whether the block evaluations have any booking. */
export function shallDisplayBookingList(data: OccupancyData): boolean {
    return data.mismatchedSurgeries.length !== 0 || data.evaluations.some(evaluation => evaluation && evaluation.bookingIds.length !== 0)
}

/** Gets whether the block evaluations have any availabilities. */
export function shallDisplayAvailabilitiesList(data: OccupancyData): boolean {
    return summarizeCapacityEvaluations(data.evaluations) === OccupancyStatus.Available
}
