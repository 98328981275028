import { flip, offset, shift } from '@floating-ui/react'
import clsx from 'clsx'
import { MouseEvent, useEffect, useRef, useState } from 'react'

import { Popover } from '~/components'
import { CloseOutlined, DownOutlined, SearchOutlined } from '~/icons'
import { isNullish } from '~/utils/guards'

import { Show } from '../../Show'
import { inputStyles, selectSize } from '../TextInput'
import { OptionsList } from './OptionsList'
import { getFilteredOptions, SelectProps } from './utils'

type Props<T extends string | number> = SelectProps<T> & {
    value?: T | null
    onChange: (value: T | null) => void
}

export function SingleSelect<T extends string | number>(props: Props<T>) {
    const { placeholder, value, onChange, options, size = 'md', placement = 'bottom-start', allowClear = false } = props
    const inputRef = useRef<HTMLInputElement>(null)

    const [search, setSearch] = useState('')
    const [open, setOpen] = useState(false)

    const filteredOptions = getFilteredOptions(options, search)

    useEffect(() => {
        if (open) requestAnimationFrame(() => inputRef.current?.focus())
    }, [open])

    function handleChange(value: T) {
        onChange(value)
        setOpen(false)
        setSearch('')
    }

    function emptyFilter(e?: MouseEvent) {
        e?.stopPropagation()
        onChange(null)
    }

    return (
        <Popover
            open={open}
            onOpenChange={setOpen}
            placement={placement}
            middleware={[offset(5), shift({ padding: 8 }), flip()]}
            trigger={
                <div className={clsx(inputStyles, selectSize[size], 'flex cursor-pointer items-center justify-between')} onClick={() => setOpen(true)} data-test="location-selector">
                    <div>{options.find(option => option.value === value)?.label ?? <span className="text-gray-500">{placeholder}</span>}</div>

                    <Show condition={allowClear && !isNullish(value)} fallback={<DownOutlined className="h-5 w-5 shrink-0" />}>
                        <CloseOutlined onClick={emptyFilter} className="ml-1 h-5 w-5 shrink-0 cursor-pointer text-gray-700 hover:text-gray-400" />
                    </Show>
                </div>
            }
        >
            <div>
                <div className="mb-2 flex items-center gap-2 border-b px-2 py-1">
                    <SearchOutlined className="h-5 w-5 shrink-0 text-gray-500" />
                    <input placeholder="Søk" ref={inputRef} value={search} onChange={e => setSearch(e.target.value)} className="w-full focus:outline-none" />
                </div>

                <div className="max-h-56 overflow-auto">
                    <OptionsList options={filteredOptions} values={isNullish(value) ? [] : [value]} onChange={handleChange} />
                </div>
            </div>
        </Popover>
    )
}
