import clsx from 'clsx'
import { createPortal } from 'react-dom'

type Props = {
    isOpen: boolean
    onClose: () => void
    children: React.ReactNode
}

export const Drawer = ({ isOpen, onClose, children }: Props) => {
    return createPortal(
        <div
            className={clsx('absolute left-0 top-0 z-50 h-full w-full bg-black/5', {
                'pointer-events-none hidden w-0': !isOpen,
            })}
            onClick={() => onClose()}
        >
            {isOpen && (
                <div
                    data-test="BookingDrawer"
                    className={clsx('absolute right-0 top-0 h-full w-[30rem] overflow-hidden bg-white shadow-2xl transition-transform', {
                        'translate-x-0': isOpen,
                        'translate-x-full': !isOpen,
                    })}
                    onClick={e => e.stopPropagation()}
                >
                    {children}
                </div>
            )}
        </div>,
        document.body
    )
}
