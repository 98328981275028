import { ReactNode } from 'react'

type Item = {
    icon: ReactNode
    element: ReactNode
    onClick?: () => void
}

type Props = {
    items: Item[]
}

export const ActionsList = ({ items }: Props) => (
    <ul className="z-10 flex w-full flex-col items-center justify-center bg-white py-2">
        {items.map(({ icon, element, onClick }, index) => (
            <li key={index} className="flex w-full cursor-pointer items-center gap-2 px-3 py-2 hover:bg-diBlue-100" onClick={onClick}>
                {icon}
                {element}
            </li>
        ))}
    </ul>
)
