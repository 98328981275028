import cn from 'clsx'
import { memo } from 'react'

type EntityListProps = {
    title: string
    children: React.ReactNode
}

export const EntityList = ({ children, title }: EntityListProps) => {
    return (
        <div className="flex flex-col gap-2 border p-2 shadow">
            <div className="text-center">{title}</div>
            <ul className="flex flex-col gap-2">{children}</ul>
        </div>
    )
}

type EntityItemProps = {
    onDelete: () => void
    onRename: () => void
    children: React.ReactNode
}

export const EntityItem = memo(({ children, onDelete, onRename }: EntityItemProps) => {
    const classNames = 'cursor-pointer rounded border border-gray-300 px-3 py-1 text-xs text-gray-500'

    return (
        <li className="flex items-center gap-1">
            <div className="grow pr-3">{children}</div>
            <div className={cn(classNames, 'hover:bg-red-500 hover:text-white')} onClick={onDelete}>
                Delete
            </div>
            <div className={cn(classNames, 'hover:bg-blue-500 hover:text-white')} onClick={onRename}>
                Rename
            </div>
        </li>
    )
})
