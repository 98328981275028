import { motion } from 'framer-motion'
import React from 'react'

export const LoadingCard = ({ fullHeight }: { fullHeight?: boolean }) => (
    <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.3, type: 'linear' }}
        className={`box-border animate-pulse-fast rounded bg-gradient-to-l from-slate-300 via-slate-100 to-slate-300 bg-large ${
            fullHeight ? 'h-full' : 'h-[240px]'
        }`}
    />
)
