import { ViewSwitcher } from '~/app/MainLayout/ViewSwitcher'

import { BarSearch } from './BarSearch'
import { Filters } from './Filters'

export const Header = () => (
    <div className="flex">
        <div className="justify-start px-2 py-2">
            <ViewSwitcher pageKey="WAITING_LIST" />
        </div>
        <div className="flex w-full" />
        <div className="justify-end px-2 py-2">
            <div className="flex gap-x-2">
                <BarSearch />
                <Filters />
            </div>
        </div>
    </div>
)
