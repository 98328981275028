import { Location, PractitionerScheduleLocation } from '~/store/selectors'
import { day, humanizeDate } from '~/utils/extendedDayjs'

import { ButtonTag } from '../../shared/ButtonTag'
import { DatePractitionerCell } from '../utils'
import { useToggleLocationSchedules } from './useToggleLocationSchedules'
import { createGetLocationStateFn } from './utils'

export const LocationTag = ($: { selectedCells: DatePractitionerCell[]; scheduleLocations: PractitionerScheduleLocation[]; location: Location }) => {
    const getLocationState = createGetLocationStateFn($.selectedCells, $.scheduleLocations)
    const { isLoading, toggleLocationSchedules } = useToggleLocationSchedules($.selectedCells, $.scheduleLocations)

    function getTooltip(locationId: Location['id']) {
        if ($.selectedCells.length !== 1) return

        const practitionerScheduleLocation = $.scheduleLocations.find(location => location.location_id === locationId)
        if (!practitionerScheduleLocation) return

        return `Sist endret av ${practitionerScheduleLocation.updated_by} ${humanizeDate(day(practitionerScheduleLocation.updated_at))}`
    }

    return (
        <ButtonTag
            disabled={isLoading}
            colorType="blue"
            fillType={getLocationState($.location.id)}
            isLoading={isLoading}
            onClick={() => toggleLocationSchedules($.location.id)}
            data-tooltip={getTooltip($.location.id)}
        >
            {$.location.room_code}
        </ButtonTag>
    )
}
