import { ViewSwitcher } from '~/app/MainLayout/ViewSwitcher'
import { Bar, DIDatePicker, Show } from '~/components'
import { useStore } from '~/store/store'

import { LocationTable } from './LocationTable/LocationTable'
import { PractitionerTable } from './PractitionerTable/PractitionerTable'
import { Filters } from './shared/Filters'

const SurgeonSchedulerPage = () => {
    const pageKey = 'SURGEON_SCHEDULER'
    const activeView = useStore(state => state.app.activeViews[pageKey])
    const isPractitionerView = activeView === '/surgeon-scheduler/practitioners'

    return (
        <div className="flex h-full flex-col gap-2 p-2">
            <Bar left={<ViewSwitcher pageKey={pageKey} />} center={<DIDatePicker unit="month" />} right={<Filters isPractitionerView={isPractitionerView} />} />

            <div className="overflow-hidden" data-test="surgeon-scheduler-page">
                <Show condition={isPractitionerView} fallback={<LocationTable />}>
                    <PractitionerTable />
                </Show>
            </div>
        </div>
    )
}

export default SurgeonSchedulerPage
