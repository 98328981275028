import { useContext, useEffect } from 'react'
import { AuthContext, AuthProvider, IAuthContext, TAuthConfig, TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce'

import { Show, Spinner } from '~/components'
import { useShowError } from '~/hooks/useShowError'
import { useStore } from '~/store/store'
import { isNullish } from '~/utils/guards'

import env from '../env'

const authConfig: TAuthConfig = {
    clientId: env.VITE_PKCE_CLIENT_ID,
    authorizationEndpoint: env.VITE_PKCE_AUTHORIZE_URL,
    tokenEndpoint: env.VITE_PKCE_TOKEN_URL,
    redirectUri: env.VITE_PKCE_REDIRECT_URL,
    scope: env.VITE_PKCE_SCOPES,
    logoutEndpoint: env.VITE_PKCE_LOGOUT_URL,
    onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => env.VITE_FF_REFRESH_TOKEN && event.login(),
}

// the react-oauth2-code-pkce library works so that this component gets rendered before and after the auth flow
// - in the before case the AuthProvider is about to do a redirect, so we don't show anything. Users seeing that for longer than a moment would constitute an error that we can't recover from. We could display text to try again.
// - in the after case, setting dipsApiAccessToken should make App not render this auth flow and thus show the actual app. We  display a "you're being redirected" message, because if something went wrong the user can recover by clicking the link
const Subcomponent = () => {
    const { token: dipsApiAccessToken, error, idToken } = useContext<IAuthContext>(AuthContext)
    const { setDipsApiAccessToken } = useStore(state => state.user.actions)

    useShowError(
        !env.VITE_PKCE_AUTHORIZE_URL || !env.VITE_PKCE_TOKEN_URL || !env.VITE_PKCE_REDIRECT_URL,
        'No dips access token (not received from API, and PKCE is not setup)'
    )

    useEffect(() => {
        if (dipsApiAccessToken !== '') {
            setDipsApiAccessToken({ dipsApiAccessToken, dipsApiAccessTokenIsFromPKCE: true, idTokenPKCE: idToken })
        }
    }, [dipsApiAccessToken, setDipsApiAccessToken, idToken])

    useShowError(!!error, error)

    return (
        <Show
            condition={!isNullish(dipsApiAccessToken) && error !== null}
            fallback={
                <div className="flex h-screen w-full items-center justify-center overflow-hidden">
                    <Spinner size="lg" />
                </div>
            }
        >
            <div className="m-10 text-lg font-normal">
                Pålogging var vellykket og du vil bli videreført umiddelbart. Hvis du ikke blir videreført automatisk, trykk &#8203;
                <div className="inline text-blue-600 underline hover:text-blue-800">
                    <a href={env.VITE_CLIENT_BASE_URL}>her</a>
                </div>
            </div>
        </Show>
    )
}

const PKCEAuthenticationFlow = () => {
    return (
        <AuthProvider authConfig={authConfig}>
            <Subcomponent />
        </AuthProvider>
    )
}

export function getPKCELogoutUrl(idTokenPKCE?: string) {
    if (!idTokenPKCE) console.error('idTokenPKCE is null')

    const post_logout_redirect_uri = encodeURIComponent(env.VITE_API_BASE_URL + '/oauth/logout')
    const id_token_hint = idTokenPKCE

    return `${env.VITE_PKCE_LOGOUT_URL}?id_token_hint=${id_token_hint}&post_logout_redirect_uri=${post_logout_redirect_uri}`
}

export function pkceClearLocalStorage() {
    localStorage.removeItem('ROCP_refreshToken')
    localStorage.removeItem('ROCP_idToken')
    localStorage.removeItem('ROCP_token')
    localStorage.removeItem('ROCP_tokenExpire')
    localStorage.removeItem('ROCP_loginInProgress')
    localStorage.removeItem('ROCP_refreshTokenExpire')
    localStorage.removeItem('PKCE_code_verifier')
}
export default PKCEAuthenticationFlow
