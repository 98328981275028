import { ObjectFieldTemplateProps } from '@rjsf/utils'

export const CustomObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
    if (props.idSchema.$id === 'root') {
        return (
            <div>
                {props.properties.map(element => (
                    <div key={element.name}>{element.content}</div>
                ))}
            </div>
        )
    }

    if (props.idSchema.$id === 'root_constraints') {
        return (
            <div className="flex flex-col">
                {props.properties.map(element => (
                    <div key={element.name} className="mt-2 w-full">
                        {element.content}
                    </div>
                ))}
            </div>
        )
    }

    if (props.properties.length === 1) {
        // Specific case for `Tilgjengelig tid`
        return <>{props.properties.map(element => element.content)}</>
    }

    return (
        <div className="grid grid-cols-12 gap-x-2">
            {props.properties.map((element, index) => (
                <div key={element.name} className={index === 0 ? 'col-span-3' : 'col-span-9 col-start-4'}>
                    {element.content}
                </div>
            ))}
        </div>
    )
}
