import { useCallback, useState } from 'react'

import { Schemas } from '~/clients/api-client'
import { createDepartmentLocationAssignment, createLocation, deleteLocation, updateLocation } from '~/store/entity.api'
import { selectEntities } from '~/store/selectors'
import { useStore } from '~/store/store'

import AddNewButton from './AddNewButton'
import { EntityItem, EntityList } from './EntityList'

type Props = {
    departmentId: number | 'uncategorized'
}

const LocationsList = ({ departmentId }: Props) => {
    const [isCreating, setIsCreating] = useState(false)
    const entities = useStore(selectEntities)
    const locations = entities.locations.filter(location =>
        departmentId === 'uncategorized' ? location.departments.length === 0 : location.departments.some(department => department.id === departmentId)
    )

    function onDelete(location: Schemas['LocationOut']) {
        void deleteLocation(location.id)
    }

    const onAddNew = useCallback(async () => {
        if (isCreating) {
            return
        }

        const roomCode = window.prompt('Enter new location name (room_code)')

        if (!roomCode) {
            return
        }

        setIsCreating(true)

        const newLocation = (await createLocation({ room_code: roomCode })).data

        if (newLocation && departmentId !== 'uncategorized') {
            await createDepartmentLocationAssignment({
                department_id: departmentId,
                location_id: newLocation.id,
            })
        }

        setIsCreating(false)
    }, [isCreating, departmentId])

    function onRename(location: Schemas['LocationOut']) {
        const roomCode = window.prompt('Enter new location name (room_code)', location.room_code)

        if (!roomCode) {
            return
        }

        void updateLocation(location.id, { room_code: roomCode })
    }

    return (
        <EntityList title="Locations">
            {locations.map(location => (
                <EntityItem key={location.id} onDelete={() => onDelete(location)} onRename={() => onRename(location)}>
                    {location.room_code}
                </EntityItem>
            ))}
            <AddNewButton onClick={onAddNew} isCreating={isCreating} entityName="Location" />
        </EntityList>
    )
}

export default LocationsList
