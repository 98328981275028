import { AgeGroupOut } from '~/store/diApi'
import { SurgeryResource } from '~/store/dipsApi'

import { isNotNullish } from '../guards'

const practitionerResourceTypeIds = [220716, 104600, 223475] as const
type PractitionerResourceTypeId = (typeof practitionerResourceTypeIds)[number]

export const PractitionerResourceNames = {
    Surgeon: 220716,
    Assistant: 104600,
    FirstAssistant: 223475,
    SecondAssistant: 104600,
} satisfies Record<string, PractitionerResourceTypeId>

const locationResourceTypeIds = [104605] as const
type LocationResourceTypeId = (typeof locationResourceTypeIds)[number]

const LocationResourceNames = {
    TheaterRoom: 104605,
} satisfies Record<string, LocationResourceTypeId>

const SurgeryResourceNames = { ...PractitionerResourceNames, ...LocationResourceNames } as const

// This is called SurgeryResourceType to refer to DIPS's SurgeryType.typeId field, as opposed to the SurgeryType.resourceId field
export type PractitionerResourceType = (typeof PractitionerResourceNames)[keyof typeof PractitionerResourceNames]

type PractitionerType = (typeof SurgeryResourceNames)['Surgeon' | 'Assistant' | 'FirstAssistant' | 'SecondAssistant']

export type MinimalPractitioner = {
    short_name: string
    name?: string
    practitionerType?: PractitionerType // if provided, will sort by them; otherwise or afterwards by name
}

export type SurgeryForPresentation = {
    bookingId?: string
    operation: string
    patient: string
    practitioners: MinimalPractitioner[]
    location: string
    displayDate: string
    comment?: string
    asa?: string | null
    dayOvernight?: { label: string; icon: JSX.Element } | null
    patientAgeGroup?: AgeGroupOut
    isShortNotice?: boolean
}

export function getTheaterRoom(resources: SurgeryResource[] | null | undefined): SurgeryResource | undefined {
    return isNotNullish(resources) ? resources.find(resource => resource.typeId === SurgeryResourceNames.TheaterRoom) : undefined
}

function toPractitioner(practitioner: SurgeryResource & { typeId: PractitionerType }): MinimalPractitioner {
    return {
        short_name: practitioner.shortName ?? '???',
        name: practitioner.name ?? undefined,
        practitionerType: practitioner.typeId, // not to be confused with resourceId
    }
}

function isSurgeon(resource: SurgeryResource | null | undefined): resource is SurgeryResource & { typeId: PractitionerType } {
    return resource?.typeId === SurgeryResourceNames.Surgeon
}

export function getSurgeons(resources: (SurgeryResource | null | undefined)[] | null | undefined): MinimalPractitioner[] {
    return resources?.filter(isSurgeon).map(toPractitioner) ?? []
}

export function getAssistants(resources: (SurgeryResource | null | undefined)[] | null | undefined): MinimalPractitioner[] {
    function isAssistant(resource: SurgeryResource | null | undefined): resource is SurgeryResource & { typeId: PractitionerType } {
        return (
            resource?.typeId === SurgeryResourceNames.Assistant ||
            resource?.typeId === SurgeryResourceNames.FirstAssistant ||
            resource?.typeId === SurgeryResourceNames.SecondAssistant
        )
    }
    return resources?.filter(isAssistant).map(toPractitioner) ?? []
}

export function getFirstAssistants(resources: SurgeryResource[] | null | undefined): MinimalPractitioner[] {
    function isFirstAssistant(resource: SurgeryResource): resource is SurgeryResource & { typeId: PractitionerType } {
        return resource.typeId === SurgeryResourceNames.FirstAssistant
    }
    return resources?.filter(isFirstAssistant).map(toPractitioner) ?? []
}

const order: (PractitionerType | undefined)[] = [
    SurgeryResourceNames.Surgeon,
    SurgeryResourceNames.FirstAssistant,
    SurgeryResourceNames.SecondAssistant,
    SurgeryResourceNames.Assistant,
    undefined,
]

export function comparePractitionerOrder(a: PractitionerType | undefined, b: PractitionerType | undefined): number {
    return order.indexOf(a) - order.indexOf(b)
}

export function getUniquePractitioners(practitioners: MinimalPractitioner[]) {
    const uniquePractitioners = practitioners
        .filter(p => !!p.short_name)
        .map(p => ({ ...p, short_name: p.short_name.toLocaleUpperCase() }))
        .reduce((map, practitioner) => {
            const shortName = practitioner.short_name
            return map.has(shortName) ? map : map.set(shortName, practitioner)
        }, new Map())
        .values()

    return [...uniquePractitioners].sort((a, b) => {
        const comparison = comparePractitionerOrder(a.practitionerType, b.practitionerType)
        return comparison !== 0 ? comparison : a.short_name.localeCompare(b.short_name)
    })
}
