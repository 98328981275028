/**
 * Returns the maximum number of surgeries that can be performed for the patient group.
 *
 * This is calculated using the surgery with the smallest weight (i.e. the smallest/fastest surgery).
 * If there is only one surgery weight or there is no surgery weight in the list, the max count will be the same
 * as the total weight, as the weight should be mapped to 1.0 in these cases.
 *
 * @param totalWeight The required total weight of all surgeries (e.g. total weight for a block schedule).
 * @param sortedGroupWeights The different weights associated with the patient group. Sorted lowest first.
 * @returns Maximum number of surgeries that can be performed.
 */
export function maxSurgeryCountForWeight(totalWeight: number, sortedGroupWeights: number[]) {
    if (sortedGroupWeights.length <= 1) {
        return totalWeight
    }

    if (!sortedGroupWeights[0] || sortedGroupWeights[0] === 0.0) {
        return 0
    }

    return Math.floor(totalWeight / sortedGroupWeights[0])
}

/**
 * Returns the number of surgeries that can be performed when choosing the largest surgeries first.
 *
 * If there is only one surgery weight or there is no surgery weight in the list, the count will be the same
 * as the total weight, as the weight should be mapped to 1.0 in these cases.
 *
 **
 * @param totalWeight The total weight of all surgeries for the block schedule.
 * @param sortedUniqueWeights The different weights associated with the patient group, sorted with lowest first.
 * @returns Maximum number of surgeries that can be performed if forced to choose the largest weighted surgeries first.
 */
export function largestFirstSurgeryCountForWeight(totalWeight: number, sortedUniqueWeights: number[]) {
    if (sortedUniqueWeights.length <= 1) {
        return totalWeight
    }

    let count = 0
    let totalRemaining = totalWeight

    for (let i = sortedUniqueWeights.length - 1; i >= 0; i--) {
        if (totalRemaining === 0) break

        const weight = sortedUniqueWeights[i]
        if (weight && totalRemaining >= weight) {
            count += Math.floor(totalRemaining / weight)
            totalRemaining = totalRemaining % weight
        }
    }
    return count
}
