import { Dayjs } from 'dayjs'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { getTheaterRoom } from '~/utils/dips'
import { AcceptedDayInput, day, getOnlyDate } from '~/utils/extendedDayjs'

import { MinimalScheduledSurgery } from '../dipsApi'
import { DepartmentKey } from '../slices/filterSlice'
import { ScheduledSurgery, selectEntities } from './entities'

function makeKey(date: Dayjs, room_code?: string | null): string {
    return `${getOnlyDate(date)}_${room_code ?? 'UNSPECIFIED-OR'}`
}

function isNonCancelledSurgery<T extends MinimalScheduledSurgery>(surgery: T) {
    const finishCode = surgery.finishInformation?.finishCode
    return !Boolean(finishCode && finishCode !== 'OR')
}

export const selectGetScheduledSurgeries = createSelector(selectEntities, ({ scheduledSurgeries }) => {
    const byBookingId = keyBy(scheduledSurgeries, 'id')
    const byDateAndLocation = groupBy(scheduledSurgeries, scheduledSurgery =>
        makeKey(day(scheduledSurgery.plannedStartTime), getTheaterRoom(scheduledSurgery.surgeryResources)?.shortName)
    )
    const byDepartmentId = groupBy(scheduledSurgeries, scheduledSurgery => {
        return scheduledSurgery.section?.department?.id
    })

    return {
        byBookingId: (bookingId: ScheduledSurgery['id']): ScheduledSurgery | null => {
            return !bookingId ? null : (byBookingId[bookingId] ?? null)
        },
        byDateAndRoomCode: (date: AcceptedDayInput, room_code: string, remove_cancelled = true): ScheduledSurgery[] => {
            const surgeries = byDateAndLocation[makeKey(day(date), room_code)] ?? []
            if (remove_cancelled) {
                return surgeries?.filter(isNonCancelledSurgery)
            }
            return surgeries
        },
        byDepartmentKey: (departmentKey: DepartmentKey) => {
            if (departmentKey === 'all') {
                return scheduledSurgeries
            }

            return byDepartmentId[departmentKey] ?? []
        },
    }
})
