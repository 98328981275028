import { emptyDipsApi as api } from "./emptyDipsApi";
export const addTagTypes = [
  "Bed" as const,
  "Organization" as const,
  "PatientInformation" as const,
  "Surgery" as const,
  "SurgeryOrder" as const,
  "Test" as const,
  "Resource" as const,
  "doc" as const,
];
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      beds: build.query<BedsApiResponse, BedsApiArg>({
        query: (queryArg) => ({
          url: `/api/v1/beds`,
          params: {
            wardId: queryArg.wardId,
            bedStatuses: queryArg.bedStatuses,
          },
        }),
        providesTags: ["Bed"],
      }),
      organizationDepartments: build.query<
        OrganizationDepartmentsApiResponse,
        OrganizationDepartmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organization/departments`,
          params: {
            includeNotAccessTo: queryArg.includeNotAccessTo,
            includeReplaced: queryArg.includeReplaced,
          },
        }),
        providesTags: ["Organization"],
      }),
      organizationSections: build.query<
        OrganizationSectionsApiResponse,
        OrganizationSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organization/sections`,
          params: {
            includeNotAccessTo: queryArg.includeNotAccessTo,
            includeReplaced: queryArg.includeReplaced,
            departmentId: queryArg.departmentId,
          },
        }),
        providesTags: ["Organization"],
      }),
      organizationWards: build.query<
        OrganizationWardsApiResponse,
        OrganizationWardsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organization/wards`,
          params: {
            includeNotAccessTo: queryArg.includeNotAccessTo,
            includeReplaced: queryArg.includeReplaced,
            departmentId: queryArg.departmentId,
          },
        }),
        providesTags: ["Organization"],
      }),
      organizationLocalizations: build.query<
        OrganizationLocalizationsApiResponse,
        OrganizationLocalizationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organization/localizations`,
          params: {
            includeNotAccessTo: queryArg.includeNotAccessTo,
            departmentId: queryArg.departmentId,
          },
        }),
        providesTags: ["Organization"],
      }),
      organizationCodeListByCodelistid: build.query<
        OrganizationCodeListByCodelistidApiResponse,
        OrganizationCodeListByCodelistidApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organization/code-list/${queryArg.codelistid}`,
        }),
        providesTags: ["Organization"],
      }),
      organizationCodeListByCodelistidCodelistitem: build.query<
        OrganizationCodeListByCodelistidCodelistitemApiResponse,
        OrganizationCodeListByCodelistidCodelistitemApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organization/code-list/${queryArg.codelistid}/codelistitem`,
          params: { code: queryArg.code, id: queryArg.id },
        }),
        providesTags: ["Organization"],
      }),
      postApiV1PatientinformationPatients: build.mutation<
        PostApiV1PatientinformationPatientsApiResponse,
        PostApiV1PatientinformationPatientsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/patientinformation/patients`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["PatientInformation"],
      }),
      surgeryFoldersByFolderIdPostponementHistory: build.query<
        SurgeryFoldersByFolderIdPostponementHistoryApiResponse,
        SurgeryFoldersByFolderIdPostponementHistoryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/surgery/folders/${queryArg.folderId}/postponement-history`,
        }),
        providesTags: ["Surgery"],
      }),
      postApiV1SurgerySchedule: build.mutation<
        PostApiV1SurgeryScheduleApiResponse,
        PostApiV1SurgeryScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/surgery/schedule`,
          method: "POST",
          body: queryArg.scheduleSurgeryRequest,
        }),
        invalidatesTags: ["Surgery"],
      }),
      surgeryWaiting: build.query<
        SurgeryWaitingApiResponse,
        SurgeryWaitingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/surgery/waiting`,
          params: {
            TreatmentLocationId: queryArg.treatmentLocationId,
            DepartmentIds: queryArg.departmentIds,
            SectionIds: queryArg.sectionIds,
            WardIds: queryArg.wardIds,
            LocationIds: queryArg.locationIds,
            ToDate: queryArg.toDate,
            FromDate: queryArg.fromDate,
            WithCheckInTimesOnly: queryArg.withCheckInTimesOnly,
          },
        }),
        providesTags: ["Surgery"],
      }),
      postApiV2SurgeriesWaiting: build.mutation<
        PostApiV2SurgeriesWaitingApiResponse,
        PostApiV2SurgeriesWaitingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/surgeries/waiting`,
          method: "POST",
          body: queryArg.surgeryWaitingListFilterV2,
        }),
        invalidatesTags: ["Surgery"],
      }),
      surgeryOrders: build.query<
        SurgeryOrdersApiResponse,
        SurgeryOrdersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/surgery/orders`,
          params: {
            status: queryArg.status,
            fromDateTime: queryArg.fromDateTime,
            toDateTime: queryArg.toDateTime,
          },
        }),
        providesTags: ["SurgeryOrder"],
      }),
      surgeryOrdersBySurgeryorderid: build.query<
        SurgeryOrdersBySurgeryorderidApiResponse,
        SurgeryOrdersBySurgeryorderidApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/surgery/orders/${queryArg.surgeryorderid}`,
        }),
        providesTags: ["SurgeryOrder"],
      }),
      helloWorld: build.query<
        HelloWorldApiResponse,
        HelloWorldApiArg
      >({
        query: () => ({ url: `/api/v1/hello-world` }),
        providesTags: ["Test"],
      }),
      helloWorldScopedPatient: build.query<
        HelloWorldScopedPatientApiResponse,
        HelloWorldScopedPatientApiArg
      >({
        query: () => ({ url: `/api/v1/hello-world-scoped-patient` }),
        providesTags: ["Test"],
      }),
      helloWorldScopedMachinelearning: build.query<
        HelloWorldScopedMachinelearningApiResponse,
        HelloWorldScopedMachinelearningApiArg
      >({
        query: () => ({ url: `/api/v1/hello-world-scoped-machinelearning` }),
        providesTags: ["Test"],
      }),
      resources: build.query<
        ResourcesApiResponse,
        ResourcesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/resources`,
          params: {
            resourceType: queryArg.resourceType,
            departmentReshId: queryArg.departmentReshId,
          },
        }),
        providesTags: ["Resource"],
      }),
      sagaBySagaIdStatus: build.query<
        SagaBySagaIdStatusApiResponse,
        SagaBySagaIdStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/saga/${queryArg.sagaId}/status`,
        }),
        providesTags: ["Surgery"],
      }),
      getApiV2SurgeryScheduled: build.query<
        GetApiV2SurgeryScheduledApiResponse,
        GetApiV2SurgeryScheduledApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/surgery/scheduled`,
          params: {
            StartTime: queryArg.startTime,
            EndTime: queryArg.endTime,
            View: queryArg.view,
            TreatmentLocationId: queryArg.treatmentLocationId,
            RoomResources: queryArg.roomResources,
            SurgeonResources: queryArg.surgeonResources,
            BookingId: queryArg.bookingId,
          },
        }),
        providesTags: ["Surgery"],
      }),
      surgeryScheduled: build.query<
        SurgeryScheduledApiResponse,
        SurgeryScheduledApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/surgery/scheduled`,
          params: {
            StartTime: queryArg.startTime,
            EndTime: queryArg.endTime,
            View: queryArg.view,
            TreatmentLocationId: queryArg.treatmentLocationId,
            RoomResources: queryArg.roomResources,
            SurgeonResources: queryArg.surgeonResources,
            BookingId: queryArg.bookingId,
          },
        }),
        providesTags: ["Surgery"],
      }),
      getSwaggerV1SwaggerJson: build.query<
        GetSwaggerV1SwaggerJsonApiResponse,
        GetSwaggerV1SwaggerJsonApiArg
      >({
        query: () => ({ url: `/swagger/v1/swagger.json` }),
        providesTags: ["doc"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as dipsApi };
export type BedsApiResponse = /** status 200 Success */ BedDto[];
export type BedsApiArg = {
  /** Format - int64. */
  wardId?: number;
  /** If you want to get beds with certain bed-statuses set statuses in a comma separated list.
    
    Statuses that does not exist will not be processed and if none of the statuses exist all bed-statuses are used.
    
    Current statuses are:
    - 0 => Available
    - 1 => Not Available
    - 2 => Out of service */
  bedStatuses?: string;
};
export type OrganizationDepartmentsApiResponse =
  /** status 200 Success */ DepartmentDto[];
export type OrganizationDepartmentsApiArg = {
  /** If set to true, all departments will be returned. A property "HasAccess" indicates if the logged in user has access to each department. */
  includeNotAccessTo?: boolean;
  /** If set to true, also return departments that has been replaced. */
  includeReplaced?: boolean;
};
export type OrganizationSectionsApiResponse =
  /** status 200 Success */ SectionDto[];
export type OrganizationSectionsApiArg = {
  /** If set to true, all sections will be returned. A property "HasAccess" indicates if the logged in user has access to each section. */
  includeNotAccessTo?: boolean;
  /** If set to true, also return sections that has been replaced. */
  includeReplaced?: boolean;
  /** Format - int64. If set, only return sections that is under the specified department. */
  departmentId?: number;
};
export type OrganizationWardsApiResponse =
  /** status 200 Success */ WardDto[];
export type OrganizationWardsApiArg = {
  /** If set to true, all wards will be returned. A property "HasAccess" indicates if the logged in user has access to each ward. */
  includeNotAccessTo?: boolean;
  /** If set to true, also return wards that has been replaced. */
  includeReplaced?: boolean;
  /** Format - int64. If set, only return wards that is under the specified department. */
  departmentId?: number;
};
export type OrganizationLocalizationsApiResponse =
  /** status 200 Success */ LocationDto[];
export type OrganizationLocalizationsApiArg = {
  /** If set to true, all localizations will be returned. A property "HasAccess" indicates if the logged in user has access to each localization. */
  includeNotAccessTo?: boolean;
  /** Format - int64. If set, only return localizations that is under the specified department. */
  departmentId?: number;
};
export type OrganizationCodeListByCodelistidApiResponse =
  /** status 200 Success */ CodeListDto;
export type OrganizationCodeListByCodelistidApiArg = {
  /** Format - int64. Specifies the code list id. */
  codelistid: number;
};
export type OrganizationCodeListByCodelistidCodelistitemApiResponse =
  /** status 200 Success */ CodeListItem;
export type OrganizationCodeListByCodelistidCodelistitemApiArg = {
  /** Format - int64. Specifies the code list id. */
  codelistid: number;
  /** Specifies the specific codelistitem that has the given code. */
  code?: string;
  /** Format - int64. Specifies the specific codelistitem that has the given id. */
  id?: number;
};
export type PostApiV1PatientinformationPatientsApiResponse =
  /** status 200 Success */ PatientExternalDto[];
export type PostApiV1PatientinformationPatientsApiArg = {
  /** Lists of personProxyGuids */
  body: string[];
};
export type SurgeryFoldersByFolderIdPostponementHistoryApiResponse =
  /** status 200 A list of all postponements done for a surgery */ PostponementHistory;
export type SurgeryFoldersByFolderIdPostponementHistoryApiArg = {
  /** Format - uuid. */
  folderId: string;
};
export type PostApiV1SurgeryScheduleApiResponse =
  /** status 202 Accepted */ string;
export type PostApiV1SurgeryScheduleApiArg = {
  scheduleSurgeryRequest: ScheduleSurgeryRequest;
};
export type SurgeryWaitingApiResponse =
  /** status 200 A list of surgeries waiting to be scheduled for a users hospital */ SurgeryWaitingListAuthorizationResult;
export type SurgeryWaitingApiArg = {
  /** Format - int64. */
  treatmentLocationId: number;
  departmentIds?: string;
  sectionIds?: string;
  wardIds?: string;
  locationIds?: string;
  /** Format - date-time (as date-time in RFC3339). */
  toDate?: string;
  /** Format - date-time (as date-time in RFC3339). */
  fromDate?: string;
  withCheckInTimesOnly?: boolean;
};
export type PostApiV2SurgeriesWaitingApiResponse =
  /** status 200 A list of surgeries waiting to be scheduled for a users hospital */ SurgeryWaitingListResult;
export type PostApiV2SurgeriesWaitingApiArg = {
  surgeryWaitingListFilterV2: SurgeryWaitingListFilterV2;
};
export type SurgeryOrdersApiResponse =
  /** status 200 Success */ SurgeryOrderDto[];
export type SurgeryOrdersApiArg = {
  /** Comma separated list of statuses.
    
    Example: status=initial,waiting
    
    Valid statuses
    - Initial
    - Waiting
    - Scheduled
    - Active
    - Completed
    - Cancelled
    - Postponed
    - Aborted
    - Suspended */
  status: string;
  /** Format - date-time (as date-time in RFC3339). From date and time. Example: fromdatetime=2023-04-18T00:00:00 */
  fromDateTime: string;
  /** Format - date-time (as date-time in RFC3339). To date and time. Example: todatetime=2023-04-18T23:59:59 */
  toDateTime: string;
};
export type SurgeryOrdersBySurgeryorderidApiResponse =
  /** status 200 Success */ SurgeryOrderDetailsDto;
export type SurgeryOrdersBySurgeryorderidApiArg = {
  /** Format - uuid. */
  surgeryorderid: string;
};
export type HelloWorldApiResponse = /** status 200 Success */ string;
export type HelloWorldApiArg = void;
export type HelloWorldScopedPatientApiResponse =
  /** status 200 Success */ string;
export type HelloWorldScopedPatientApiArg = void;
export type HelloWorldScopedMachinelearningApiResponse =
  /** status 200 Success */ string;
export type HelloWorldScopedMachinelearningApiArg = void;
export type ResourcesApiResponse =
  /** status 200 Success */ ResourceDto[];
export type ResourcesApiArg = {
  resourceType: string;
  departmentReshId: string;
};
export type SagaBySagaIdStatusApiResponse = /** status 200 OK */ string;
export type SagaBySagaIdStatusApiArg = {
  sagaId: string;
};
export type GetApiV2SurgeryScheduledApiResponse =
  /** status 200 A list of scheduled surgeries from a user's hospital */ (
    | FullScheduledSurgeryV2Dto
    | MinimalScheduledSurgeryV2Dto
  )[];
export type GetApiV2SurgeryScheduledApiArg = {
  startTime: string;
  endTime: string;
  view: string;
  treatmentLocationId?: number;
  roomResources?: string;
  surgeonResources?: string;
  bookingId?: string;
};
export type SurgeryScheduledApiResponse =
  /** status 200 A list of scheduled surgeries from a user's hospital */ (
    | FullScheduledSurgery
    | MinimalScheduledSurgery
  )[];
export type SurgeryScheduledApiArg = {
  /** Format - date-time (as date-time in RFC3339). */
  startTime: string;
  /** Format - date-time (as date-time in RFC3339). */
  endTime: string;
  view: string;
  /** Format - int64. */
  treatmentLocationId?: number;
  roomResources?: string;
  surgeonResources?: string;
  /** Format - uuid. */
  bookingId?: string;
};
export type GetSwaggerV1SwaggerJsonApiResponse =
  /** status 200 Success */ object;
export type GetSwaggerV1SwaggerJsonApiArg = void;
export type BedDto = {
  id?: number;
  name?: string | null;
  typeId?: number;
  type?: string | null;
  statusId?: number;
  status?: string | null;
  wardId?: number;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type DepartmentDto = {
  id?: number;
  reshId?: string | null;
  substitutedById?: number | null;
  substituteFor?: number[] | null;
  name?: string | null;
  shortName?: string | null;
  departmentType?: number;
  departmentTypeName?: string | null;
  isInPatientDepartment?: boolean;
  isOutPatientDepartment?: boolean;
  isWaitingListDepartment?: boolean;
  permitsParallels?: boolean;
  hospitalId?: number;
  clinicId?: number | null;
  validFrom?: string | null;
  validTo?: string | null;
  hasAccess?: boolean;
};
export type SectionDto = {
  id?: number;
  reshId?: string | null;
  substitutedById?: number | null;
  substituteFor?: number[] | null;
  name?: string | null;
  shortName?: string | null;
  hospitalId?: number;
  departmentId?: number | null;
  validFrom?: string | null;
  validTo?: string | null;
  hasAccess?: boolean;
};
export type WardDto = {
  id?: number;
  reshId?: string | null;
  substitutedById?: number | null;
  substituteFor?: number[] | null;
  name?: string | null;
  shortName?: string | null;
  wing?: string | null;
  wardType?: number | null;
  wardTypeName?: string | null;
  isEmergencyWard?: boolean;
  isTechnicalWard?: boolean;
  hospitalId?: number;
  departmentIds?: number[] | null;
  placeOfWorkLocalizationId?: number | null;
  locationId?: string | null;
  validFrom?: string | null;
  validTo?: string | null;
  hasAccess?: boolean;
};
export type LocationTypeDto = {
  id?: number;
  locationTypeName?: string | null;
};
export type LocationDto = {
  id?: number;
  reshId?: string | null;
  placeOfWorkLocalizationId?: number | null;
  name?: string | null;
  shortName?: string | null;
  localizationTypes?: number[] | null;
  locationTypeDtos?: LocationTypeDto[] | null;
  levelOfCare?: number | null;
  levelOfCareName?: string | null;
  hospitalId?: number;
  departmentIds?: number[] | null;
  validFrom?: string | null;
  validTo?: string | null;
  hasAccess?: boolean;
};
export type CodeListPropertyType = {
  id?: number | null;
  name?: string | null;
  dataType?: string | null;
  description?: string | null;
  ri?: number | null;
  rv?: number | null;
};
export type CodeListItemProperty = {
  id?: number;
  propertyTypeId?: number;
  valid?: boolean;
  value?: string | null;
  ri?: number;
  rv?: number;
};
export type CodeListItem = {
  id?: number | null;
  codeListId?: number | null;
  code?: string | null;
  name?: string | null;
  shortName?: string | null;
  description?: string | null;
  departmentId?: number | null;
  hospitalId?: number | null;
  validFrom?: string | null;
  validTo?: string | null;
  inUse?: boolean | null;
  ri?: number | null;
  rv?: number | null;
  properties?: CodeListItemProperty[] | null;
};
export type CodeListDto = {
  id?: number | null;
  name?: string | null;
  description?: string | null;
  prefix?: string | null;
  inUse?: boolean | null;
  providedByDIPS?: boolean | null;
  customerCanModify?: boolean | null;
  ri?: number | null;
  rv?: number | null;
  propertyTypes?: CodeListPropertyType[] | null;
  codes?: CodeListItem[] | null;
};
export type AddressDto = {
  streetAddress?: string | null;
  zipCode?: string | null;
};
export type PatientExternalDto = {
  personProxyGuid?: string;
  nationalIdentityNumber?: string | null;
  lastName?: string | null;
  firstname?: string | null;
  address?: AddressDto;
};
export type History = {
  postponedSurgeryId?: number;
  postponementDateTime?: string;
  postponementReason?: string | null;
  postponementCode?: string | null;
  postponementCodeId?: number;
  plannedStartTime?: string;
  surgeryId?: number;
};
export type PostponementHistory = {
  postponements?: History[] | null;
};
export type SurgeryResourceMinimal = {
  /** Unique identifier */
  id?: string;
  resourceId?: number | null;
  [key: string]: any;
};
export type ScheduleSurgeryParams = {
  sagaId: string;
  bookingId: string;
  checkInTime: string;
  plannedStartTime: string;
  surgeryResources: SurgeryResourceMinimal[];
  version: number;
  contactVersion: number;
  treatmentLocationId: number;
  [key: string]: any;
};
export type ScheduleSurgeryRequest = {
  scheduleSurgeryParams: ScheduleSurgeryParams[];
  callbackUrl?: string | null;
  [key: string]: any;
};
export type Gender = "Unknown" | "Female" | "Male";
export type Patient = {
  /** Unique identifier */
  id?: string;
  /** Unique identifier */
  nationalId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  gender?: Gender;
  birthDate?: string | null;
  address?: AddressDto;
};
export type Location = {
  /** Unique identifier */
  id?: number;
  /** Unique identifier */
  shortName?: string | null;
};
export type LevelOfCareNpr = {
  nprId?: number;
  nprCodeName?: string | null;
};
export type Tentative = "NotTentative" | "TentativeDate" | "TentativeMonth";
export type ResponsibleHcp = {
  /** Unique identifier */
  id?: number;
  name?: string | null;
  code?: string | null;
};
export type Contact = {
  /** Unique identifier */
  id?: number;
  department?: Location;
  section?: Location;
  ward?: Location;
  location?: Location;
  levelOfCareNpr?: LevelOfCareNpr;
  tentative?: Tentative;
  /** Oppmøte/Checkin-time */
  checkInTime?: string | null;
  isShortNotice?: boolean;
  /** Kontaktårsak/Contact Reason */
  note?: string | null;
  /** Oppmøtekommentar/CoordinationComment */
  coordinationComment?: string | null;
  diagnosisGroupName?: string | null;
  responsibleHcp?: ResponsibleHcp;
  version?: number;
};
export type SurgeryOrderDetails = {
  /** Unique identifier */
  folderId?: string | null;
  asa?: string | null;
  bmi?: string | null;
  noteToPlanner?: string | null;
  orderedDate?: string;
  surgeryOrderDetailsUrl?: string | null;
};
export type SurgeryResource = {
  /** Unique identifier */
  id?: string;
  name?: string | null;
  /** Unique identifier */
  shortName?: string | null;
  resourceId?: number | null;
  typeId?: number;
  assignedResourceTypeName?: string | null;
  order?: number;
};
export type SurgeryType = {
  /** Unique identifier */
  id?: number;
  name?: string | null;
  code?: string | null;
};
export type UnscheduledSurgery = {
  bookingId?: string;
  patient?: Patient;
  contact?: Contact;
  creationTime?: string;
  plannedProcedureDuration?: string;
  surgeryOrderDetails?: SurgeryOrderDetails;
  surgeryResources?: SurgeryResource[] | null;
  surgeryType?: SurgeryType;
  treatmentLocationId?: number | null;
  version?: number;
};
export type SurgeryWaitingListAuthorizationResult = {
  surgeryWaitingList?: UnscheduledSurgery[] | null;
  numberOfItemsWithNoAccess?: number;
  numberOfItemsBlockedForUser?: number;
};
export type OrgUnit = {
  /** Unique identifier */
  id?: number;
  /** Unique identifier */
  shortName?: string | null;
  name?: string | null;
  [key: string]: any;
};
export type SurgeryWaitingList = {
  surgeryBookingId?: string;
  patient?: Patient;
  contact?: Contact;
  creationTime?: string;
  plannedProcedureDuration?: string;
  surgeryOrderDetails?: SurgeryOrderDetails;
  surgeryResources?: SurgeryResource[] | null;
  surgeryType?: SurgeryType;
  treatmentLocation?: OrgUnit;
  noteToPatient?: string | null;
  postponementCount?: number;
  healthIssue?: string | null;
  [key: string]: any;
};
export type ServiceAuthorizationResult = {
  numberOfItemsWithNoAccess?: number;
  numberOfItemsBlockedForUser?: number;
  [key: string]: any;
};
export type SurgeryWaitingListResult = {
  surgeryWaitingList?: SurgeryWaitingList[] | null;
  serviceAuthorizationResult?: ServiceAuthorizationResult;
  [key: string]: any;
};
export type SurgeryWaitingListFilterV2 = {
  treatmentLocationId?: number | null;
  departmentIds?: number[] | null;
  sectionIds?: number[] | null;
  wardIds?: number[] | null;
  locationIds?: number[] | null;
  toDate?: string | null;
  fromDate?: string | null;
  withCheckInTimesOnly?: boolean;
  isTentative?: boolean | null;
  [key: string]: any;
};
export type SurgeryOrderDto = {
  surgerOrderId?: string;
  surgeryOrderDetailsUrl?: string | null;
  title?: string | null;
  orderedTime?: string | null;
  patientId?: string | null;
  patientDetailsUrl?: string | null;
  state?: string | null;
};
export type SurgeryProcedure = {
  procedureName?: string | null;
  procedureCode?: string | null;
};
export type UrgencyType =
  | "Immediately"
  | "Emergently"
  | "Urgently"
  | "CanWait"
  | "Normal";
export type SurgeryWorkflowType =
  | "SurgeryWithAnesthesia"
  | "SurgeryOnly"
  | "AnesthesiaOnly";
export type Diagnosis = {
  diagnoseCode?: string | null;
  diagnoseName?: string | null;
};
export type SurgeryOrderDetailsDto = {
  surgeryOrderDocumentId?: number | null;
  surgeryAccessmentDocumentId?: number | null;
  surgeryType?: string | null;
  surgeryTypeCode?: string | null;
  surgeryDescription?: string | null;
  surgeryProcedures?: SurgeryProcedure[] | null;
  urgencyType?: UrgencyType;
  priorityType?: string | null;
  maxWaitTimeHours?: string | null;
  canPatientGetBackLaterWithNewAppointment?: boolean | null;
  surgeryLocationName?: string | null;
  surgeryLocationId?: number | null;
  messageAboutPatientAppointment?: string | null;
  earliestDate?: string | null;
  performedBeforeDate?: string | null;
  scheduleByWaitingList?: boolean | null;
  surgeryWorkflowType?: SurgeryWorkflowType;
  surgeryTemplateName?: string | null;
  surgeryTemplateId?: number | null;
  referralId?: number | null;
  referralDetailsUrl?: string | null;
  plannedContactId?: number | null;
  plannedContactURL?: string | null;
  indicationOrDiagnosis?: string | null;
  diagnoses?: Diagnosis[] | null;
  estimatedKnifeTime?: string | null;
  medianKnifeTime?: string | null;
  patientPositioning?: string | null;
  incision?: string | null;
  side?: string | null;
  asa?: string | null;
  assessmentSurgeryType?: string | null;
  assessmentSurgeryTypeCode?: string | null;
  assessmentSurgeryProcedures?: SurgeryProcedure[] | null;
  orderedTime?: string | null;
  bmi?: string | null;
};
export type ResourceTypes = "MainSurgeon" | "OperatingTheater";
export type ResourceDto = {
  shortName?: string | null;
  name?: string | null;
  inUse?: boolean;
  resourceType?: ResourceTypes;
};
export type FinishInformation = {
  /** Unique identifier */
  finishCodeId?: number;
  finishCode?: string | null;
  finishTime?: string;
};
export type FullScheduledSurgeryV2Dto = {
  /** Booking id for surgery */
  id?: string;
  patient?: Patient;
  contact?: Contact;
  surgeryOrderDetails?: SurgeryOrderDetails;
  /** Information about Surgeon and Theater Room if set on surgery */
  surgeryResources?: SurgeryResource[] | null;
  surgeryType?: SurgeryType;
  /** Planned procedure time (NO: Knivtid), based on median times */
  plannedProcedureDuration?: string;
  /** Planned procedure for the entire procedure time (NO: Sesjonstid) */
  plannedDuration?: string;
  /** The time the patient is expected to be in the operating theatre (NO: Planlagt Stue inn tid) */
  plannedTheatreInTime?: string;
  /** Treatment location where surgery is listed. */
  treatmentLocationId?: number;
  finishInformation?: FinishInformation;
  /** Information about a surgery that might have been postponed */
  postponementCount?: number;
  /** Is the surgery urgent or not */
  isUrgentCare?: boolean;
  /** Update Version of surgery. Increases everytime an update is made to this schema */
  version?: number;
  [key: string]: any;
};
export type MinimalScheduledSurgeryV2Dto = {
  /** Booking id for surgery */
  id?: string;
  /** Folder id for surgery */
  folderId?: string;
  /** Diagnosis group name */
  diagnosisGroupName?: string | null;
  /** Information about Surgeon and Theater Room if set on surgery */
  surgeryResources?: SurgeryResource[] | null;
  surgeryType?: SurgeryType;
  /** Planned procedure time (NO: Knivtid), based on median times */
  plannedProcedureDuration?: string;
  /** Planned procedure for the entire procedure time (NO: Sesjonstid) */
  plannedDuration?: string;
  /** The time the patient is expected to be in the operating theatre (NO: Planlagt Stue inn tid) */
  plannedTheatreInTime?: string;
  /** This is the planned time when the patient is supposed to meet before procedure (NO: Oppmøtetid) */
  checkInTime?: string;
  /** Treatment location where surgery is listed. */
  treatmentLocationId?: number;
  finishInformation?: FinishInformation;
  /** Information if the surgery has been postponed, and how many times. */
  postponementCount?: number;
  /** Is the surgery urgent or not */
  isUrgentCare?: boolean;
  /** Update Version of surgery. Increases everytime an update is made to this schema */
  version?: number;
  [key: string]: any;
};
export type FullScheduledSurgery = {
  /** Unique identifier */
  id?: string;
  patient?: Patient;
  contact?: Contact;
  surgeryOrderDetails?: SurgeryOrderDetails;
  surgeryResources?: SurgeryResource[] | null;
  surgeryType?: SurgeryType;
  plannedProcedureDuration?: string;
  plannedDuration?: string;
  plannedStartTime?: string;
  treatmentLocationId?: number;
  finishInformation?: FinishInformation;
  postponementCount?: number;
  isUrgentCare?: boolean;
  version?: number;
};
export type MinimalScheduledSurgery = {
  /** Unique identifier */
  id?: string;
  /** Unique identifier */
  patientId?: string;
  /** Unique identifier */
  folderId?: string;
  diagnosisGroupName?: string | null;
  surgeryResources?: SurgeryResource[] | null;
  surgeryType?: SurgeryType;
  plannedProcedureDuration?: string;
  plannedDuration?: string;
  plannedStartTime?: string;
  checkInTime?: string;
  treatmentLocationId?: number;
  finishInformation?: FinishInformation;
  postponementCount?: number;
  isUrgentCare?: boolean;
  version?: number;
};
export const {
  useBedsQuery,
  useOrganizationDepartmentsQuery,
  useOrganizationSectionsQuery,
  useOrganizationWardsQuery,
  useOrganizationLocalizationsQuery,
  useOrganizationCodeListByCodelistidQuery,
  useOrganizationCodeListByCodelistidCodelistitemQuery,
  usePostApiV1PatientinformationPatientsMutation,
  useSurgeryFoldersByFolderIdPostponementHistoryQuery,
  usePostApiV1SurgeryScheduleMutation,
  useSurgeryWaitingQuery,
  usePostApiV2SurgeriesWaitingMutation,
  useSurgeryOrdersQuery,
  useSurgeryOrdersBySurgeryorderidQuery,
  useHelloWorldQuery,
  useHelloWorldScopedPatientQuery,
  useHelloWorldScopedMachinelearningQuery,
  useResourcesQuery,
  useSagaBySagaIdStatusQuery,
  useGetApiV2SurgeryScheduledQuery,
  useSurgeryScheduledQuery,
  useGetSwaggerV1SwaggerJsonQuery,
} = injectedRtkApi;
