import keyBy from 'lodash/keyBy'

export type Entity<IDKey extends string> = Record<IDKey, string | number> & Record<string, unknown>

type Handlers<IDKey extends string, T extends Entity<IDKey>> = {
    abandoned: (entityId: T[IDKey][]) => void
    changed?: (entity: T[]) => void
}

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AsyncFn<IDKey extends string> = (...args: any[]) => Promise<Entity<IDKey>[]>

export function withFetchObserver<IdKey extends string, T extends AsyncFn<IdKey>>(
    call: T,
    idKey: IdKey,
    handlers: Handlers<IdKey, Awaited<ReturnType<T>>[number]>
): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
    // Bookkeeping for the fetch observer
    type IdValue = Entity<IdKey>[IdKey]
    const idsByKey = new Map<string, IdValue[]>()
    const keysById = new Map<IdValue, string[]>()

    return async (...newArgs: Parameters<T>) => {
        const key = JSON.stringify(newArgs)
        const newEntities = (await call(...newArgs)) as Awaited<ReturnType<T>>

        const previousIds = idsByKey.get(key) ?? []
        const newIds = newEntities.map(e => e[idKey])
        let unObservedIds = previousIds.filter(id => !newIds.includes(id))
        const fetchedKeys: string[] = [key]

        const newEntitiesById = keyBy(newEntities, idKey)

        // Update the bookkeeping
        newEntities.forEach(entity => {
            const value = entity[idKey]
            keysById.set(value, [...(keysById.get(value) ?? []), key])
        })
        idsByKey.set(key, newIds)

        while (unObservedIds.length) {
            // Find the unique keys for all unobserved Ids that have not been fetched yet
            const uniqueKeys = [...new Set(unObservedIds.map(id => keysById.get(id)).flat())].filter(Boolean).filter(key => !fetchedKeys.includes(key))

            // If there are not keys left, we are done
            if (!uniqueKeys.length) break

            // Take the first key
            const firstKey = uniqueKeys[0]
            if (!firstKey) break

            // Fetch using the key
            const args = JSON.parse(firstKey) as Parameters<T>
            const entities = await call(...args)
            fetchedKeys.push(firstKey)

            // Remove any unobserved Ids observed within the fetch
            const newIds = entities.map(e => e[idKey])
            unObservedIds = unObservedIds.filter(id => !newIds.includes(id))

            // Update newEntitiesById
            entities.forEach(e => {
                newEntitiesById[e[idKey]] = e
            })
        }

        // We notify the handler of all the ids we didn't observe in any of the fetches
        if (unObservedIds.length) {
            handlers.abandoned(unObservedIds)
        }

        const allNewEntities = Object.values(newEntitiesById)

        if (allNewEntities.length && handlers.changed) {
            handlers.changed(allNewEntities)
        }

        return newEntities
    }
}
