import dayjs from 'dayjs'

const cache = new Map<string, string>()

export function getFormattedDuration(duration: string, asclock?: boolean) {
    const durationCandidate = dayjs.duration(duration)

    if (dayjs.isDuration(durationCandidate)) {
        const hours = durationCandidate.hours()
        const minutes = durationCandidate.minutes()

        if (asclock) {
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        }

        if (hours !== 0 && minutes !== 0) {
            return `${hours}t ${minutes}min`
        } else if (hours !== 0) {
            return `${hours} timer`
        } else if (minutes !== 0) {
            return `${minutes} min`
        }
    }

    console.warn(`You have provided an invalid duration '${duration}'`)
    return ''
}

export function formatDuration(duration: string, asclock?: boolean): string {
    if (duration === 'P0D' || duration === 'P0DT0H0M0.000000S' || duration === 'PT0S') {
        return '0 min'
    }

    const cached = cache.get(duration)
    if (cached) return cached

    const formattedDuration = getFormattedDuration(duration, asclock)

    cache.set(duration, formattedDuration)

    return formattedDuration
}
