import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import { useState } from 'react'

import { selectGetLocations, selectGetScheduledSurgeries } from '~/store/selectors'
import { useStore } from '~/store/store'

import { EmptyItem } from './EmptyItem'
import { SurgeryCard } from './SurgeryCard'

export const BookingDrawerBookings = () => {
    const [animationEnded, setAnimationEnded] = useState(false)
    const { location_id, unixDate } = useStore(state => state.operationalPlanner.drawerProps) ?? {}
    const getScheduledSurgeries = useStore(selectGetScheduledSurgeries)
    const getLocations = useStore(selectGetLocations)
    const location = location_id !== undefined ? getLocations.byId(location_id) : null
    const date = dayjs.unix(unixDate ?? 0)

    if (!unixDate) return null
    if (!location) return null

    const bookedSurgeries = getScheduledSurgeries.byDateAndRoomCode(date, location.room_code)

    return (
        <motion.div
            onAnimationComplete={() => setAnimationEnded(true)}
            initial={{ opacity: 0, y: 24 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.25, type: 'linear' } }}
            exit={{ opacity: 0, x: -50, transition: { duration: 0.2, type: 'linear' } }}
        >
            <div className="flex items-center gap-4 pb-1">
                <p className="text-base font-semibold">Bestilte Operasjoner</p>
                {bookedSurgeries.length > 0 && <p className="text-sm text-gray-500">{bookedSurgeries.length} bestilt</p>}
            </div>
            <div className="flex flex-col gap-2">
                {bookedSurgeries.length > 0 ? (
                    bookedSurgeries.map((surgery, index) => <SurgeryCard surgery={{ type: 'scheduled', value: surgery }} key={index} />)
                ) : (
                    <EmptyItem>
                        <p>Det er ingen pasienter på programmet.</p>
                    </EmptyItem>
                )}
                {animationEnded && <div data-test="booking-drawer-loaded" className="hidden" />}
            </div>
        </motion.div>
    )
}
