import { useMemo } from 'react'

import { SurgeonCodeTag } from '~/components'
import { getUniquePractitioners, MinimalPractitioner } from '~/utils/dips'

export const PractitionerTags = ({ practitioners }: { practitioners: MinimalPractitioner[] }) => {
    const uniquePractitioners = useMemo(() => getUniquePractitioners(practitioners), [practitioners])
    if (uniquePractitioners.length === 0) return null

    return (
        <div className="flex flex-wrap gap-2">
            {uniquePractitioners.map((p, index) => (
                <SurgeonCodeTag surgeonCode={p.short_name} key={`${p.practitionerType}-${index}`} />
            ))}
        </div>
    )
}
