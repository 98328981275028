type Props = {
    children: React.ReactNode
}

export const ListItem = ({ children }: Props) => {
    return <li>{children}</li>
}

export const List = ({ children }: Props) => {
    return <ul className="flex flex-col gap-2 px-5 py-2">{children}</ul>
}
