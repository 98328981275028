import { useRef } from 'react'

import { Show } from '~/components'
import { useStore } from '~/store/store'
import { highlightNeedlesDebounced, needleToTokens } from '~/utils/highlightNeedlesDebounced'

import { usePagination } from '../../../../hooks/usePagination/usePagination'
import { useColumnsToShow } from '../hooks/useColumnsToShow'
import { useWaitingListData } from '../hooks/useWaitingListData'
import { LoadingRows } from './LoadingRows'
import { Pagination } from './Pagination/Pagination'
import { TableHeaderRow } from './TableHeaderRow'
import { TableRow } from './TableRow'

type Props = {
    isLoading: boolean
}

export function Table({ isLoading }: Props) {
    const selectedPage = useStore(state => state.waitingList.selectedPage)
    const { setSelectedPage } = useStore(state => state.waitingList.actions)
    const itemsPerPage = useStore(state => state.waitingList.itemsPerPage)
    const pageRawNeedle = useStore(state => state.waitingList.pageRawNeedle)
    const needle = pageRawNeedle.toLowerCase()
    const tableRef = useRef<HTMLTableSectionElement>(null)

    highlightNeedlesDebounced(needleToTokens(needle), tableRef)

    const filteredTableItems = useWaitingListData(needle)

    const { pageItems, ...paginationProps } = usePagination(filteredTableItems, selectedPage, itemsPerPage)

    const columnsToShow = useColumnsToShow()

    return (
        <div className="h-full overflow-auto">
            <table className="w-full" data-test="waiting-list-loaded">
                <thead className="border-none">
                    <TableHeaderRow />
                </thead>
                <tbody ref={tableRef} className="w-full border-y text-left text-sm font-normal text-gray-700">
                    <Show condition={!isLoading} fallback={<LoadingRows columnsToShow={Object.keys(columnsToShow).length} rowsToShow={itemsPerPage} />}>
                        {pageItems.map((item, index) => (
                            <TableRow key={item.BookingId} item={item} className="border-b last:border-0" data-test={`waiting-list-item-${index}`} />
                        ))}
                    </Show>
                </tbody>
            </table>
            <Pagination setSelectedPage={setSelectedPage} {...paginationProps} />
        </div>
    )
}
