import { Bar, DIDatePicker } from '~/components'
import { useImportSurgeryTypeGroupEntities } from '~/hooks/useImportSurgeryTypeGroupEntities'
import {
    importBlockSchedules,
    importDepartmentLocationAssignments,
    importDepartments,
    importLocations,
    importPractitionerSchedules,
    importRuleDefinitions,
} from '~/store/entity.api'
import { selectTimeArgs } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'

import { Filter } from './Header/Filter'
import { MultipleCreate } from './Header/MultipleCreate'
import { Sider } from './Sider/Sider'
import { WeeklyTable } from './WeeklyTable/WeeklyTable'

export default function MasterSchedulerPage() {
    const timeArgs = useStore(selectTimeArgs)

    const { isLoading: isLoadingSurgeryTypeGroups } = useImportSurgeryTypeGroupEntities()
    const { isLoading: isLoadingEntities } = useImportEntities(
        () => [
            importPractitionerSchedules({ ...timeArgs, exclude_practitioner: true }),
            importRuleDefinitions({}),
            importBlockSchedules({}),
            importLocations({}),
            importDepartmentLocationAssignments({}),
            importDepartments({}),
        ],
        [timeArgs]
    )

    const isLoading = isLoadingSurgeryTypeGroups || isLoadingEntities

    return (
        <div className="flex h-full">
            <Sider />
            <div className="flex h-full w-full flex-col gap-2 overflow-auto p-2">
                <Bar left={<MultipleCreate />} center={<DIDatePicker unit="week" />} right={<Filter />} />
                <div className="overflow-hidden">
                    <WeeklyTable isLoading={isLoading} />
                </div>
            </div>
        </div>
    )
}
