import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { selectEntities, UnScheduledSurgery } from '../selectors/entities'
import { DepartmentKey } from '../slices/filterSlice'

export const selectGetUnScheduledSurgeries = createSelector(selectEntities, ({ unScheduledSurgeries }) => {
    const byBookingId = keyBy(unScheduledSurgeries, 'bookingId')
    const byDepartmentId = groupBy(unScheduledSurgeries, unScheduledSurgery => {
        return unScheduledSurgery.section?.department?.id
    })

    return {
        byBookingId: (bookingId: UnScheduledSurgery['bookingId']) => {
            return !bookingId ? null : (byBookingId[bookingId] ?? null)
        },
        byDepartmentKey: (departmentKey: DepartmentKey) => {
            if (departmentKey === 'all') {
                return unScheduledSurgeries
            }

            return byDepartmentId[departmentKey] ?? []
        },
    }
})
