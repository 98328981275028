import { Dayjs } from 'dayjs'

/**
 * Returns a new date with the given time.
 *
 * @param {Dayjs} day - The day to check.
 * @param {string} time - The time to set.
 * @return {Dayjs} The new date with the given time.
 */
export const getNewDateWithTime = (date: Dayjs, time: string): Dayjs => {
    return date
        .set('hour', Number(time.split(':')[0]))
        .set('minute', Number(time.split(':')[1]))
        .clone()
}
