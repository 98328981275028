import { useState } from 'react'

import { Location, PractitionerScheduleLocation } from '~/store/selectors'

import { DatePractitionerCell } from '../utils'
import { LocationTag } from './LocationTag'

export const LocationTagsWithStaticState = ($: {
    selectedCells: DatePractitionerCell[]
    availableLocations: Location[]
    scheduleLocations: PractitionerScheduleLocation[]
}) => {
    const [initialLocationsState] = useState(() => $.availableLocations)

    return (
        <>
            {initialLocationsState.map(location => (
                <LocationTag key={location.id} selectedCells={$.selectedCells} scheduleLocations={$.scheduleLocations} location={location} />
            ))}
        </>
    )
}
