import { useState } from 'react'

import { Show } from '~/components'
import { DownOutlined, UpOutlined } from '~/icons'

const iconStyles = 'w-5 h-5'

type Props = { value: string }

export const OriginalComment = ({ value }: Props) => {
    const [isVisible, setIsVisible] = useState(false)

    return (
        <div data-test="original-comment">
            <p onClick={() => setIsVisible(!isVisible)} className="flex cursor-pointer select-none items-center text-sm text-diBlue-800">
                Oppmøtekommentar fra DIPS
                {(isVisible && <UpOutlined className={iconStyles} />) || <DownOutlined className={iconStyles} />}
            </p>

            <Show condition={isVisible}>{value === '' ? '—' : value}</Show>
        </div>
    )
}
