import 'whatwg-fetch' // this removes warning due to fetch is not accessible in test environment

import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import env from '~/env'
import { useStore } from '~/store/store'
import { isNullish } from '~/utils/guards'

const baseQuery = fetchBaseQuery({
    baseUrl: env.VITE_API_BASE_URL,
    mode: 'cors',
    credentials: 'include',
})

const baseQueryWithToasts: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)
    const isNotAuthorized = isNullish(useStore.getState().user.apiAccessToken)

    if (isNotAuthorized) {
        return result
    }

    if (result.error) {
        if (result.error.status === 401) {
            window.location.reload()
        } else {
            api.dispatch({
                type: 'toaster/addToast',
                payload: {
                    type: 'error',
                    message: 'Det oppstod en feil. Vennligst prøv igjen senere.',
                },
            })
        }
    }

    return result
}

export const emptyDIApi = createApi({
    reducerPath: 'diApi',
    baseQuery: baseQueryWithToasts,
    endpoints: () => ({}),
})
