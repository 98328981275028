import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

export const emptyDuration: Duration = dayjs.duration(0)

export function getDurationInMs(a: Duration): number {
    return a.asMilliseconds()
}

export function accumulateDuration(a: Duration, b: Duration): Duration {
    return a.add(b.asMilliseconds(), 'milliseconds')
}

export function subtractDuration(a: Duration, b: Duration): Duration {
    const result = a.subtract(b.asMilliseconds(), 'milliseconds')
    return result.asMilliseconds() > 0 ? result : emptyDuration
}
