import { Dayjs } from 'dayjs'
import moize from 'moize'

import { diApi } from '~/store/diApi'
import { BlockSchedule } from '~/store/selectors'
import { RuleDefinitionId } from '~/store/utils/ruleInstance'

import { day, format, getOnlyDate, isHoliday } from './extendedDayjs'
import { isNullish } from './guards'

export function isNumber(s?: unknown): s is number {
    return !isNaN(Number(s))
}

export type Dictionary<T> = Record<string, T>

export function getNonEmptyString(s?: string | null): string | undefined {
    if (isNullish(s)) {
        return undefined
    }
    return s.trim() === '' ? undefined : s
}

export function urlToRegex(url: string): RegExp {
    return new RegExp(`^${url.replace('/', '\\/')}`)
}

export const getEntityTimeArgs = moize(
    (startTime: string, endTime: string) => {
        return {
            'start_time:gte': startTime,
            'start_time:lte': endTime,
        } satisfies Parameters<typeof diApi.endpoints.getPractitionerSchedules.useQuery>[0]
    },
    { maxSize: 100 }
)

/**
 * @returns whether the specified element has the ellipsis due to text overflow.
 */
export function isEllipsisActive(element: HTMLElement) {
    // copied from https://stackoverflow.com/a/72637621/308451
    const temp = element.cloneNode(true) as HTMLElement

    temp.style.position = 'fixed'
    temp.style.overflow = 'visible'
    temp.style.whiteSpace = 'nowrap'
    temp.style.visibility = 'hidden'

    element.parentElement?.appendChild(temp)

    try {
        const fullWidth = temp.getBoundingClientRect().width
        const displayWidth = element.getBoundingClientRect().width

        return fullWidth > displayWidth
    } finally {
        temp.remove()
    }
}

export function getHolidayStyles(date: Dayjs | string) {
    date = typeof date === 'string' ? day(date) : date
    return isHoliday(date) ? 'bg-zinc-100' : false
}

export function formatDateToNorskReadableIdDate(date: Dayjs) {
    return { id: getOnlyDate(date), label: format(date, 'dddd DD. MMM YYYY') }
}

export function getRuleId(blockSchedule: BlockSchedule | null): RuleDefinitionId {
    return blockSchedule?.rule_instance?.rule_definition_id ?? 'max_weight_of_surgeries_per_patient_group'
}
