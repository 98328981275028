import { useCallback, useState } from 'react'

import { Schemas } from '~/clients/api-client'
import { createSection, deleteSection, updateSection } from '~/store/entity.api'
import { selectEntities } from '~/store/selectors'
import { useStore } from '~/store/store'
import { isNullish } from '~/utils/guards'

import AddNewButton from './AddNewButton'
import { EntityItem, EntityList } from './EntityList'

type Props = {
    departmentId: number | 'uncategorized'
}

const SectionsList = ({ departmentId }: Props) => {
    const [isCreating, setIsCreating] = useState(false)
    const entities = useStore(selectEntities)
    const sections = entities.sections.filter(section =>
        departmentId === 'uncategorized' ? isNullish(section.department_id) : section.department_id === departmentId
    )

    function onDelete(section: Schemas['SectionOut']) {
        void deleteSection(section.id)
    }

    const onAddNew = useCallback(async () => {
        if (isCreating) {
            return
        }

        const name = window.prompt('Enter new section name (name)')
        const dips_section_id = window.prompt('Enter new section id (dips_section_id)')

        if (!name || !dips_section_id) {
            return
        }

        setIsCreating(true)

        await createSection({
            name,
            dips_section_id: Number(dips_section_id),
            ...(departmentId === 'uncategorized' ? {} : { department_id: Number(departmentId) }),
        })

        setIsCreating(false)
    }, [departmentId, isCreating])

    function onRename(section: Schemas['SectionOut']) {
        const name = window.prompt('Enter new section name (name)', section.name)
        const dips_section_id = window.prompt('Enter new section id (dips_section_id)', section.dips_section_id.toString())

        if (!name || !dips_section_id) {
            return
        }

        void updateSection(section.id, { name, dips_section_id: Number(dips_section_id), department_id: section.department_id })
    }

    return (
        <EntityList title="Sections">
            {sections.map(section => (
                <EntityItem key={section.id} onDelete={() => onDelete(section)} onRename={() => onRename(section)}>
                    {section.name} - {section.dips_section_id}
                </EntityItem>
            ))}
            <AddNewButton onClick={onAddNew} isCreating={isCreating} entityName="Section" />
        </EntityList>
    )
}

export default SectionsList
