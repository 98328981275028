import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import { MinimalScheduledSurgery } from '~/store/dipsApi'

export function getRoomDuration(surgery: MinimalScheduledSurgery): Duration {
    return surgery.plannedDuration ? dayjs.duration(surgery.plannedDuration) : dayjs.duration(0, 'minutes')
}

export function getKnifeTime(surgery: MinimalScheduledSurgery): Duration {
    return surgery.plannedProcedureDuration ? dayjs.duration(surgery.plannedProcedureDuration) : dayjs.duration(0, 'minutes')
}
