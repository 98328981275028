import { MultipleSelect } from '../Inputs/Selects/MultipleSelect'

export const MultipleSelectWithTitle = ($: {
    title: string
    values: string[]
    options: { label: string; value: string }[]
    onChange: (args: { value: string; label: string }) => void
    emptyFilter: () => void
}) => {
    return (
        <div className="px-4 pb-4 pt-2">
            <p className="w-full pb-2">{$.title}</p>
            <MultipleSelect
                onChange={value => $.onChange({ value, label: $.options.find(option => option.value === value)?.label ?? '' })}
                emptyFilter={$.emptyFilter}
                values={$.values}
                options={$.options}
                placeholder="Søk"
            />
        </div>
    )
}
