import clsx from 'clsx'

import { CalendarOutlined } from '~/icons'

import { inputSize, inputStyles, TextInputSize } from '../../TextInput'

type Props = {
    size?: TextInputSize
    formattedDate?: string
    onClick?: () => void
}

export const DatePickerInput = ({ size = 'md', formattedDate, onClick }: Props) => (
    <div className={clsx('flex w-[250px] cursor-pointer items-center justify-between px-3 py-1', inputStyles, inputSize[size])} onClick={onClick}>
        <span className={clsx({ 'text-gray-400': !formattedDate })}>{formattedDate ?? 'Velg dato'}</span>
        <CalendarOutlined className="ml-5 h-5 w-5" />
    </div>
)
