import { immer } from 'zustand/middleware/immer'

import { Slice, useStore } from '../store'

const toastTypes = ['primary', 'success', 'warning', 'error'] as const
type ToastType = (typeof toastTypes)[number]

export type ToastProps = {
    id: number
    type: ToastType
    message: string
}

type State = {
    toasts: ToastProps[]
}

const initialState: State = {
    toasts: [],
}

type Actions = {
    actions: {
        addToast: (toast: Pick<ToastProps, 'message' | 'type'>) => void
        removeToast: (id: number) => void
    }
}

export type ToasterSlice = {
    toaster: State & Actions
}

export const createToasterSlice: Slice<ToasterSlice> = immer(set => ({
    toaster: {
        ...initialState,
        actions: {
            addToast(toast: Pick<ToastProps, 'message' | 'type'>) {
                set(state => {
                    state.toaster.toasts = [...state.toaster.toasts, { id: state.toaster.toasts.length, ...toast }]
                })
            },
            removeToast(id: ToastProps['id']) {
                set(state => {
                    state.toaster.toasts = state.toaster.toasts.filter(toast => toast.id !== id)
                })
            },
        },
    },
}))

export function dispatchErrorToast(msg: string) {
    const addToast = useStore.getState().toaster.actions.addToast

    addToast({
        message: msg,
        type: 'error',
    })
}
