import { BlockSchedule, ScheduledSurgery } from '~/store/selectors'
import { GetResolvedPatientGroups } from '~/store/selectors/resolvedPatientGroups'
import { RuleEvaluation } from '~/store/utils/blockEvaluation'
import { isNotNullish, isNullish } from '~/utils/guards'

import { CapacityRule, isCountBasedRule } from './blocks/implementations/rules/types'
import { getAllPatientGroupConstraints, getBlockRules } from './blocks/registry'
import { getSurgeriesBelongingToPatientGroup, isSurgeryInAnyPatientGroup } from './patientGroups/patientGroups'

function evaluateCapacityRule(rule: CapacityRule, scheduledSurgeries: ScheduledSurgery[]): RuleEvaluation {
    const surgeries = getSurgeriesBelongingToPatientGroup(scheduledSurgeries, rule.patientGroupFilter)

    // these if branches are duplicated because it's better to have duplicated code than type unsafe code
    if (isCountBasedRule(rule)) {
        let occupancies = rule.zeroValue
        for (const surgery of surgeries) {
            const occupancy = rule.getSurgeryOccupancy(surgery, rule.patientGroupFilter ?? null)
            occupancies = isNullish(occupancies) ? occupancy : rule.accumulate(occupancies, occupancy)
        }

        let remaining = null
        if (isNotNullish(rule.filledBlockThreshold)) {
            if (isNotNullish(occupancies)) {
                remaining = rule.subtract(rule.filledBlockThreshold, occupancies)
            } else {
                remaining = rule.filledBlockThreshold
            }
        }
        return {
            status: rule.evaluate(occupancies),
            type: rule.type,
            value: occupancies,
            remaining,
            maxValue: rule.filledBlockThreshold,
            filteredByPatientGroup: rule.patientGroupFilter ?? null,
            bookingIds: surgeries.map(surgery => surgery.id).filter(isNotNullish),
        }
    } else {
        // duration based
        let occupancies = rule.zeroValue
        for (const surgery of surgeries) {
            const occupancy = rule.getSurgeryOccupancy(surgery, rule.patientGroupFilter ?? null)
            occupancies = isNullish(occupancies) ? occupancy : rule.accumulate(occupancies, occupancy)
        }

        let remaining = null
        if (isNotNullish(rule.filledBlockThreshold)) {
            if (isNotNullish(occupancies)) {
                remaining = rule.subtract(rule.filledBlockThreshold, occupancies)
            } else {
                remaining = rule.filledBlockThreshold
            }
        }
        return {
            status: rule.evaluate(occupancies),
            type: rule.type,
            value: occupancies,
            remaining,
            maxValue: rule.filledBlockThreshold,
            filteredByPatientGroup: rule.patientGroupFilter ?? null,
            bookingIds: surgeries.map(surgery => surgery.id).filter(isNotNullish),
        }
    }
}

export function findMismatchedSurgeries(
    bookedSurgeries: ScheduledSurgery[],
    block: BlockSchedule | undefined | null,
    selectGetPatientGroups: GetResolvedPatientGroups
): ScheduledSurgery[] {
    // a mismatch is defined as a non-canceled booked surgery of a patient group that is not scheduled
    const blockRule = block?.rule_instance
    const allPatientGroupConstraints = getAllPatientGroupConstraints(blockRule).map(selectGetPatientGroups.byConstraints).filter(Boolean)
    const mismatchedSurgeries = bookedSurgeries.filter(surgery => !isSurgeryInAnyPatientGroup(surgery, allPatientGroupConstraints))
    return mismatchedSurgeries
}
/**
 * evaluate the booked surgeries against the rule(s) defined in the scheduled block
 * Booked surgeries are all surgeries that are booked in the same time period and location as the scheduled block.
 * - The time period is typically, but not necessary, a day in the Operational Planning calendar
 * - The location is typically, but not necessary, a theatre room in the Operational Planning calendar
 *
 * @see BlockScheduleOutPopulated for the definition of a scheduled block - time period and location
 *
 * @param block the rule instance of the scheduled block.
 * @param scheduledSurgeries a list of all surgeries booked in this scheduled block
 * @param allPatientGroups a list of all patient groups
 * @param logParams the date and location of this block (for logging only, and therefore optional in tests)
 * @returns the evaluation of the block: the occupancy of the theatre room at the date of the scheduled block.
 */
export function evaluateBlock(
    block: BlockSchedule | undefined | null,
    scheduledSurgeries: ScheduledSurgery[],
    selectGetPatientGroups: GetResolvedPatientGroups
): RuleEvaluation[] {
    const blockRule = block?.rule_instance
    if (isNullish(blockRule)) {
        return []
    }

    const capacityRules = getBlockRules(blockRule, selectGetPatientGroups)
    const evaluations: RuleEvaluation[] = []
    for (const rule of capacityRules) {
        const evaluation = evaluateCapacityRule(rule, scheduledSurgeries)
        evaluations.push(evaluation)
    }

    return evaluations
}
