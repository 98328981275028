import env from '~/env'
import { importFullScheduledSurgeries } from '~/store/dips-entity.api'
import {
    importBlockLocks,
    importBlockSchedules,
    importDepartmentLocationAssignments,
    importDepartmentPractitionerAssignments,
    importLocations,
    importLocationSchedules,
    importPractitioners,
    importPractitionerSchedules,
    importRuleDefinitions,
} from '~/store/entity.api'
import { useImportEntities } from '~/store/useImportEntities'
import { useAutomaticBlockLockRelease } from '~/utils/blockLock'
import { getEntityTimeArgs } from '~/utils/utils'

import { useImportSurgeryTypeGroupEntities } from './useImportSurgeryTypeGroupEntities'

export function useImportOccupancyEntities(startTime: string, endTime: string) {
    const args = getEntityTimeArgs(startTime, endTime)
    const treatmentLocationId = Number(env.VITE_DIPS_TREATMENT_LOCATION_ID)

    const common = useImportEntities(
        () => [
            importRuleDefinitions({}),
            importBlockSchedules({ ...args }),
            importBlockLocks({ ...args }),
            importPractitioners({}),
            importPractitionerSchedules({ ...args, exclude_practitioner: true }),
            importDepartmentPractitionerAssignments({}),
            importLocations({}),
            importLocationSchedules({ ...args, exclude_location: true }),
            importDepartmentLocationAssignments({}),
        ],
        [args]
    )

    const surgeryTypeGroups = useImportSurgeryTypeGroupEntities()

    const dips = useImportEntities(() => [importFullScheduledSurgeries(startTime, endTime, treatmentLocationId)], [startTime, endTime, treatmentLocationId], {
        refetchInterval: 1000 * 30,
    })

    useAutomaticBlockLockRelease({
        skip: !common.isSuccess || !dips.isSuccess || !surgeryTypeGroups.isSuccess,
    })

    return {
        isLoading: common.isLoading || dips.isLoading || surgeryTypeGroups.isLoading,
        isError: common.isError || dips.isError || surgeryTypeGroups.isError,
        isSuccess: common.isSuccess && dips.isSuccess && surgeryTypeGroups.isSuccess,
    }
}
