import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { pages } from '~/app/routes'
import { Button, Show } from '~/components'
import { useUnScheduledSuggestions } from '~/hooks/useUnScheduledSuggestions'
import { HelpOutlined } from '~/icons'
import { OccupancyData, Practitioner } from '~/store/selectors'
import { useStore } from '~/store/store'

import { EmptyItem } from './EmptyItem'
import { LoadingCard } from './LoadingCard'
import { SuggestionsInfoCard } from './SuggestionsInfoCard'
import { SurgeryCard } from './SurgeryCard'

const MAX_LIST_LENGTH = 5

type Props = {
    occupancyData: OccupancyData | null
    practitioners: Practitioner[]
}

export const BookingWaitingList = ({ practitioners, occupancyData }: Props) => {
    const settings = useStore(state => state.user.settings)
    const setHideSuggestionsInfo = useStore(state => state.user.actions.setHideSuggestionsInfo)
    const { unScheduledSuggestions, isLoading } = useUnScheduledSuggestions({
        scheduledPractitioners: practitioners,
        occupancyData,
    })

    const [showSuggestionsCard, setShowSuggestionsCard] = useState(!settings.hideSuggestionsInfo)

    const handleHideAlwaysButtonClick = () => {
        setHideSuggestionsInfo(true)
        setShowSuggestionsCard(false)
    }

    const getListTitle = () => {
        return `${unScheduledSuggestions.length > MAX_LIST_LENGTH ? `Topp ${unScheduledSuggestions.slice(0, MAX_LIST_LENGTH).length} av ${unScheduledSuggestions.length}` : `${unScheduledSuggestions.length}`} mulige pasienter`
    }

    return (
        <>
            <div className="flex items-center justify-between">
                <p className="flex items-center gap-1 text-base">
                    {getListTitle()}
                    {unScheduledSuggestions.length > 0 && (
                        <Button size="icon" color="custom" onClick={() => setShowSuggestionsCard(prev => !prev)}>
                            <HelpOutlined />
                        </Button>
                    )}
                </p>
                <Link className="text-diBlue-500 underline" to={pages.WAITING_LIST.views.unscheduled.path}>
                    Se hele ventelisten
                </Link>
            </div>
            <AnimatePresence>
                {showSuggestionsCard && unScheduledSuggestions.length > 0 && (
                    <SuggestionsInfoCard
                        showButton={!settings.hideSuggestionsInfo}
                        onHideButtonClick={handleHideAlwaysButtonClick}
                        onClose={() => setShowSuggestionsCard(false)}
                    />
                )}
            </AnimatePresence>

            <Show
                condition={!isLoading}
                fallback={
                    <>
                        <LoadingCard />
                        <LoadingCard />
                    </>
                }
            >
                {unScheduledSuggestions.length > 0 ? (
                    unScheduledSuggestions
                        .slice(0, MAX_LIST_LENGTH)
                        .map(surgery => <SurgeryCard surgery={{ type: 'unscheduled', value: surgery }} key={surgery.bookingId} />)
                ) : (
                    <EmptyItem>
                        <p>
                            Vi fant ingen pasienter på ventelisten
                            {practitioners.length > 0 && ' til'}
                            {practitioners.length > 0 && <span className="font-semibold"> {practitioners.map(prac => prac.short_name).join(', ')}</span>} som
                            passer disse operasjonstypene.
                        </p>
                    </EmptyItem>
                )}
                <div data-test="booking-drawer-waiting-list-loaded" className="hidden" />
            </Show>
        </>
    )
}
