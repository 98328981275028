import env from '~/env'

/**
 * Simple wrapper around fetch to make it easier to use the DI API.
 * This is a temporary solution until we replace the RTK Query generated API with some other one.
 *
 * It's temporary because the return types are not embedded in the function signature, which
 * makes it less safe to use.
 */
export async function diApiFetch<T>(userUrl: string, userOptions?: RequestInit): Promise<T> {
    const defaultOptions: RequestInit = {
        mode: 'cors',
        credentials: 'include',
    }

    const options = { ...defaultOptions, ...userOptions }
    const url = new URL(userUrl, env.VITE_API_BASE_URL).toString()

    const response = await fetch(url, options)
    const data = (await response.json()) as T

    return data
}
