import { Outlet } from 'react-router-dom'

import { Navigation } from './Navigation'
import { UserMenu } from './UserMenu'

export const MainLayout = () => (
    <div className="flex h-screen flex-col overflow-hidden">
        <div className="flex items-center border-b px-2">
            <div className="grow">
                <Navigation />
            </div>
            <UserMenu />
        </div>

        <div className="grow overflow-auto bg-white">
            <Outlet />
        </div>
    </div>
)
