import { createSelector } from 'reselect'

import { selectGetScheduledSurgeries, selectGetUnScheduledSurgeries } from '~/store/selectors'
import { StoreState } from '~/store/store'

export const selectWaitingListItems = createSelector(
    (state: StoreState) => state.appFilters.departmentKey,
    (state: StoreState) => state.app.activeViews.WAITING_LIST,
    selectGetUnScheduledSurgeries,
    selectGetScheduledSurgeries,
    (departmentKey, activeView, getUnScheduledSurgeries, getScheduledSurgeries) => {
        if (activeView === '/waiting-list/scheduled') {
            return getScheduledSurgeries.byDepartmentKey(departmentKey)
        } else if (activeView === '/waiting-list/unscheduled') {
            return getUnScheduledSurgeries.byDepartmentKey(departmentKey)
        }

        // Assumed 'All'
        return [...getScheduledSurgeries.byDepartmentKey(departmentKey), ...getUnScheduledSurgeries.byDepartmentKey(departmentKey)]
    }
)

export const selectAllWaitingListItems = createSelector(
    (state: StoreState) => state.appFilters.departmentKey,
    selectGetUnScheduledSurgeries,
    selectGetScheduledSurgeries,
    (departmentKey, getUnScheduledSurgeries, getScheduledSurgeries) => {
        return [...getScheduledSurgeries.byDepartmentKey(departmentKey), ...getUnScheduledSurgeries.byDepartmentKey(departmentKey)]
    }
)
