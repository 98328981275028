import { Dayjs } from 'dayjs'

import { add } from './addAndSubtract'
import { isWeekend } from './isWeekend'

const cache: Map<number, Map<number, Dayjs[]>> = new Map()

/**
 * Calculates the days between two dates inclusively.
 *
 * @param {Dayjs} start - The start date.
 * @param {Dayjs} end - The end date.
 * @returns {Dayjs[]} - An array of dates between the start and end dates (including both start and end dates).
 */
export function getDaysBetween(start: Dayjs, end: Dayjs): Dayjs[] {
    const cachedStart = cache.get(start.valueOf())
    const cached = cachedStart?.get(end.valueOf())

    if (cached) {
        return cached
    }

    const range: Dayjs[] = []
    let current = start
    while (!current.isAfter(end)) {
        range.push(current)
        current = add(current, 1, 'day')
    }

    cache.set(start.valueOf(), new Map([[end.valueOf(), range]]))

    return range
}

export function getWeekdaysBetween(start: Dayjs, end: Dayjs): Dayjs[] {
    return getDaysBetween(start, end).filter(day => !isWeekend(day))
}
