import { Dayjs } from 'dayjs'

import { getToday } from '~/utils/extendedDayjs'

import { Button } from '../../../Button'

type Props = {
    setDateToToday: (today: Dayjs) => void
    allowClear?: boolean
    clearDate: () => void
}

export const DatePickerBottom = ({ setDateToToday, clearDate, allowClear }: Props) => (
    <div className="flex justify-evenly pb-2 pt-1">
        {allowClear && (
            <Button size="sm" color="delete" link onClick={clearDate}>
                Fjern
            </Button>
        )}
        <Button size="sm" link onClick={() => setDateToToday(getToday())}>
            I dag
        </Button>
    </div>
)
