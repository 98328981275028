import { Dayjs } from 'dayjs'
import groupBy from 'lodash/groupBy'

import { Show, Spinner } from '~/components'
import { useImportOccupancyEntities } from '~/hooks/useImportOccupancyEntities'
import { LocationOut } from '~/store/diApi'
import {
    selectActiveFilteredLocations,
    selectGetBlockLocks,
    selectGetNeedleMatch,
    selectGetOccupancies,
    selectGetScheduledPractitioners,
} from '~/store/selectors'
import { selectPractitionerValues, selectWeekdayValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { OccupancyStatus, summarizeCapacityEvaluations } from '~/store/utils/blockEvaluation'
import { day, dayOfWeek, format, getOnlyDate, getToday, getWeekdaysBetween, isHoliday } from '~/utils/extendedDayjs'

import { AvailableTimeCard } from './AvailableTimeCard'

function getOccupancyDataKey(date: Dayjs, room_code: LocationOut['room_code']) {
    return `${getOnlyDate(date)}__${room_code}`
}

const getMonthStartEndDays = (date: Dayjs) => {
    const today = getToday()
    const startOfMonth = date.startOf('month')
    const endOfMonth = date.endOf('month')
    const isTodayInMonth = today.isBetween(startOfMonth, endOfMonth, 'day', '[]')

    return {
        start: isTodayInMonth ? today : startOfMonth,
        end: endOfMonth.add(1, 'day'),
    }
}

export const AvailableTime = () => {
    const selectedDate = useStore(state => state.appFilters.selectedDate)
    const activeLocations = useStore(selectActiveFilteredLocations)
    const filteredWeekdayValues = useStore(selectWeekdayValues)
    const getBlockLocks = useStore(selectGetBlockLocks)
    const getOccupancies = useStore(selectGetOccupancies)
    const getScheduledPractitioners = useStore(selectGetScheduledPractitioners)
    const activePractitionerShortNames = useStore(selectPractitionerValues)
    const freeTextFilter = useStore(state => state.operationalPlanner.needle)
    const getNeedleMatch = useStore(selectGetNeedleMatch)

    const { start, end } = getMonthStartEndDays(selectedDate)
    const weekDaysInMonth = getWeekdaysBetween(day(start), day(end))
    const weekDaysInMonthByWeek = groupBy(weekDaysInMonth, value => value.isoWeek())

    const { isLoading } = useImportOccupancyEntities(format(start), format(end))

    if (isLoading) {
        return (
            <div className="flex h-screen w-full items-center justify-center overflow-hidden">
                <Spinner size="lg" />
            </div>
        )
    }

    return (
        <div className="h-full overflow-auto pt-4" data-test="availability-view-page">
            <div className="flex flex-col gap-y-8">
                {Object.entries(weekDaysInMonthByWeek).map(([weekNumber, days]) => {
                    const isPartialWeek = days.length < 5
                    const availabilityCards = days
                        .flatMap(date =>
                            activeLocations.map(location => {
                                if (isHoliday(date)) return null

                                if (filteredWeekdayValues.length > 0 && !filteredWeekdayValues.includes(String(dayOfWeek(date)))) {
                                    return null
                                }

                                const isMatch = getNeedleMatch.byScheduledPractitioners(date, location, freeTextFilter)
                                if (!isMatch) return null

                                const blockLock = getBlockLocks.byDateAndLocationId(date, location.id)
                                if (blockLock) return null

                                const scheduledPractitioners = getScheduledPractitioners.byDateAndLocation(date, location)

                                if (
                                    activePractitionerShortNames.length > 0 &&
                                    !scheduledPractitioners.some(practitioner => activePractitionerShortNames.includes(practitioner.short_name))
                                ) {
                                    return null
                                }

                                const occupancyData = getOccupancies.byDateAndLocation(date, location)
                                if (summarizeCapacityEvaluations(occupancyData.evaluations) !== OccupancyStatus.Available) return null

                                const key = getOccupancyDataKey(date, location.room_code)

                                return (
                                    <AvailableTimeCard
                                        key={key}
                                        date={date}
                                        location={location}
                                        scheduledPractitioners={scheduledPractitioners}
                                        evaluations={occupancyData.evaluations}
                                        occupancyData={occupancyData}
                                    />
                                )
                            })
                        )
                        .filter(Boolean)
                    return (
                        <div key={weekNumber} className="flex flex-col gap-y-2">
                            <p className="mb-2">
                                Uke {weekNumber} {isPartialWeek && `(kun ${format(selectedDate, 'MMMM')})`}
                            </p>
                            <div className="flex flex-col gap-y-2">
                                <Show condition={availabilityCards.length > 0} fallback={<p className="text-gray-500">Ingen tilgjengelige tider</p>}>
                                    {availabilityCards}
                                </Show>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
