import { ResolvedConstraintObject } from '~/utils/blockEvaluation'

export function groupByDepartment(surgeryTypeGroupConstraints: ResolvedConstraintObject[]) {
    return surgeryTypeGroupConstraints.reduce(
        (dict, value) => {
            for (const key of value.specialities) {
                ;(dict[key] ||= []).push(value)
            }
            return dict
        },
        {} as { [key: string]: ResolvedConstraintObject[] }
    )
}

export function sumSurgeryTypeGroupCounts(groupConstraints: ResolvedConstraintObject[]) {
    return groupConstraints?.reduce(
        (result: ResolvedConstraintObject, current: ResolvedConstraintObject) => {
            let value = result.value
            if (current.minCount === 0 && current.maxCount === 0 && current.surgeryTypeGroupLabel !== '') {
                value = '*'
            }
            return {
                value: value,
                surgeryTypeGroupLabel: current.surgeryTypeGroupLabel,
                groupLevel: current.groupLevel,
                specialities: current.specialities,
                minCount: (result.minCount += current.minCount),
                maxCount: (result.maxCount += current.maxCount),
            }
        },
        {
            minCount: 0,
            maxCount: 0,
            surgeryTypeGroupLabel: '',
            value: '',
            specialities: [],
            groupLevel: 'none',
        }
    )
}

export function sumSpecialityCounts(speciality: string, specialityConstraints: ResolvedConstraintObject[]) {
    return specialityConstraints?.reduce(
        (result: { minCount: number; maxCount: number; someUnresolved: boolean }, current: ResolvedConstraintObject) => {
            const unresolved = result.someUnresolved ? true : current.value !== ''
            return {
                speciality: speciality,
                minCount: (result.minCount += current.minCount),
                maxCount: (result.maxCount += current.maxCount),
                someUnresolved: unresolved,
            }
        },
        { minCount: 0, maxCount: 0, speciality: speciality, someUnresolved: false }
    )
}
