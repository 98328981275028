import moize from 'moize'

import { day } from './day'
import { format } from './format'

/**
 * Calculates the date of "1. påskedag" for a given year
 * based on the Gregorian calendar , see
 * [(Meeus/Jones/Butcher algorithm)](https://en.wikipedia.org/wiki/Date_of_Easter#Anonymous_Gregorian_algorithm)
 * */
const getGregorianEaster = (year: number) => {
    const a = year % 19
    const b = Math.floor(year / 100)
    const c = year % 100
    const d = Math.floor(b / 4)
    const e = b % 4
    const f = Math.floor((b + 8) / 25)
    const g = Math.floor((b - f + 1) / 3)
    const h = (19 * a + b - d - g + 15) % 30
    const i = Math.floor(c / 4)
    const k = c % 4
    const l = (32 + 2 * e + 2 * i - h - k) % 7
    const m = Math.floor((a + 11 * h + 22 * l) / 451)
    const n0 = h + l + 7 * m + 114
    const n = Math.floor(n0 / 31) - 1
    const p = (n0 % 31) + 1
    return day(new Date(year, n, p))
}

type Holidays = Record<string, string>

// exported for testing only
/**
 * Calculates Norwegian holidays for a given year
 * */
export const getHolidays = moize(
    (year: string) => {
        const easter = getGregorianEaster(Number(year))
        const holidaysList = [
            [format(easter.subtract(7, 'day'), 'YYYY-MM-DD'), 'Palmesøndag'],
            [format(easter.subtract(3, 'day'), 'YYYY-MM-DD'), 'Skjærtorsdag'],
            [format(easter.subtract(2, 'day'), 'YYYY-MM-DD'), 'Langfredag'],
            [format(easter, 'YYYY-MM-DD'), '1. påskedag'],
            [format(easter.add(1, 'day'), 'YYYY-MM-DD'), '2. påskedag'],
            [format(easter.add(39, 'day'), 'YYYY-MM-DD'), 'Kristi Himmelfartsdag'],
            [format(easter.add(49, 'day'), 'YYYY-MM-DD'), '1. pinsedag'],
            [format(easter.add(50, 'day'), 'YYYY-MM-DD'), '2. pinsedag'],
            [format(day(`${year}-01-01`), 'YYYY-MM-DD'), '1. nyttårsdag'],
            [format(day(`${year}-05-01`), 'YYYY-MM-DD'), 'Arbeidernes dag'],
            [format(day(`${year}-05-17`), 'YYYY-MM-DD'), 'Grunnlovsdagen'],
            [format(day(`${year}-12-25`), 'YYYY-MM-DD'), '1. juledag'],
            [format(day(`${year}-12-26`), 'YYYY-MM-DD'), '2. juledag'],
        ] as const
        const holidaysObject: Holidays = {}

        // Overwriting possible duplicate keys (e.g. '2027-05-17' is both 'Grunnlovsdag' and '2. pinsedag')
        holidaysList.forEach(([key, value]) => {
            holidaysObject[key] = value
        })

        return holidaysObject
    },
    { maxSize: 10 }
)

/**
 * Determines if the given date is a holiday in Norway.
 *
 * @param args - The arguments to pass to `dayjs` to create the date.
 * @returns {boolean} `true` if the date is a holiday, `false` otherwise.
 */
export function isHoliday(...args: Parameters<typeof day>): boolean {
    const date = day(...args)

    const formattedDate = format(date, 'YYYY-MM-DD')
    const year = formattedDate.substring(0, 4)

    const holidays = getHolidays(year)
    return holidays[formattedDate] !== undefined
}
