import { PractitionerScheduleStatus } from '~/store/selectors'
import { Status, statusesMap } from '~/store/slices/filterSlice'
import { day, humanizeDate } from '~/utils/extendedDayjs'

import { ButtonTag } from '../../shared/ButtonTag'
import { DatePractitionerCell } from '../utils'
import { useToggleStatuses } from './useToggleStatuses'
import { createGetStatusStateFn } from './utils'

export const StatusTag = ($: { selectedCells: DatePractitionerCell[]; scheduleStatuses: PractitionerScheduleStatus[]; status: Status }) => {
    const getStatusState = createGetStatusStateFn($.selectedCells, $.scheduleStatuses)
    const { toggleStatus, isLoading } = useToggleStatuses($.selectedCells, $.scheduleStatuses)

    function getTooltip(status: Status) {
        if ($.selectedCells.length !== 1) return statusesMap[status].tooltip

        const scheduleStatus = $.scheduleStatuses.find(scheduleStatus => scheduleStatus.status_code === status)
        if (!scheduleStatus) return statusesMap[status].tooltip

        const editInfo = `Sist endret av ${scheduleStatus.updated_by} ${humanizeDate(day(scheduleStatus.updated_at))}`
        return `${statusesMap[status].tooltip}\n${editInfo}`
    }

    return (
        <ButtonTag
            disabled={isLoading}
            isLoading={isLoading}
            colorType={statusesMap[$.status].color}
            fillType={getStatusState($.status)}
            onClick={() => toggleStatus($.status)}
            data-tooltip={getTooltip($.status)}
        >
            {statusesMap[$.status].label}
        </ButtonTag>
    )
}
