import { ReactNode } from 'react'

type Props = {
    left?: ReactNode
    center?: ReactNode
    right?: ReactNode
}

export const Bar = ({ left, center, right }: Props) => (
    <div className="grid grid-cols-3">
        {left && <div className="col-start-1 justify-self-start">{left}</div>}
        {center && <div className="col-start-2 justify-center">{center}</div>}
        {right && <div className="col-start-3 justify-self-end">{right}</div>}
    </div>
)
