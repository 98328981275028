import { WidgetProps } from '@rjsf/utils'

import { SingleSelect } from '~/components'

export const CustomSelectWidget = ({ onChange, options, value }: WidgetProps) => {
    const candidateOption = options.enumOptions?.find(({ value: optionValue }) => optionValue === value)

    return (
        <SingleSelect
            onChange={onChange}
            size="sm"
            value={candidateOption?.value}
            placeholder="Søk etter en pasientgruppe"
            options={options.enumOptions as { label: string; value: string }[]}
        />
    )
}
