import { ArrayFieldTemplateProps } from '@rjsf/utils'

import { AddOutlined, DeleteOutlined } from '~/icons'

import { Button } from '../../../components'

export const CustomArrayFieldTemplate = (props: ArrayFieldTemplateProps) => (
    <>
        {props.items.map(element => (
            <div key={element.index} className="mb-2 flex items-center justify-between gap-2">
                <div className="w-full">{element.children}</div>

                <Button
                    size="custom"
                    className="p-[2px]"
                    color="delete"
                    onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        element.onDropIndexClick(element.index)()
                    }}
                >
                    <DeleteOutlined className="h-4 w-4" />
                </Button>
            </div>
        ))}

        <Button onClick={props.onAddClick} size="custom">
            <AddOutlined />
        </Button>
    </>
)
