import keyBy from 'lodash/keyBy'

import { isNotNullish } from '~/utils/guards'
import { Dictionary } from '~/utils/utils'

import {
    getSurgeryTypeGroupOutParentChildKey,
    ResolvedSurgeryTypeGroup,
    ResolvedSurgeryTypeGroupMetadata,
    SurgeryTypeGroupCode,
    SurgeryTypeGroupId,
} from '../surgeryTypeGroups'
import { SurgeryTypeGroup, SurgeryTypeGroupHierarchy } from './../entities'

/**
 * Resolves the weight of each surgery type in the group, taking into account that the hierarchy may override the weight.
 */
export function resolveSurgeryTypeGroup(
    surgeryTypeGroup: SurgeryTypeGroup,
    hierarchy: Dictionary<SurgeryTypeGroupHierarchy>,
    weight?: number | null
): ResolvedSurgeryTypeGroupMetadata {
    const results: ResolvedSurgeryTypeGroupMetadata = {
        resolvedSurgeryTypes: {},
        sortedUniqueSurgeryWeights: [],
    }

    for (const child of surgeryTypeGroup.children) {
        // Check if the hierarchy overrides the weight
        const key = getSurgeryTypeGroupOutParentChildKey(surgeryTypeGroup.id, child.id)
        const child_weight = hierarchy[key]?.weight ?? weight

        // Recursively resolve the child
        const childResults = resolveSurgeryTypeGroup(child, hierarchy, child_weight)
        for (const hospitalSurgeryTypeId in childResults.resolvedSurgeryTypes) {
            const metadata = childResults.resolvedSurgeryTypes[Number(hospitalSurgeryTypeId)]
            if (metadata) {
                results.resolvedSurgeryTypes[Number(hospitalSurgeryTypeId)] = metadata
            }
        }
    }

    surgeryTypeGroup.surgeryTypes.reduce((acc, surgeryType) => {
        // Check if the weight was overridden in the hierarchy
        acc.resolvedSurgeryTypes[surgeryType.hospital_surgery_type_id] = {
            weight: isNotNullish(weight) ? weight : surgeryType.weight,
        }
        return acc
    }, results)

    const uniqueWeights = new Set<number>()
    for (const surgeryType in results.resolvedSurgeryTypes) {
        uniqueWeights.add(results.resolvedSurgeryTypes[surgeryType]?.weight ?? 1.0)
    }
    results.sortedUniqueSurgeryWeights = [...uniqueWeights].sort((a, b) => a - b) // sort with the lowest first.

    return results
}

type ResolvedSurgeryTypeGroupsById = Record<SurgeryTypeGroupId, ResolvedSurgeryTypeGroup>
type ResolvedSurgeryTypeGroupsByCode = Record<SurgeryTypeGroupCode, SurgeryTypeGroupId>

export function resolveSurgeryTypeGroups(surgeryTypeGroupHierarchies: SurgeryTypeGroupHierarchy[], surgeryTypeGroups: SurgeryTypeGroup[]) {
    const hierarchy = keyBy(surgeryTypeGroupHierarchies, hierarchy =>
        getSurgeryTypeGroupOutParentChildKey(hierarchy.parent_surgery_type_group_id, hierarchy.child_surgery_type_group_id)
    )

    const resolvedSurgeryTypeGroupsById: ResolvedSurgeryTypeGroupsById = {}
    const resolvedSurgeryTypeGroupsByCode: ResolvedSurgeryTypeGroupsByCode = {}
    surgeryTypeGroups.reduce((resolved, surgeryTypeGroup) => {
        resolved[surgeryTypeGroup.id] = {
            ...surgeryTypeGroup,
            ...resolveSurgeryTypeGroup(surgeryTypeGroup, hierarchy),
            resolvedChildren: [],
        }
        return resolved
    }, resolvedSurgeryTypeGroupsById)

    // add the resolved children
    for (const surgeryTypeGroup of Object.values(resolvedSurgeryTypeGroupsById)) {
        surgeryTypeGroup.resolvedChildren = surgeryTypeGroup.children.map(child => resolvedSurgeryTypeGroupsById[child.id]).filter(isNotNullish)
    }

    for (const surgeryTypeGroupId in resolvedSurgeryTypeGroupsById) {
        const surgeryTypeGroup = resolvedSurgeryTypeGroupsById[surgeryTypeGroupId]
        if (surgeryTypeGroup?.code) {
            resolvedSurgeryTypeGroupsByCode[surgeryTypeGroup.code] = Number(surgeryTypeGroupId)
        }
    }

    return { resolvedSurgeryTypeGroupsById, resolvedSurgeryTypeGroupsByCode }
}
