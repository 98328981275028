import { Dayjs } from 'dayjs'

import { AccountCircleOutlined } from '~/icons'
import { Location, selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { day } from '~/utils/extendedDayjs'

type Props = {
    date: Dayjs
    location: Location
}

export const SurgeonCodeTags = ({ date, location }: Props) => {
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const surgeonCodes = getPractitionerSchedules
        .byDateAndLocationId(day(date), location.id)
        .map(ps => ps.practitioner?.short_name)
        .filter(Boolean)

    return (
        <div className="flex gap-2 px-0 py-2">
            {surgeonCodes?.map((surgeonCode, index) => (
                <div key={index} className="text-xs text-gray-600">
                    <span className="flex w-fit items-center gap-[2px]">
                        <AccountCircleOutlined className="h-4 w-4 text-black" />
                        {surgeonCode}
                    </span>
                </div>
            ))}
        </div>
    )
}
