import { Dayjs } from 'dayjs'

import { subtract } from './addAndSubtract'

/**
 * Retrieve Friday in a week based on a given date.
 *
 * @param {Dayjs} date - The date used to determine the week.
 * @return {Dayjs} - Friday in the week as an instance of Dayjs.
 */
export function getFridayInWeek(date: Dayjs): Dayjs {
    if (date.isoWeekday() === 5) return date
    return subtract(date.endOf('isoWeek'), 2, 'day')
}
