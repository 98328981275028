import { CollapsibleCard } from '~/components'
import { selectActiveFilteredLocations, selectGetBlockSchedules, selectSurgeryTypeGroup, selectWeekIdDates } from '~/store/selectors'
import { useStore } from '~/store/store'

import { resolveWeeklyCounts } from './resolveWeeklyCounts'
import { TagList } from './TagList'

function formatSpecialityCountValue(specialityCount: { minCount: number; maxCount: number; speciality: string; someUnresolved: boolean }) {
    const num = specialityCount.maxCount > specialityCount.minCount ? `${specialityCount.minCount}-${specialityCount.maxCount}` : `${specialityCount.maxCount}`
    return specialityCount.someUnresolved ? `${num}*` : num
}

export const Sider = () => {
    const weekIdDates = useStore(selectWeekIdDates)
    const getBlockSchedules = useStore(selectGetBlockSchedules)
    const getSurgeryTypeGroup = useStore(selectSurgeryTypeGroup)
    const activeFilteredLocationIds = useStore(selectActiveFilteredLocations).map(location => location.id)

    const weeklyCountData = resolveWeeklyCounts(weekIdDates, getBlockSchedules.byDate, activeFilteredLocationIds, getSurgeryTypeGroup)
    const specialityCounts = Object.values(weeklyCountData.specialityCounts)

    return (
        <div className="bg-stone-50 px-4 pt-11">
            <div className="w-72">
                <p className="mb-6 font-bold">Antall pasienter</p>

                <div className="flex flex-col gap-y-3">
                    {specialityCounts.map((specialityCount, index) => {
                        const specialityConstraint = weeklyCountData?.specialityConstraints[specialityCount.speciality]

                        return (
                            <CollapsibleCard
                                key={index}
                                title={specialityCount.speciality}
                                className="bg-white"
                                secondaryIcon={formatSpecialityCountValue(specialityCount)}
                            >
                                <div className="flex flex-col gap-1 pt-4">
                                    <TagList groupLevel={'speciality'} specialityConstraints={specialityConstraint} />
                                    <TagList groupLevel={'subspeciality'} specialityConstraints={specialityConstraint} />
                                    <TagList groupLevel={'composite'} specialityConstraints={specialityConstraint} />
                                    <TagList groupLevel={'category'} specialityConstraints={specialityConstraint} />
                                </div>
                            </CollapsibleCard>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
