import { BaseInputTemplateProps } from '@rjsf/utils'

import { Button } from '~/components'
import { AddOutlined, MinusOutlined } from '~/icons'

import { DurationInput } from '../components/DurationInput'
import { NumberInput } from '../components/NumberInput'

type Format = 'duration'

export const customFormats: Record<Format, RegExp> = {
    duration: /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/,
}

const buttonStyles = 'flex-none cursor-pointer p-[2px]'
const iconStyles = 'w-4 h-4'

export const InputTemplate = (props: BaseInputTemplateProps) => {
    function handleChangeNumberInput(change: 'add' | 'substract') {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault()

            const parsedValue = parseInt(props.value)

            if (isNaN(parsedValue)) {
                props.onChange(change === 'add' ? 1 : -1)
            } else {
                props.onChange(change === 'add' ? parsedValue + 1 : parsedValue - 1)
            }
        }
    }

    if (['integer', 'number'].includes(props.schema.type as string)) {
        return (
            <div className="flex max-w-full gap-1">
                <Button className={buttonStyles} color="white" size="custom" onClick={handleChangeNumberInput('substract')}>
                    <MinusOutlined className={iconStyles} />
                </Button>
                <NumberInput {...props} />
                <Button className={buttonStyles} color="white" size="custom" onClick={handleChangeNumberInput('add')}>
                    <AddOutlined className={iconStyles} />
                </Button>
            </div>
        )
    }

    if (props.schema.type === 'string' && props.schema.format === 'duration') {
        return (
            <div className="flex gap-2">
                <p>Timer</p>
                <DurationInput {...props} />
            </div>
        )
    }

    return null
}
