import clsx from 'clsx'

import { useStore } from '~/store/store'

export const WaitingListDrawerHeader = () => {
    const drawerProps = useStore(state => state.waitingList.drawerProps)

    return (
        <header data-test="waiting-list-drawer-header">
            <div
                className={clsx('flex', {
                    'justify-between': location,
                    'justify-end': !location,
                })}
            >
                {drawerProps && (
                    <div className="font-bold">
                        {drawerProps.item.Patient} - {drawerProps.item.Age}
                    </div>
                )}
            </div>
        </header>
    )
}
