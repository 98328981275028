import { ReactNode } from 'react'

export const ModalElement = ($: { label: string; count: number; maxCount: number; children?: ReactNode }) => (
    <div className="px-3 pt-2 pb-3 border-b pointer-events-auto">
        <div className="flex justify-between items-center">
            <span>{$.label}</span>
            <span className="text-gray-500">{$.count}/{$.maxCount}</span>
        </div>

        <div className="grid grid-cols-3 gap-2 mt-3">{$.children}</div>
    </div>
)
