import { Weekday } from 'rrule'

const TimeTokens = {
    every: 'hver',
    until: 'til',
    day: 'dag',
    days: 'dager',
    week: 'uke',
    weeks: 'uker',
    on: 'på',
    in: 'i',
    'on the': 'på',
    for: 'for',
    and: 'og',
    or: 'eller',
    at: 'ved',
    last: 'sist',
    st: '.',
    nd: '.',
    rd: '.',
    th: '.',
    '(~ approximate)': '(~ omtrentlig)',
    times: 'ganger',
    time: 'tid',
    minutes: 'minutter',
    hours: 'timer',
    weekdays: 'ukedager',
    weekday: 'ukedag',
    months: 'måneder',
    month: 'måned',
    years: 'år',
    year: 'år',
}

function isTimeTokenKey(key: string | number): key is keyof typeof TimeTokens {
    return key in TimeTokens
}

export const getNorwegianText = (id: string | number | Weekday) => {
    if (typeof id === 'number' || typeof id === 'string') {
        if (isTimeTokenKey(id)) {
            return TimeTokens[id]
        }
    }

    return String(id)
}
