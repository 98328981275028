import identity from 'lodash/identity'
import omit from 'lodash/omit'

import { day, format } from '~/utils/extendedDayjs'

import { dateHeaders, metaHeaders } from './config'

function filterKeys<T extends object>(object: T): T {
    return omit(object, metaHeaders) as T
}

function formatDates<T extends object>(object: T): T {
    return Object.fromEntries(
        Object.entries(object).map(([key, value]) => {
            if (value !== null && dateHeaders.includes(key)) {
                return [key, format(day(value), 'YYYY-MM-DD HH:mm')]
            }
            return [key, value]
        })
    ) as T
}

export function createObjectTransformer(shouldFilterKeys: boolean, shouldFormatDates: boolean) {
    const filterer = shouldFilterKeys ? filterKeys : identity
    const formatter = shouldFormatDates ? formatDates : identity

    return function transformer<T>(object: T): T {
        if (typeof object !== 'object' || object === null) {
            return object
        }

        return formatter(filterer(object)) as T
    }
}

export function getType(value: unknown, header: string): 'date-string' | 'array' | 'object' | 'other' {
    if (dateHeaders.includes(header)) {
        return 'date-string'
    }

    if (Array.isArray(value)) {
        return 'array'
    }

    if (typeof value === 'object' && value !== null) {
        return 'object'
    }

    return 'other'
}
