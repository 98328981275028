import { Dayjs } from 'dayjs'

import { format, getDaysBetween, isWeekend } from '~/utils/extendedDayjs'

// cache is used to avoid recalculating the same dates
// key is a string in the format of 'year-month'
// value is an array of dates
const cache: Map<string, Dayjs[]> = new Map()

export function getCalendarDates(day: Dayjs) {
    const date = format(day, 'YYYY-MM')
    const cachedYearMonth = cache.get(date)

    if (cachedYearMonth) {
        return cachedYearMonth
    }

    const firstDay = day.startOf('month')
    const lastDay = day.endOf('month')

    const daysBetween = getDaysBetween(firstDay, lastDay).filter(date => !isWeekend(date))

    cache.set(date, daysBetween)

    return daysBetween
}
