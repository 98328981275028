import { SurgeryTypeGroupOut } from '../diApi'

export function isSpecialitySurgeryTypeGroup(surgeryTypeGroup: SurgeryTypeGroupOut): boolean {
    return surgeryTypeGroup.group_type === 'speciality'
}

export function isCompositeSurgeryTypeGroup(surgeryTypeGroup: SurgeryTypeGroupOut): boolean {
    return surgeryTypeGroup.group_type === 'composite'
}

export function isCategorySurgeryTypeGroup(surgeryTypeGroup: SurgeryTypeGroupOut): boolean {
    return surgeryTypeGroup.group_type === 'category'
}
