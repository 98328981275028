import { flip, offset, shift } from '@floating-ui/react'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import capitalize from 'lodash/capitalize'
import { useEffect, useState } from 'react'

import { add, format, getToday, isWeekend } from '~/utils/extendedDayjs'

import { Popover } from '../../Popover'
import { DatePickerBottom } from './helpers/DatePickerBottom'
import { DatePickerInput } from './helpers/DatePickerInput'
import { YearSwitch } from './helpers/DateSwitches'
import { DefaultDatePickerProps } from './helpers/DefaultDatePickerProps'

const months = dayjs.monthsShort()

export const MonthPicker = ({ value, onChange, size, placement, allowClear = false }: DefaultDatePickerProps) => {
    const [open, setOpen] = useState(false)
    const [year, setYear] = useState(getToday().year())

    useEffect(() => {
        if (value) setYear(value.year())
    }, [value])

    const formattedDate = value ? `${capitalize(format(value, 'MMM'))} ${value.year()}` : undefined

    function closeModal() {
        setOpen(false)
        setYear(value?.year() ?? getToday().year())
    }

    function handleClickMonth(month: string) {
        const date = dayjs({ year, month: months.indexOf(month) })
        const firstWeekdayOfMonth = isWeekend(date.startOf('month')) ? add(date, 1, 'week').startOf('isoWeek') : date.startOf('month')

        onChange(date.set('date', firstWeekdayOfMonth.date()))
        closeModal()
    }

    function setToToday(today: Dayjs) {
        onChange(today)
        setYear(today.year())
        closeModal()
    }

    function clearDate() {
        onChange(undefined)
        closeModal()
    }

    return (
        <Popover
            open={open}
            onOpenChange={setOpen}
            placement={placement}
            middleware={[offset(5), shift(), flip()]}
            trigger={<DatePickerInput size={size} formattedDate={formattedDate} onClick={() => setOpen(true)} />}
        >
            <YearSwitch yearBack={setYear} year={year} yearForward={setYear} />
            <hr className="my-1 h-px bg-gray-200" />
            <div className="p-1">
                <div className="grid grid-cols-3 grid-rows-4 gap-y-2">
                    {months.map(month => {
                        const isCurrentMonth = value?.month() === months.indexOf(month) && value.year() === year

                        return (
                            <div
                                key={month}
                                onClick={() => handleClickMonth(month)}
                                className={clsx('mx-2 cursor-pointer rounded p-2 text-center', {
                                    'pointer-events-none bg-diBlue-500 text-white': isCurrentMonth,
                                    'hover:bg-gray-100': !isCurrentMonth,
                                })}
                            >
                                {month}
                            </div>
                        )
                    })}
                </div>
            </div>
            <hr className="my-1 h-px bg-gray-200" />
            <DatePickerBottom allowClear={allowClear} clearDate={clearDate} setDateToToday={setToToday} />
        </Popover>
    )
}
