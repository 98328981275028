import { SurgeryMetadatumOut } from '~/store/diApi'
import { createSurgeryMetadata, updateSurgeryMetadata } from '~/store/entity.api'

let timerId: NodeJS.Timeout | undefined = undefined

type Payload = { booking_id: string; comment: string }

export function useSaveComment(existingItem: SurgeryMetadatumOut | undefined): (payload: Payload) => void {
    function saveComment(payload: Payload) {
        payload.comment = payload.comment.trim()

        if (!existingItem) {
            void createSurgeryMetadata(payload)
        } else {
            void updateSurgeryMetadata(existingItem.id, payload)
        }
    }

    return payload => {
        clearTimeout(timerId)

        timerId = setTimeout(() => saveComment(payload), 200)
    }
}
