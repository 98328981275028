import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

import { StoreState } from '~/store/store'
import { groupByMany } from '~/utils/array'

import { DepartmentKey, selectLocationValuesAsNumbers } from '../slices/filterSlice'
import { Location, selectEntities } from './entities'

export type LocationId = Location['id']

export const selectGetLocations = createSelector(selectEntities, ({ locations }) => {
    const byDepartmentKey = groupByMany(locations, location => location.departments.map(department => String(department.id)))
    const byDepartmentKeySorted = mapValues(byDepartmentKey, locations => sortBy(locations, location => location.room_code))
    const byId = keyBy(locations, 'id')

    const sortedAllLocations = sortBy(locations, location => location.room_code)

    return {
        byDepartment: (departmentKey: DepartmentKey): Location[] => {
            const result = departmentKey === 'all' ? sortedAllLocations : byDepartmentKeySorted[departmentKey]
            return result ?? []
        },
        byId: (id: number): Location | null => byId[id] ?? null,
    }
})

export const selectActiveFilteredLocations = createSelector(
    selectGetLocations,
    selectLocationValuesAsNumbers,
    (state: StoreState) => state.appFilters.departmentKey,
    (getLocations, locationIds, departmentKey) => {
        const activeLocations = getLocations.byDepartment(departmentKey)
        return activeLocations.filter(location => locationIds.length === 0 || locationIds.includes(location.id))
    }
)

export const selectLocationOptions = createSelector(selectActiveFilteredLocations, locations =>
    locations.map(location => ({
        label: location.room_code,
        value: location.id,
    }))
)
