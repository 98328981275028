import FooterLogo from '/footer.svg'
import NotFoundLogo from '/not-found.svg'

const NotFoundPage = () => (
    <div className="flex h-screen flex-col bg-slate-50" data-test="not-found-page">
        <div className="flex flex-grow flex-col items-center justify-center gap-12">
            <img src={NotFoundLogo} alt="Not found" />
            <p className="max-w-[840px] text-center text-3xl font-bold leading-[58px]">
                Det ser ut til at denne siden har tatt en uventet sykemelding. I mellomtiden kan du prøve en ny URL eller ta en liten kaffepause!
            </p>
        </div>

        <footer>
            <img className="w-full" src={FooterLogo} alt="Footer" />
        </footer>
    </div>
)

export default NotFoundPage
