import { Link } from 'react-router-dom'

import { pages } from '~/app/routes'
import { Button } from '~/components'
import { OpenInNewOutlined } from '~/icons'
import { SurgeryForPresentation } from '~/utils/dips'

import { BookingInfo } from './BookingInfo'

interface Props extends SurgeryForPresentation {
    onCloseDialog: () => void
    checkInTime: string
}

export const Confirmation = ({ bookingId, operation, patient, practitioners, location, displayDate, onCloseDialog, checkInTime }: Props) => (
    <>
        <dl className="grid w-[585px] justify-between gap-6 bg-diSuccess p-6">
            <BookingInfo label="Operasjon">{operation}</BookingInfo>

            <BookingInfo label="Pasient">{patient}</BookingInfo>
            <BookingInfo label="Kirurg">
                {practitioners
                    .filter(prac => prac.name !== undefined)
                    .map((prac, i) => (
                        <dd key={prac.name}>{`${i > 0 ? ',' : ''}${prac.short_name}`}</dd>
                    ))}
            </BookingInfo>
            <BookingInfo label="Stue">{location}</BookingInfo>
            <BookingInfo label="Dato">
                <dd className="capitalize">{displayDate}</dd>
            </BookingInfo>
            <BookingInfo label="Oppmøtetid">kl. {checkInTime}</BookingInfo>
            <Link className="text-diBlue-500 underline" to={`${pages.WAITING_LIST.views.scheduled.path}${bookingId ? `?bookingId=${bookingId}` : ''}`}>
                Se pasienten i Planlagt-oversikten
            </Link>
        </dl>
        <div className="flex gap-2">
            <p className="text-base font-semibold">Pasienten har fått tid til operasjon i Hero. </p>
            <Link to="#" onClick={() => alert('LINK TO BE DETERMINED')} className="flex items-center gap-1 text-diBlue-500 underline">
                Se pasienten i DIPS <OpenInNewOutlined height={16} width={16} />
            </Link>
        </div>
        <div className="grid gap-2">
            <p className="text-base">Dette må du gjøre i DIPS Arena</p>
            <p>&#x2022; Send brev til pasienten</p>
        </div>
        <div className="mt-2 flex justify-end gap-2">
            <Button color="delete" onClick={onCloseDialog} data-test="done">
                Lukk
            </Button>
        </div>
    </>
)
