import { Dayjs } from 'dayjs'
import moize from 'moize'

import { Id, TableCell } from '~/components'
import { Practitioner } from '~/store/selectors'
import { DepartmentKey } from '~/store/slices/filterSlice'
import { dayOfWeek } from '~/utils/extendedDayjs'

export function keyFromTableCells(tableCells: TableCell<{ id: Id }, { id: Id }>[]) {
    return tableCells.map(cell => `${cell.row},${cell.column}`).join(';')
}

export function getFridayStyles(date: Dayjs) {
    return dayOfWeek(date) === 5 ? 'border-r-[#8391c3]' : ''
}

export const comparePractitionersOrder = moize(
    (departmentKey: DepartmentKey, a?: Practitioner, b?: Practitioner) => {
        // We allow here for undefined because the data might still be loading
        if (!a || !b) return 0

        if (departmentKey === 'all') {
            return a.short_name.localeCompare(b.short_name)
        } else {
            const orderA = a.departmentAssignments.find(assignment => assignment.department?.id === departmentKey)?.order ?? 0
            const orderB = b.departmentAssignments.find(assignment => assignment.department?.id === departmentKey)?.order ?? 0
            return orderA - orderB
        }
    },
    { maxSize: 1000 }
)
