import { Show } from '~/components'
import { useStore } from '~/store/store'

import { AvailableTime } from './AvailableTime/AvailableTime'
import { BookingDrawer } from './BookingDrawer/BookingDrawer'
import { Calendar } from './Calendar/Calendar'
import { Header } from './Header/Header'

const Content = () => {
    const isCalendarView = useStore(state => state.app.activeViews.OPERATIONAL_PLANNER) === '/operational-planner/calendar'

    return (
        <div className="overflow-hidden">
            <Show condition={isCalendarView} fallback={<AvailableTime />}>
                {<Calendar />}
            </Show>
        </div>
    )
}

const OperationalPlannerPage = () => (
    <div className="flex h-full flex-col gap-2 p-2">
        <Header />
        <Content />
        <BookingDrawer />
    </div>
)

export default OperationalPlannerPage
