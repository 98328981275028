import { Dayjs } from 'dayjs'

import { getOnlyDate } from './getOnlyDate'

type AnyDate = Dayjs | string

/**
 * Determines whether two date values represent the same calendar date.
 *
 * @param {AnyDate} a - The first date value.
 * @param {AnyDate} b - The second date value.
 * @return {boolean} `true` if both dates represent the same calendar date, `false` otherwise.
 */
export function isSameDate(a?: AnyDate, b?: AnyDate): boolean {
    if (a === undefined || b === undefined) {
        return false
    }

    return getOnlyDate(a) === getOnlyDate(b)
}
