import { Dayjs } from 'dayjs'

/**
 * Determines if the given day is a weekend.
 *
 * @param {Dayjs} day - The day to check.
 * @return {boolean} `true` if the day is a weekend, `false` otherwise.
 */
export function isWeekend(day: Dayjs): boolean {
    const isoWeekday = day.isoWeekday()
    return isoWeekday === 6 || isoWeekday === 7
}
