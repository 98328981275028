import { Dayjs } from 'dayjs'

import { Tag } from '~/components'
import { LocationOut } from '~/store/diApi'
import { OccupancyData } from '~/store/selectors'
import { useStore } from '~/store/store'
import { hasAvailability, isCountBasedRuleEvaluation, isDurationRuleEvaluation, RuleEvaluation } from '~/store/utils/blockEvaluation'
import { trackEvent } from '~/utils/analytics'
import { MinimalPractitioner } from '~/utils/dips'
import { format } from '~/utils/extendedDayjs'

import { AvailabilityByPatientGroup } from '../shared/AvailabilityByPatientGroup'
import { AvailabilityByTime } from '../shared/AvailabilityByTime'
import { BlockIcons } from '../shared/BlockIcons'
import { PractitionerTags } from '../shared/PractitionerTags'

type Props = {
    date: Dayjs
    location: LocationOut
    scheduledPractitioners?: MinimalPractitioner[]
    evaluations: RuleEvaluation[]
    occupancyData: OccupancyData
}

export const AvailableTimeCard = ({ date, location, scheduledPractitioners, evaluations, occupancyData }: Props) => {
    const { openDrawer } = useStore(state => state.operationalPlanner.actions)

    return (
        <button
            onClick={() => {
                openDrawer({ date, location, occupancyData, hasAvailability: true })
                void trackEvent('OP_CARD_WAITINGLIST_AVAILABILITY__CLICK')
            }}
            aria-label="ledig tid kort"
        >
            <div className="flex flex-col gap-y-4">
                <div className="grid grid-cols-[repeat(24,_minmax(0,_1fr))] gap-x-2 gap-y-2 overflow-hidden rounded border border-diBlue-500 px-4 py-2">
                    <div className="col-span-2 flex flex-wrap">
                        <div className="mr-2 font-bold capitalize">{format(date, 'dddd')}</div>
                        <div>{format(date, 'DD.MM.YYYY')}</div>
                    </div>
                    <div className="flex items-center justify-center">
                        <Tag color="location">{location?.room_code}</Tag>
                    </div>
                    <div className="col-span-3 flex flex-wrap items-center gap-2">
                        {scheduledPractitioners && <PractitionerTags practitioners={scheduledPractitioners} />}
                    </div>
                    <div className="col-span-3 flex items-center">
                        {evaluations
                            .filter(isDurationRuleEvaluation)
                            .filter(hasAvailability)
                            .map((evaluation, idx) => (
                                <AvailabilityByTime key={idx} evaluation={evaluation} as_tag={false} />
                            ))}
                    </div>
                    <div className="col-span-8 flex items-center justify-between">
                        <div className="flex flex-wrap gap-2">
                            {evaluations
                                .filter(isCountBasedRuleEvaluation)
                                .filter(hasAvailability)
                                .map((evaluation, idx) => (
                                    <AvailabilityByPatientGroup key={idx} evaluation={evaluation} />
                                ))}
                        </div>
                    </div>

                    <div className="col-span-7 flex items-center justify-end">
                        <BlockIcons date={date} location={location} />
                    </div>
                </div>
            </div>
        </button>
    )
}
