import { produce } from 'immer'
import { create, StateCreator } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { getPageKeyByPath } from '~/app/routes'
import { createUserSlice, UserSlice } from '~/store/slices/userSlice'
import { day, getOnlyDate, getToday } from '~/utils/extendedDayjs'

import { AppSlice, createAppSlice } from './slices/appSlice'
import { createDipsSlice, DipsSlice } from './slices/dipsSlice'
import { createDiSlice, DiSlice } from './slices/diSlice'
import { createFeatureFlagSlice, FeatureFlagSlice } from './slices/featureFlagSlice'
import { createFilterSlice, FilterSlice } from './slices/filterSlice'
import { createOperationalPlannerSlice, OperationalPlannerSlice } from './slices/operationalPlannerSlice'
import { createToasterSlice, ToasterSlice } from './slices/toasterSlice'
import { createWaitingListSlice, WaitingListSlice } from './slices/waitingListSlice'

export type AllSlices = DiSlice & AppSlice & FilterSlice & DipsSlice & FeatureFlagSlice & UserSlice & OperationalPlannerSlice & WaitingListSlice & ToasterSlice

export type Slice<T> = StateCreator<AllSlices, [], [['zustand/immer', never]], T>

type PersistedState = {
    departmentKey: FilterSlice['appFilters']['departmentKey']
    departmentDipsId: FilterSlice['appFilters']['departmentDipsId']
    filters: FilterSlice['appFilters']['filters']
    selectedDate: string | null
    activeViews: AppSlice['app']['activeViews'] | Record<string, unknown>
    settings?: UserSlice['user']['settings']
}

const PERSISTED_STORE_NAME = 'store'

function isPartializeStore(obj: unknown): obj is PersistedState {
    if (typeof obj !== 'object' || obj === null) return false

    const persistedKeys: (keyof PersistedState)[] = ['departmentKey', 'departmentDipsId', 'selectedDate', 'activeViews']

    return persistedKeys.every(key => key in obj)
}

function restoreDate(date: string | null) {
    if (date === null) return getToday()

    const parsedDate = day(date)

    if (parsedDate.isValid()) return parsedDate

    return getToday()
}

export function clearPartialStorage() {
    const currentStorageState = JSON.parse(localStorage.getItem(PERSISTED_STORE_NAME) || '{}')
    const isPersistedState = typeof currentStorageState === 'object' && currentStorageState !== null && 'state' in currentStorageState

    if (isPersistedState && isPartializeStore(currentStorageState.state)) {
        localStorage.setItem(
            PERSISTED_STORE_NAME,
            JSON.stringify({
                ...currentStorageState,
                state: {
                    ...currentStorageState.state,
                    filters: { all: [] },
                    selectedDate: null,
                    activeViews: {},
                },
            })
        )
    }
}

export const useStore = create<AllSlices>()(
    devtools(
        persist(
            (...args) => ({
                ...createAppSlice(...args),
                ...createDiSlice(...args),
                ...createFilterSlice(...args),
                ...createDipsSlice(...args),
                ...createFeatureFlagSlice(...args),
                ...createUserSlice(...args),
                ...createOperationalPlannerSlice(...args),
                ...createWaitingListSlice(...args),
                ...createToasterSlice(...args),
            }),
            {
                name: PERSISTED_STORE_NAME,
                partialize: (state): PersistedState => ({
                    departmentKey: state.appFilters.departmentKey,
                    departmentDipsId: state.appFilters.departmentDipsId,
                    filters: state.appFilters.filters,
                    selectedDate: getOnlyDate(state.appFilters.selectedDate),
                    activeViews: state.app.activeViews,
                    settings: state.user.settings,
                }),
                merge: (persistedState, initialState) => {
                    return produce(initialState, draft => {
                        if (isPartializeStore(persistedState)) {
                            draft.appFilters.departmentKey = persistedState.departmentKey ?? initialState.appFilters.departmentKey
                            draft.appFilters.departmentDipsId = persistedState.departmentDipsId ?? initialState.appFilters.departmentDipsId
                            draft.appFilters.filters = persistedState.filters ?? initialState.appFilters.filters
                            draft.appFilters.selectedDate = restoreDate(persistedState.selectedDate) ?? initialState.appFilters.selectedDate
                            draft.user.settings = persistedState.settings ?? initialState.user.settings
                            const pathname = window.location.pathname
                            const pageKey = getPageKeyByPath(pathname)

                            if (!pageKey) {
                                draft.app.activeViews = { ...initialState.app.activeViews, ...persistedState.activeViews }
                            } else {
                                draft.app.activeViews = {
                                    ...initialState.app.activeViews,
                                    ...persistedState.activeViews,
                                    [pageKey]: pathname,
                                }
                            }
                        }
                    })
                },
            }
        )
    )
)

export type StoreState = ReturnType<typeof useStore.getState>

// Exporting these for backwards compatibility for now
export * from './slices/diSlice'
