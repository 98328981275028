import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { includes } from '~/utils/array'

type ElementWithDataset = HTMLElement | SVGElement

let timeoutId: NodeJS.Timeout | null = null
const placements = ['top', 'left', 'topright'] as const

function findFirstParentWithTooltip(target: ElementWithDataset | null, maxDepth = 10, depth = 0): ElementWithDataset | null {
    if (!target || depth >= maxDepth) {
        return null
    }

    if (target.dataset.tooltip) {
        if (target.dataset.tooltip === 'self') return null
        return target
    }

    return findFirstParentWithTooltip(target.parentElement, maxDepth, depth + 1)
}

export const Tooltip = () => {
    const ref = useRef<HTMLDivElement | null>(null)

    const [text, setText] = useState<string | null>()
    const [position, setPosition] = useState<DOMRect | null>()
    const [placement, setPlacement] = useState<(typeof placements)[number]>('top')

    function onMouseOver(e: MouseEvent) {
        // Check if the mouse entered an element with the tooltip attribute
        const target = e.target
        const isHTMLElement = target instanceof HTMLElement
        const isSVGElement = target instanceof SVGElement

        if (!isHTMLElement && !isSVGElement) {
            return
        }

        const element = findFirstParentWithTooltip(target)

        if (!element) {
            return
        }

        const box = element.getBoundingClientRect()
        const placement = element.dataset.tooltipPlacement

        if (timeoutId) {
            clearTimeout(timeoutId)
        }

        timeoutId = setTimeout(() => {
            setText(element.dataset.tooltip ?? null)
            setPlacement(includes(placements, placement) ? placement : 'top')
            setTimeout(() => setPosition(box), 1)
        }, 250)

        function onMouseOut(e: Event) {
            const target = e.target
            if (!(target instanceof Element)) {
                return
            }

            target.removeEventListener('mouseout', onMouseOut)

            if (timeoutId) {
                clearTimeout(timeoutId)
            }

            setText(null)
            setPosition(null)
        }

        target.addEventListener('mouseout', onMouseOut)
    }

    function closeOnScroll() {
        setText(null)
        setPosition(null)
    }

    useEffect(() => {
        document.addEventListener('mouseover', onMouseOver)
        return () => {
            document.removeEventListener('mouseover', onMouseOver)
        }
    }, [])

    useEffect(() => {
        document.addEventListener('scroll', closeOnScroll, true)
        return () => {
            document.removeEventListener('scroll', closeOnScroll, true)
        }
    }, [])

    const tooltipHeight = ref.current?.clientHeight ?? 0
    const tooltipWidth = ref.current?.clientWidth ?? 0
    const targetTop = position?.top ?? 0
    const targetLeft = position?.left ?? 0
    const targetWidth = position?.width ?? 0
    const targetHeight = position?.height ?? 0

    let tooltipTopPosition, tooltipLeftPosition

    if (placement === 'left') {
        tooltipTopPosition = targetTop + targetHeight / 2 - tooltipHeight / 2
        tooltipLeftPosition = targetLeft - tooltipWidth - 10
    } else if (placement === 'topright') {
        tooltipTopPosition = targetTop - tooltipHeight - 20
        tooltipLeftPosition = targetLeft + targetWidth - tooltipWidth / 2
    } else {
        // defaults to `top`
        tooltipTopPosition = targetTop - tooltipHeight - 10
        tooltipLeftPosition = targetLeft + targetWidth / 2 - tooltipWidth / 2
    }

    const idealTooltipLeftPosition = tooltipLeftPosition
    tooltipLeftPosition = Math.min(tooltipLeftPosition, window.innerWidth - tooltipWidth)
    tooltipLeftPosition = Math.max(0, tooltipLeftPosition)
    tooltipTopPosition = Math.max(0, tooltipTopPosition)

    const arrowPositionOverride: React.CSSProperties =
        tooltipLeftPosition !== idealTooltipLeftPosition && placement !== 'left'
            ? { left: tooltipWidth / 2 + idealTooltipLeftPosition - tooltipLeftPosition }
            : {}

    return createPortal(
        <div
            ref={ref}
            data-tooltip="self"
            className={clsx({
                hidden: !text,
                'opacity-0': !position?.top,
                'absolute whitespace-pre-wrap rounded bg-black px-3 py-2 text-center text-xs text-white shadow': true,
            })}
            style={{
                zIndex: 9999,
                top: tooltipTopPosition,
                left: tooltipLeftPosition,
                maxWidth: '20%',
            }}
        >
            <div>{text}</div>

            {/* Arrow */}
            <div
                className={clsx('absolute h-3 w-3 rotate-45 transform bg-black', {
                    '-bottom-1.5 left-1/2 -translate-x-1/2': placement === 'top' || placement === 'topright',
                    '-right-1.5 top-1/2 -translate-y-1/2': placement === 'left',
                })}
                style={arrowPositionOverride}
            />
        </div>,
        document.body
    )
}
