import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { createBlockLock, deleteBlockLock } from '~/store/entity.api'
import { Location, selectGetOccupancies } from '~/store/selectors'
import { useStore } from '~/store/store'
import { createFingerprintV1 } from '~/utils/blockLock'
import { format } from '~/utils/extendedDayjs'

type ToggleArgs = {
    isBlockLocked: boolean
    lockId?: number
    date: Dayjs
    location: Location
}

export function useToggleBlockLock() {
    const [isLoading, setIsLoading] = useState(false)
    const getOccupancies = useStore(selectGetOccupancies)

    async function toggleBlockLock({ location, date, lockId, isBlockLocked }: ToggleArgs) {
        setIsLoading(true)

        if (isBlockLocked) {
            if (lockId) {
                await deleteBlockLock(lockId)
            }
        } else {
            const occupanices = getOccupancies.byDateAndLocation(date, location)

            await createBlockLock({
                start_time: format(date),
                location_id: location.id,
                end_time: format(date.endOf('day')),
                fingerprint: createFingerprintV1(occupanices.evaluations),
            })
        }
        setIsLoading(false)
    }

    return { isLoading, toggleBlockLock }
}
