import clsx from 'clsx'
import { Dayjs } from 'dayjs'

import { Spinner } from '~/components'
import { selectEntities, selectGetLocations, selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { trackUserEvent } from '~/utils/analytics'

import { operatingStatuses } from '../utils'
import { useAssignPractitionerToCells, useRemovePractitionerFromCells } from './hooks'

type Props = {
    selectedCells: { locationId: number; date: Dayjs }[]
    selectSearch: string
}

export const PractitionerDropdownContent = ({ selectedCells, selectSearch }: Props) => {
    const { addPractitioner, practitionerIdsBeingAdded } = useAssignPractitionerToCells(selectedCells)
    const { removePractitioner } = useRemovePractitionerFromCells(selectedCells)
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const entities = useStore(selectEntities)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const getLocations = useStore(selectGetLocations)

    const allPractitionerSchedulesByCell = selectedCells.map(cell => getPractitionerSchedules.byDateAndLocationId(cell.date, cell.locationId))
    const selectedSchedules = allPractitionerSchedulesByCell.flat()

    return (
        <>
            {entities.departments.map(department => {
                if (department.id !== departmentKey && departmentKey !== 'all') return null

                return department.practitioners.map(practitioner => {
                    if (!practitioner.short_name.toLowerCase().includes(selectSearch.trim().toLowerCase())) {
                        return null
                    }

                    // The following checks that the practitioner has an operating status in all selected cells and
                    // if not, the practitioner is not rendered in the dropdown
                    const practitionerSchedulesByCell = selectedCells.map(cell => getPractitionerSchedules.byDateAndPractitioner(cell.date, practitioner))
                    const withOperatingStatusByCell = practitionerSchedulesByCell.map(schedules =>
                        schedules.filter(schedule => schedule.statuses.some(status => operatingStatuses.includes(status.status_code)))
                    )
                    const hasOperatingStatusInAllCells = withOperatingStatusByCell.filter(cell => cell.length).length === selectedCells.length

                    if (!hasOperatingStatusInAllCells) {
                        return null
                    }

                    const isInAllCells = allPractitionerSchedulesByCell.every(schedules =>
                        schedules.some(schedule => schedule.practitioner_id === practitioner.id)
                    )
                    const isInSomeCells = !isInAllCells && selectedSchedules.some(schedule => schedule.practitioner_id === practitioner.id)
                    const isBeingAdded = practitionerIdsBeingAdded.includes(practitioner.id)

                    return (
                        <div
                            key={practitioner.id}
                            className="cursor-pointer border-b p-2"
                            onClick={() => {
                                const numberOfEdits = selectedCells.length
                                const rooms = [...new Set(selectedCells.map(cell => getLocations.byId(cell.locationId)?.room_code))].filter(Boolean)
                                if (isInAllCells) {
                                    void trackUserEvent('SS_SURGEON_LOCATION_REMOVED', { numberOfEdits, rooms })
                                    void removePractitioner(practitioner)
                                } else {
                                    void trackUserEvent('SS_SURGEON_LOCATION_ADDED', { numberOfEdits, rooms })
                                    void addPractitioner(practitioner)
                                }
                            }}
                        >
                            <div
                                className={clsx('inline-flex w-full items-center justify-between rounded px-3 py-1 hover:bg-diBlue-100', {
                                    'pointer-events-none': isBeingAdded,
                                })}
                            >
                                <span className={clsx({ 'text-gray-500': isInSomeCells })}>{practitioner.short_name}</span>
                                {isBeingAdded && <Spinner size="sm" />}
                            </div>
                        </div>
                    )
                })
            })}
        </>
    )
}
