import { Dayjs } from 'dayjs'
import { createSelector } from 'reselect'
import { immer } from 'zustand/middleware/immer'

import { DayOutlined, NightOutlined, PersonAlertOutlined } from '~/icons'
import { getToday } from '~/utils/extendedDayjs'
import { isNullish } from '~/utils/guards'

import { DepartmentOut } from '../diApi'
import { Slice } from '../store'

export type DepartmentKey = DepartmentOut['id'] | 'all'

export const statusesMap = {
    SURGERY: { color: 'fuchsia', label: 'O', tooltip: 'Operasjon' },
    DAY_SURGERY: { color: 'emerald', label: 'D', tooltip: 'Dagkirurgi' },
    POLICLINIC: { color: 'orange', label: 'P', tooltip: 'Poliklinikk' },
    EVENING_POLYCLINIC: { color: 'indigo', label: 'KvP', tooltip: 'Kveldspoliklinikk' },
    'ON-CALL': { color: 'yellow', label: 'Ox', tooltip: 'On-call' }, // Håvard: "In Norwegian we just say ‘on-call’"
    VACATION: { color: 'sky', label: 'F', tooltip: 'Fravær' },
    OTHER: { color: 'gray', label: 'A', tooltip: 'Annet' },
} as const
export type Status = keyof typeof statusesMap

export const dayOvernightMap = {
    'Poliklinisk dagbehandling': { label: 'Dag', icon: <DayOutlined className="h-4 w-4" /> },
    Heldøgn: { label: 'Døgn', icon: <NightOutlined className="h-4 w-4" /> },
} as const
type DayOvernight = typeof dayOvernightMap
type DayOvernightKey = keyof DayOvernight
type DayOvernightLabel = DayOvernight[keyof DayOvernight]['label']

export function isDayOvernightKey(key: string | null | undefined): key is DayOvernightKey {
    if (isNullish(key)) return false
    return key in dayOvernightMap
}

export function isDayOvernightLabel(label: string): label is DayOvernightLabel {
    const dayOvernightLabels = Object.values(dayOvernightMap).map(({ label }) => label)
    return dayOvernightLabels.includes(label)
}

export function getDayOvernightTooltipText(name: string) {
    return `Denne pasienten har status “${name}”. Dette bør endres i DIPS slik at videre oppfølging blir korrekt.`
}

export function getDayOvernight(name: string | null | undefined) {
    if (isDayOvernightKey(name)) {
        return dayOvernightMap[name]
    }

    if (!!name) {
        return {
            label: name,
            icon: <PersonAlertOutlined className="h-5 w-5 shrink-0" data-tooltip={getDayOvernightTooltipText(name)} />,
        }
    }

    return null
}

const waitingListFilters = ['asa', 'day-overnight', 'interpreter', 'postponed', 'kort-varsel', 'practitioner', 'operation-type'] as const
const operationalPlannerFilters = ['practitioner', 'weekday', 'location'] as const
const surgeonSchedulerFilters = ['practitioner', 'status', 'location'] as const
const masterSchedulerFilters = ['location'] as const
const allFilters = [...new Set([...waitingListFilters, ...operationalPlannerFilters, ...surgeonSchedulerFilters, ...masterSchedulerFilters])]

type FilterType = (typeof allFilters)[number]

export type Option = {
    label: string
    value: string
}

type Filter = {
    type: FilterType
    value: string
    label: string
    id: string
}

type State = {
    departmentKey: DepartmentKey
    departmentDipsId: number | null
    selectedDate: Dayjs
    filters: {
        [key in DepartmentKey]: Filter[]
    }
}

type Actions = {
    actions: {
        setDepartment: (department: DepartmentKey, dipsId: number | null) => void
        setSelectedDate: (date: Dayjs) => void
        emptyFilters: () => void
        removeSingleFilter: (id: string) => void
        toggleFilter: (args: { value: string; label: string; type: FilterType }) => void
        removeAllFilterByType: (type: FilterType) => void
    }
}

export type FilterSlice = {
    appFilters: State & Actions
}

const initialState: State = {
    filters: { all: [] },
    departmentKey: 'all',
    departmentDipsId: null,
    selectedDate: getToday(),
}

export const createFilterSlice: Slice<FilterSlice> = immer((set, get) => ({
    appFilters: {
        ...initialState,
        actions: {
            setSelectedDate: date =>
                set(state => {
                    state.appFilters.selectedDate = date
                }),
            setDepartment: (department, dipsId) =>
                set(state => {
                    state.appFilters.departmentKey = department
                    state.appFilters.departmentDipsId = dipsId
                    state.appFilters.filters[department] = state.appFilters.filters[department] ?? []
                }),
            emptyFilters: () =>
                set(state => {
                    const departmentKey = get().appFilters.departmentKey
                    state.appFilters.filters[departmentKey] = []
                }),
            removeSingleFilter: id =>
                set(state => {
                    const departmentKey = get().appFilters.departmentKey
                    const departmentKeyFilters = state.appFilters.filters[departmentKey] ?? []

                    state.appFilters.filters[departmentKey] = departmentKeyFilters.filter(filter => filter.id !== id)
                }),
            toggleFilter: ({ value, label, type }) => {
                const departmentKey = get().appFilters.departmentKey
                const filterByDepartmentKey = get().appFilters.filters[departmentKey]

                if (!filterByDepartmentKey) {
                    set(state => {
                        state.appFilters.filters[departmentKey] = []
                    })
                }

                const existingFilter = get().appFilters.filters[departmentKey]?.find(filter => filter.type === type && filter.value === value)

                if (existingFilter) {
                    set(state => {
                        const departmentKeyFilters = state.appFilters.filters[departmentKey] ?? []
                        state.appFilters.filters[departmentKey] = departmentKeyFilters.filter(filter => filter.type !== type || filter.value !== value)
                    })
                } else {
                    set(state => {
                        state.appFilters.filters[departmentKey]?.push({
                            id: `${type}-${value}`,
                            type,
                            value,
                            label,
                        })
                    })
                }
            },
            removeAllFilterByType: type =>
                set(state => {
                    const departmentKey = get().appFilters.departmentKey
                    state.appFilters.filters[departmentKey] = state.appFilters.filters[departmentKey]?.filter(filter => filter.type !== type) ?? []
                }),
        },
    },
}))

const selectDepartmentFilters = createSelector(
    (state: FilterSlice) => state.appFilters.filters,
    (state: FilterSlice) => state.appFilters.departmentKey,
    (filters, departmentKey) => filters?.[departmentKey] ?? []
)

// Page specific selectors
export const selectWaitingListFilters = createSelector(selectDepartmentFilters, filters => filters.filter(filter => waitingListFilters.includes(filter.type)))
export const selectOperationalPlannerFilters = createSelector(selectDepartmentFilters, filters =>
    filters.filter(filter => operationalPlannerFilters.includes(filter.type))
)
export const selectSurgeonSchedulerFilters = createSelector(selectDepartmentFilters, filters =>
    filters.filter(filter => surgeonSchedulerFilters.includes(filter.type))
)
export const selectMasterSchedulerFilters = createSelector(selectDepartmentFilters, filters =>
    filters.filter(filter => masterSchedulerFilters.includes(filter.type))
)

// Filter specific selectors
export const selectShortNoticeValues = createSelector(selectDepartmentFilters, filters =>
    filters.filter(filter => filter.type === 'kort-varsel').map(filter => filter.value)
)
export const selectASAValues = createSelector(selectDepartmentFilters, filters => filters.filter(filter => filter.type === 'asa').map(filter => filter.value))
export const selectDayOvernightValues = createSelector(selectDepartmentFilters, filters =>
    filters.filter(filter => filter.type === 'day-overnight').map(filter => filter.value)
)
export const selectPractitionerValues = createSelector(selectDepartmentFilters, filters =>
    filters.filter(filter => filter.type === 'practitioner').map(filter => filter.value)
)
export const selectOperationTypeValues = createSelector(selectDepartmentFilters, filters =>
    filters.filter(filter => filter.type === 'operation-type').map(filter => filter.value)
)
export const selectWeekdayValues = createSelector(selectDepartmentFilters, filters =>
    filters.filter(filter => filter.type === 'weekday').map(filter => filter.value)
)
export const selectLocationValues = createSelector(selectDepartmentFilters, filters =>
    filters.filter(filter => filter.type === 'location').map(filter => filter.value)
)
export const selectLocationValuesAsNumbers = createSelector(selectDepartmentFilters, filters =>
    filters
        .filter(filter => filter.type === 'location')
        .map(filter => filter.value)
        .map(Number)
)
export const selectStatusValues = createSelector(selectDepartmentFilters, filters =>
    filters.filter(filter => filter.type === 'status').map(filter => filter.value)
)
