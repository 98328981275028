import dayjs, { Dayjs } from 'dayjs'

export const cache = new Map<string, Dayjs>()

export type AcceptedDayInput = string | Date | Dayjs | number | null | undefined

/**
 * Converts the specified date to a Dayjs (not to be confused with convert to a day i.e. stripping the time-part).
 */
export function day(date?: AcceptedDayInput, interpretedTimezone?: string): Dayjs {
    if (date === undefined) {
        return dayjs()
    }

    if (date === null) {
        return dayjs(null)
    }

    if (dayjs.isDayjs(date)) {
        return date
    }

    if (date instanceof Date) {
        return dayjs(date).tz(undefined, true)
    }

    if (interpretedTimezone) {
        return dayjs(date).tz(interpretedTimezone, true)
    }

    const isString = typeof date === 'string'

    if (isString && cache.has(date)) {
        return cache.get(date)!
    }

    if (isString) {
        const hasTzInfo = date.includes('T') && (date.includes('+') || date.includes('Z') || date.split('T')[1]?.includes('-'))

        if (!hasTzInfo) {
            // Caching dates without timezone info is a bit risky, because it
            // depends on the timezone set in .ts.setDefault() which can change
            // However, right now we're very confident that we're not changing the timezone
            // during the app's lifecycle. If we ever do, we need to remove the caching here.
            try {
                const dayjsDate = dayjs(date).tz(undefined, true)
                cache.set(date, dayjsDate)
                return dayjsDate
            } catch (e) {
                if (e instanceof Error) {
                    console.error('Invalid date', date, e.message, e.stack)
                } else {
                    console.error(e)
                }
            }
        } else {
            const dayjsDate = dayjs(date)
            cache.set(date, dayjsDate)
            return dayjsDate
        }
    }

    return dayjs(date)
}
