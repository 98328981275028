import * as RadixDialog from '@radix-ui/react-dialog'
import { motion } from 'framer-motion'
import React from 'react'

import { CloseOutlined } from '~/icons'

type Props = {
    children: React.ReactNode
    isOpen: boolean
    onCloseDialog: () => void
    title: string
}

// Generic dialog component that can be used to display any content in a dialog
export const Dialog = ({ children, isOpen, onCloseDialog, title }: Props) => {
    return (
        <RadixDialog.Root open={isOpen}>
            <RadixDialog.Portal>
                <RadixDialog.Overlay className="fixed inset-0 z-50 bg-black/50" />
                <RadixDialog.Content
                    className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center"
                    onEscapeKeyDown={onCloseDialog}
                    onPointerDownOutside={onCloseDialog}
                >
                    <motion.div
                        transition={{ type: 'linear' }}
                        initial={{ opacity: 0, y: 25 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="relative z-50 rounded bg-white"
                        data-test="dialog"
                    >
                        <div className="flex justify-between border-b px-6 py-4">
                            <p className="text-lg font-semibold">{title}</p>
                            <button onClick={onCloseDialog}>
                                <CloseOutlined />
                            </button>
                        </div>
                        <div className="px-6 pb-8 pt-4">{children}</div>
                    </motion.div>
                </RadixDialog.Content>
            </RadixDialog.Portal>
        </RadixDialog.Root>
    )
}
