/**
 * This file is a generic fetch client that does not know about React or store lifecycles.
 * It should be treated as simply a convencience wrapper around the generated API client.
 */
import createClient from 'openapi-fetch'
import { SimplifyDeep } from 'type-fest'

import env from '~/env'
import { useStore } from '~/store/store'

import { components, paths } from './generated-dips-types'

export function getClient() {
    return createClient<paths>({
        baseUrl: env.VITE_DIPS_BASE_URL,
        headers: {
            'dips-subscription-key': env.VITE_DIPS_SUBSCRIPTION_KEY,
            Authorization: `Bearer ${useStore.getState().user.dipsApiAccessToken}`,
        },
        mode: 'cors',
        credentials: 'include',
    })
}

type RemoveIndex<T> = {
    [K in keyof T as string extends K ? never : number extends K ? never : symbol extends K ? never : K]: T[K]
}

type WithoutIndexSignature<T> = SimplifyDeep<{
    [K in keyof T]: RemoveIndex<T[K]>
}>

// Removing the index signature to make it better work with the denormalizer, which can't handle index signatures yet.
export type DipsSchemas = WithoutIndexSignature<components['schemas']>
