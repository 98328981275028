import { Dayjs } from 'dayjs'
import { useRef, useState } from 'react'

import { Show, TextInput } from '~/components'
import { isHoliday } from '~/utils/extendedDayjs/helpers/isHoliday'

import { LocationComment } from './LocationComment'
import { PractitionerDropdownContent } from './PractitionerDropdownContent'
import { PractitionerTags } from './PractitionerTags'

type Props = {
    selectedCells: { locationId: number; date: Dayjs }[]
}

export const LocationScheduleModal = ({ selectedCells }: Props) => {
    const searchRef = useRef<HTMLInputElement>(null)
    const [selectSearch, setSelectSearch] = useState('')

    const selectedCellsIncludesHolidays = selectedCells.some(({ date }) => isHoliday(date))
    const firstCell = selectedCells.at(0)

    function stopPropagation(e: React.MouseEvent) {
        e.stopPropagation()
    }

    return (
        <div className="flex w-80 flex-col space-y-4" onClick={stopPropagation}>
            <Show
                condition={!selectedCellsIncludesHolidays}
                fallback={<small className="mr-9 text-gray-500">Du kan ikke foreta deg noe på en helligdag. Velg kun ordinære dager.</small>}
            >
                <div className="flex flex-col gap-y-3">
                    <p className="!mb-0 px-3 pt-3 font-medium">Kirurg</p>

                    <div className="px-2.5">
                        <TextInput
                            ref={searchRef}
                            id="search_practitioners"
                            className="w-full"
                            placeholder="Søk for Kirurger"
                            value={selectSearch}
                            onChange={e => setSelectSearch(e.target.value)}
                        />

                        <PractitionerTags selectedCells={selectedCells} />
                    </div>

                    <div className="h-60 overflow-auto border-y">
                        <PractitionerDropdownContent selectedCells={selectedCells} selectSearch={selectSearch} />
                    </div>

                    <div className="px-3 pb-3">
                        <Show
                            condition={selectedCells.length === 1}
                            fallback={<small className="text-gray-500">Du kan ikke skrive kommentar på flere dager samtidig. Velg kun én dag.</small>}
                        >
                            {firstCell && <LocationComment selectedCell={firstCell} />}
                        </Show>
                    </div>
                </div>
            </Show>
        </div>
    )
}
