import clsx from 'clsx'

export const PillsSelect = ($: {
    title: string
    values: string[]
    options: { label: string; value: string }[]
    onChange: (args: { label: string; value: string }) => void
}) => (
    <div key={$.title} className="flex flex-col items-center px-4 pb-4 pt-2">
        <p className="w-full pb-2">{$.title}</p>

        <div className="flex w-full flex-wrap justify-start gap-2">
            {$.options.map(value => (
                <div
                    key={value.value}
                    onClick={() => $.onChange({ label: value.label, value: value.value })}
                    className={clsx(
                        'cursor-pointer rounded-full border px-3 py-0.5',
                        $.values.includes(value.value)
                            ? 'bg-indigo-700 text-white hover:bg-indigo-800'
                            : 'border-indigo-700 text-indigo-700 hover:bg-indigo-700/10'
                    )}
                >
                    <p className="text-sm">{value.label}</p>
                </div>
            ))}
        </div>
    </div>
)
