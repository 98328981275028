import dayjs from 'dayjs'

import { CloseOutlined } from '~/icons'
import { selectGetLocations } from '~/store/selectors'
import { useStore } from '~/store/store'
import { format } from '~/utils/extendedDayjs'

type InfoTextProps = {
    label: string
    value: string
}

const InfoText = ({ label, value }: InfoTextProps) => (
    <div className="pt-2 text-sm">
        {label}: <span className="font-semibold">{value}</span>
    </div>
)

type BookingDrawerHeaderProps = {
    onClose: () => void
}

export const BookingDrawerHeader = ({ onClose }: BookingDrawerHeaderProps) => {
    const { location_id, unixDate, totalKnifeTime, totalRoomDuration, capacity } = useStore(state => state.operationalPlanner.drawerProps) ?? {}

    const getLocations = useStore(selectGetLocations)
    const location = location_id !== undefined ? getLocations.byId(location_id) : null
    const date = format(dayjs.unix(unixDate ?? 0).startOf('day'), 'DD.MM.YYYY')

    return (
        <header className="border-b bg-white">
            <div className={`flex border-b px-6 py-4 ${location ? 'justify-between' : 'justify-end'}`}>
                {location && (
                    <div className="flex items-center gap-1 font-semibold">
                        <span>Stue {location.room_code}</span> <div className="h-4 w-[2px] bg-black" /> <span>{date}</span>
                    </div>
                )}
                <CloseOutlined onClick={onClose} className="cursor-pointer rounded-full hover:bg-blue-900/40" />
            </div>
            <div className="py-4 pl-6">
                {totalKnifeTime && <InfoText label="Total knivtid" value={totalKnifeTime} />}
                {totalRoomDuration && <InfoText label="Total stuetid" value={totalRoomDuration} />}
                {capacity && <InfoText label="Kapasitet" value={capacity} />}
            </div>
        </header>
    )
}
