import clsx from 'clsx'
import { FormEvent, useEffect, useRef, useState } from 'react'

type Props = {
    value?: string
    onInput?: (e: FormEvent<HTMLTextAreaElement>) => void
    disabled?: boolean
}

export const TextArea = ({ value, onInput, disabled }: Props) => {
    const ref = useRef<HTMLTextAreaElement>(null)
    const [hasFocused, setHasFocused] = useState(false) // only focus once
    const [showScroll, setShowScroll] = useState(false)

    function textAreaAdjust(element: HTMLTextAreaElement) {
        const maxHeight = 136 // 6 rows
        element.style.height = '1px'
        element.style.height = Math.min(element.scrollHeight, maxHeight) + 'px'
        setShowScroll(element.scrollHeight > maxHeight)
    }

    // Adjust the height of the textarea to fit the content
    useEffect(() => {
        if (ref.current) {
            textAreaAdjust(ref.current)
        }
    }, [value])

    // Focus on the element when it goes from disabled to enabled
    useEffect(() => {
        if (!disabled && ref.current && !hasFocused) {
            ref.current.focus()
            ref.current.selectionStart = ref.current.textLength
            setHasFocused(true)
        }
    }, [disabled, hasFocused])

    return (
        <textarea
            className={clsx('w-full resize-none rounded-md border border-gray-300 p-2 align-top text-sm', {
                'bg-gray-50': disabled,
                'bg-white': !disabled,
                'overflow-y-hidden': !showScroll,
            })}
            disabled={disabled}
            placeholder="Skriv her"
            value={value}
            onInput={onInput}
            maxLength={4000} // database can hold 4096
            ref={ref}
            data-test={hasFocused ? 'waiting-list-textarea' : ''}
            onChange={e => textAreaAdjust(e.target)}
        />
    )
}
