import clsx from 'clsx'
import { ReactNode, RefObject, useEffect } from 'react'

import { Id } from './utils'

function useStickyFooter<T extends { id: Id }>(tableRef: RefObject<HTMLTableElement>, columns: readonly T[] | T[]) {
    // Calculating the correct bottom sticky position for the footer cells
    // requestAnimationFrame to avoid forced reflow
    useEffect(() => {
        const recalculateStickyFooter = () => {
            const table = tableRef.current
            if (!table) return

            const footerTrs = Array.from(table.querySelectorAll<HTMLTableRowElement>('[data-footer-index]'))
            const footerRowsCount = footerTrs.length

            for (let i = 0; i < footerRowsCount; i++) {
                let cumulativeHeight = -1 // -1 because of PF-1476

                for (let j = footerRowsCount - 1; j >= i; j--) {
                    const tr = footerTrs[j]

                    if (!tr) {
                        continue
                    }

                    const height = tr.offsetHeight

                    for (const td of Array.from(tr.children)) {
                        if (!(td instanceof HTMLTableCellElement)) {
                            continue
                        }

                        td.style.bottom = `${cumulativeHeight}px`
                    }

                    cumulativeHeight += height
                }
            }
        }

        const resizeObserver = new ResizeObserver(recalculateStickyFooter)

        if (tableRef.current) {
            resizeObserver.observe(tableRef.current)
            recalculateStickyFooter() // Initial calculation
        }

        return () => {
            resizeObserver.disconnect()
        }
        // We control what we are subscribing to so adding this rule
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns])
}

export type FooterProps<Column extends { id: Id }> = {
    header: ReactNode
    cellRender: (column: Column) => ReactNode
    footerClassName?: (column: Column) => string
}

type Props<HeaderRow extends { id: Id }> = {
    tableRef: RefObject<HTMLTableElement>
    headerRow: HeaderRow[]
    footers: FooterProps<HeaderRow>[]
}

export function StickyFooter<Column extends { id: Id }>({ headerRow, footers, tableRef }: Props<Column>) {
    useStickyFooter(tableRef, headerRow)

    return (
        <>
            {footers?.map(({ header, cellRender, footerClassName }, footerIndex) => (
                <tr key={footerIndex} data-footer-index={footerIndex}>
                    <td
                        className={clsx('sticky bottom-0 left-0 z-30 whitespace-nowrap border-r border-t border-r-[#8391c3] bg-white p-2', {
                            'border-t-[#8391c3]': footerIndex === 0,
                        })}
                    >
                        {header}
                    </td>

                    {headerRow.map(cell => (
                        <td
                            key={cell.id}
                            className={clsx(footerClassName?.(cell), 'sticky bottom-0 z-20 border-r border-t bg-white p-2 last:border-r-0', {
                                'border-t-[#8391c3]': footerIndex === 0,
                            })}
                        >
                            {cellRender(cell)}
                        </td>
                    ))}
                </tr>
            ))}
        </>
    )
}
