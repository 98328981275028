import { motion } from 'framer-motion'
import React from 'react'

import { ExclamationCircleOutlined } from '~/icons'

type Props = { label: string; error?: string; value?: string; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; id: string }
const inlineErrorStyles = 'text-red-500 text-xs flex gap-0.5 absolute bottom-0 right-0'

const inputErrorStyles = 'border-red-500'

const labelInputStyles = 'grid max-w-[332px] grid-cols-[1fr_132px] items-center justify-between text-gray-500 gap-4'

const inputStyles = 'rounded-lg border p-1 font-normal outline-none'

export const BookingFormInput = ({ label, error, value, onChange, id }: Props) => (
    <div className="relative h-14">
        <label className={labelInputStyles}>
            {label}
            <input
                type="time"
                id={id}
                name={id}
                data-test={id}
                className={`${inputStyles} ${error ? inputErrorStyles : ''}`}
                value={value}
                onChange={onChange}
            />
        </label>
        {error && (
            <motion.p initial={{ opacity: 0, y: -16 }} animate={{ opacity: 1, y: 0 }} transition={{ type: 'linear' }} className={inlineErrorStyles}>
                <ExclamationCircleOutlined height={20} />
                {error}
            </motion.p>
        )}
    </div>
)
