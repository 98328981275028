import clsx from 'clsx'

import { Table } from '~/components'
import { selectActiveFilteredLocations, selectWeekIdDates } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getToday, isSameDate } from '~/utils/extendedDayjs'
import { getHolidayStyles } from '~/utils/utils'

import { Cell } from './Cell'
import { DayColumn } from './DayColumn/DayColumn'

export const WeeklyTable = ({ isLoading }: { isLoading: boolean }) => {
    const weekIdDates = useStore(selectWeekIdDates)
    const activeFilteredLocations = useStore(selectActiveFilteredLocations)

    return (
        <Table
            isLoading={isLoading}
            data-test={isLoading ? 'MSS-weekly-table-loading' : 'MSS-weekly-table-loaded'}
            header={{
                label: 'Stuer',
                row: weekIdDates,
                rowClassName: ({ date }) =>
                    clsx('w-[calc((100vw-116px)/5)]', getHolidayStyles(date), {
                        'calendar-today': isSameDate(date, getToday()),
                    }),
                rowRender: ({ date }) => <DayColumn date={date} />,
            }}
            body={{
                rows: activeFilteredLocations,
                rowRender: ({ room_code }) => room_code,
                cellClassName: (_, { id: date }) => clsx(getHolidayStyles(date), '!p-0'),
                cellRender: (location, { date }) => <Cell date={date} location={location} />,
            }}
        />
    )
}
