import { useEffect } from 'react'

import { SearchText } from '~/components'
import { useSessionLikeSearchParam } from '~/hooks/useSessionLikeSearchParam'
import { useStore } from '~/store/store'

let timer: NodeJS.Timeout | undefined = undefined

export const BarSearch = () => {
    const [rawNeedle, setNeedleInUrl] = useSessionLikeSearchParam('needle', value => value ?? '')
    const { setSelectedPage, setPageRawNeedle } = useStore(state => state.waitingList.actions)

    useEffect(() => {
        if (timer !== undefined) clearTimeout(timer)
        timer = setTimeout(() => setPageRawNeedle(rawNeedle), 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawNeedle])

    function setNeedle(text: string) {
        setSelectedPage(1)
        setNeedleInUrl(text)
    }

    // WTF is a needle?
    return <SearchText rawNeedle={rawNeedle} setNeedle={setNeedle} />
}
