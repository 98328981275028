import isString from 'lodash/isString'

import { Show, Tag, TagColor } from '~/components'

type Props = {
    tags: string | string[]
    tagColor?: TagColor
    fallback?: string
}

export const WaitingListTags = ({ tags, tagColor = 'waiting-list', fallback = '' }: Props) => {
    const nonEmptyTags = (isString(tags) ? [tags] : tags).filter(tag => tag !== '')

    return (
        <Show condition={nonEmptyTags.length !== 0} fallback={fallback}>
            <div className="flex flex-row gap-2">
                {nonEmptyTags.map((tag, index) => (
                    <Tag color={tagColor} textSize="sm" key={`tag-${index}`}>
                        {tag}
                    </Tag>
                ))}
            </div>
        </Show>
    )
}
