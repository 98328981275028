import { SingleSelect } from '~/components'
import { RuleDefinitionId } from '~/store/utils/ruleInstance'

type Props = {
    locationId: number | null
    setLocationId: (id: number | null) => void
    locationOptions: { label: string; value: number }[]

    ruleId: RuleDefinitionId
    onSchemaChange: (id: string | null) => void
    ruleDefinitionOptions: { label: string; value: string }[]
}

export const Header = ({ locationId, setLocationId, locationOptions, ruleId, onSchemaChange, ruleDefinitionOptions }: Props) => {
    return (
        <div className="grid grid-cols-9">
            <div className="col-span-2 w-full">
                <SingleSelect size="sm" options={locationOptions} value={locationId} onChange={setLocationId} />
            </div>

            <div className="col-span-7 pl-2.5">
                <SingleSelect size="sm" options={ruleDefinitionOptions} value={ruleId} onChange={onSchemaChange} />
            </div>
        </div>
    )
}
