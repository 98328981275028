export type Id = string | number

export type TableCell<Row extends { id: Id }, Column extends { id: Id }> = {
    row: Row['id']
    column: Column['id']
}

export function toCellId<Row extends { id: Id }, Column extends { id: Id }>(rowId: Row['id'], columnId: Column['id']): string {
    return `${columnId}_${rowId}`
}

export function fromCellId(cellId: string): TableCell<{ id: Id }, { id: Id }> {
    const [row, column] = cellId.split('_')

    if (row === undefined || column === undefined) {
        throw new Error(`Invalid cell id: ${cellId}`)
    }

    return { row, column }
}
