import { createSelector } from 'reselect'

import { selectEntities, SurgeryTypeGroup } from './entities'
import { HospitalSurgeryTypeId } from './hospitalSurgeryTypes'
import { resolveSurgeryTypeGroups } from './internal/internalSurgeryTypeGroups'
import { SurgeryTypeMetadata } from './surgeryTypes'

export type SurgeryTypeGroupId = SurgeryTypeGroup['id']

export type SurgeryTypeGroupCode = SurgeryTypeGroup['code']

export type ResolvedSurgeryTypeGroupMetadata = {
    resolvedSurgeryTypes: Record<HospitalSurgeryTypeId, SurgeryTypeMetadata>
    sortedUniqueSurgeryWeights: number[]
}

export type ResolvedSurgeryTypeGroup = SurgeryTypeGroup &
    ResolvedSurgeryTypeGroupMetadata & {
        resolvedChildren: ResolvedSurgeryTypeGroup[]
    }

export function getSurgeryTypeGroupOutParentChildKey(parentId: SurgeryTypeGroupId, childId: SurgeryTypeGroupId): string {
    return `${parentId}::${childId}`
}

export const selectSurgeryTypeGroup = createSelector(selectEntities, ({ surgeryTypeGroups, surgeryTypeGroupHierarchies }) => {
    const { resolvedSurgeryTypeGroupsById, resolvedSurgeryTypeGroupsByCode } = resolveSurgeryTypeGroups(surgeryTypeGroupHierarchies, surgeryTypeGroups)

    return {
        byId: (id?: SurgeryTypeGroupId): ResolvedSurgeryTypeGroup | null => {
            return id ? (resolvedSurgeryTypeGroupsById[id] ?? null) : null
        },
        byCode: (code?: SurgeryTypeGroupCode): ResolvedSurgeryTypeGroup | null => {
            const id = code ? (resolvedSurgeryTypeGroupsByCode[code] ?? null) : null
            return id ? (resolvedSurgeryTypeGroupsById[id] ?? null) : null
        },
    }
})

export type GetResolvedSurgeryTypeGroup = ReturnType<typeof selectSurgeryTypeGroup>
