import { Dayjs } from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

const cache: Map<string, string> = new Map()

export function format(date: Dayjs | Duration, template?: string): string {
    const key = String(date.valueOf()) + '__' + (template ?? '')

    if (cache.has(key)) {
        return cache.get(key)!
    }

    const formatted = date.format(template)
    cache.set(key, formatted)

    return formatted
}
