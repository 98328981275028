import { Dayjs } from 'dayjs'

import { Id, TableCell } from '~/components/Table'
import { day } from '~/utils/extendedDayjs'

export const operatingStatuses = ['SURGERY', 'DAY_SURGERY', 'ON-CALL']
export const operatingStatusesForLocationAssignment = ['SURGERY', 'DAY_SURGERY']

export type DateLocationCell = { date: Dayjs; locationId: number }

export function selectedCellsToLocationDateCells(selectedCells: TableCell<{ id: Id }, { id: Id }>[]): DateLocationCell[] {
    return selectedCells.map(cell => ({
        locationId: Number(cell.row),
        date: day(cell.column),
    }))
}
