import { UnscheduledSurgery } from '~/store/dipsApi'
import { useStore } from '~/store/store'

import { SurgeryCard } from '../../OperationalPlanner/BookingDrawer/SurgeryCard'

export const BookingContent = () => {
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const unscheduled = useStore(state => state.dips.entities.unScheduledSurgeries)

    if (drawerProps === null) return <div />

    const { item } = drawerProps

    return (
        <div data-test="waiting-list-booking-content">
            <p className="pb-4 font-bold">Til Planlegging</p>
            <SurgeryCard surgery={{ type: 'unscheduled', value: unscheduled.byId[item.BookingId] as UnscheduledSurgery }} />
        </div>
    )
}
