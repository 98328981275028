import { useEffect } from 'react'

export function useHandleEscKey(handleEscape: () => void) {
    useEffect(() => {
        function handleEscKey(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                event.preventDefault()
                event.stopPropagation()

                handleEscape()
            }
        }

        window.addEventListener('keydown', handleEscKey)
        return () => window.removeEventListener('keydown', handleEscKey)
    }, [handleEscape])
}
