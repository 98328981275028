import { useEffect, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import env from '~/env'

const WebsocketDemo = () => {
    // For the real implementation, it is better to have a proper env variable for this rather than hacking the API_BASE_URL.
    const webSocketUrl = env.VITE_API_BASE_URL.replace('https', 'wss') + '/ws'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [messageHistory, setMessageHistory] = useState<MessageEvent<any>[]>([])
    const { lastMessage, readyState } = useWebSocket(webSocketUrl, { share: true, onClose: console.error })

    useEffect(() => {
        if (lastMessage !== null) {
            setMessageHistory(prev => prev.concat(lastMessage))
        }
    }, [lastMessage])

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState]

    return (
        <div className="flex flex-col gap-2">
            <span>The WebSocket is currently {connectionStatus}</span>
            <ul>
                {messageHistory.map((message, idx) => (
                    <div key={idx}>{message ? message.data : null}</div>
                ))}
            </ul>
        </div>
    )
}

export default WebsocketDemo
