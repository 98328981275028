import { ResolvedPatientGroup, ScheduledSurgery } from '~/store/selectors'
import { OccupancyStatus } from '~/store/utils/blockEvaluation'
import { isNullish } from '~/utils/guards'

import { CapacityRuleTypes, CountBasedRule } from './types'

// surgeryCounts includes weighted surgeries
type BookingId = string

export type SurgeryCounts = Map<string, { patientGroup: ResolvedPatientGroup; bookingIds: (BookingId | undefined)[]; value: number }>

function aggregateSurgeryCounts(counts: SurgeryCounts): number {
    let sum = 0
    for (const { value } of counts.values()) sum += value
    return sum
}

function accumulateSurgeryCounts(a: SurgeryCounts, b: SurgeryCounts): SurgeryCounts {
    const result = a
    for (const [patientGroupKey, data] of b) {
        a.set(patientGroupKey, {
            patientGroup: data.patientGroup,
            value: (a.get(patientGroupKey)?.value ?? 0) + data.value,
            bookingIds: [...(a.get(patientGroupKey)?.bookingIds ?? []), ...data.bookingIds],
        })
    }
    return result
}

function subtractSurgeryCounts(a: number, b: SurgeryCounts): number {
    const remaining = a - aggregateSurgeryCounts(b)
    return remaining < 0 ? 0 : remaining
}

export function newCountBasedRule(
    patientGroupFilter: ResolvedPatientGroup | null,
    maxValue: number | null,
    getSurgeryOccupancy: (surgery: ScheduledSurgery, patientGroup: ResolvedPatientGroup | null) => SurgeryCounts
): CountBasedRule {
    const evaluateSurgeryCounts = function (occupancy: SurgeryCounts) {
        if (isNullish(maxValue)) {
            return OccupancyStatus.Available
        }

        const occupancyValue = aggregateSurgeryCounts(occupancy)
        const remaining = subtractSurgeryCounts(maxValue, occupancy)

        if (occupancyValue < maxValue && remaining >= 1) {
            return OccupancyStatus.Available
        }

        if (occupancyValue <= maxValue) {
            return OccupancyStatus.Filled
        }

        return OccupancyStatus.Overbooked
    }

    return {
        type: CapacityRuleTypes.CountBased,
        patientGroupFilter: patientGroupFilter,
        getSurgeryOccupancy: getSurgeryOccupancy,
        accumulate: accumulateSurgeryCounts,
        subtract: subtractSurgeryCounts,
        evaluate: evaluateSurgeryCounts,
        filledBlockThreshold: maxValue,
        zeroValue: new Map(),
    }
}
