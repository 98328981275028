import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

import { PractitionerScheduleId, PractitionerScheduleStatus, selectEntities } from '~/store/selectors'

export const selectGetPractitionerScheduleStatuses = createSelector(selectEntities, ({ practitionerScheduleStatuses }) => {
    const sortedPractitionerScheduleStatuses = sortBy(practitionerScheduleStatuses, status => status.id)
    const byPractitionerScheduleId = groupBy(sortedPractitionerScheduleStatuses, val => val.practitioner_schedule_id)

    return {
        byPractitionerScheduleId: (practitionerScheduleId: PractitionerScheduleId): PractitionerScheduleStatus[] =>
            byPractitionerScheduleId[practitionerScheduleId] ?? [],
    }
})
