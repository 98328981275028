import { autoUpdate, Middleware, Placement, useDismiss, UseDismissProps, useFloating, useInteractions, useRole, UseRoleProps } from '@floating-ui/react'
import clsx from 'clsx'
import { ReactNode } from 'react'

import { Show } from '~/components'

export const Popover = ($: {
    open: boolean
    onOpenChange: (show: boolean) => void

    placement?: Placement
    middleware?: Middleware[]

    dismissProps?: UseDismissProps
    roleProps?: UseRoleProps

    className?: string
    trigger: ReactNode
    children: ReactNode
}) => {
    const { refs, floatingStyles, context } = useFloating({
        placement: $.placement ?? 'bottom-start',
        open: $.open,
        onOpenChange: $.onOpenChange,
        middleware: $.middleware,
        whileElementsMounted: autoUpdate,
    })

    const dismiss = useDismiss(context, $.dismissProps)
    const role = useRole(context, $.roleProps)

    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, role])

    return (
        <div className={clsx('cursor-auto', $.className)}>
            <div className="h-full" ref={refs.setReference} {...getReferenceProps()}>
                {$.trigger}
            </div>

            <Show condition={$.open}>
                <div ref={refs.setFloating} className="z-40" style={floatingStyles} {...getFloatingProps()}>
                    <div className="popover">{$.children}</div>
                </div>
            </Show>
        </div>
    )
}
