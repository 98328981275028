import { Dayjs } from 'dayjs'

import { getToday } from './getToday'
import { isSameDate } from './isSameDate'

/**
 * Function to convert a Dayjs date object into a human-readable string.
 *
 * @param {Dayjs} date - The date to be converted.
 *
 * @returns {string} - The human-readable string representation of the date.
 *
 * If the date is the same as today, it returns "i dag kl HH:mm".
 * If the date is the same as yesterday, it returns "i går kl HH:mm".
 * If the date is the same as tomorrow, it returns "i morgen kl HH:mm".
 * Otherwise, it returns the date in the format "DD.MM.YYYY kl HH:mm".
 */
export function humanizeDate(date: Dayjs) {
    if (isSameDate(getToday(), date)) {
        return `i dag kl ${date.format('HH:mm')}`
    }

    if (isSameDate(getToday().subtract(1, 'day'), date)) {
        return `i går kl ${date.format('HH:mm')}`
    }

    if (isSameDate(getToday().add(1, 'day'), date)) {
        return `i morgen kl ${date.format('HH:mm')}`
    }

    return date.format('DD.MM.YYYY kl HH:mm')
}
