import { ReactNode } from 'react'

import { TagColor } from '~/components'

import { WaitingListTags } from '../shared/WaitingListTags'

type Props = {
    title: string | ReactNode
    'data-test'?: string
    children: ReactNode
}

export const DrawerElement = ({ children, title, 'data-test': data_test }: Props) => (
    <div data-test={data_test}>
        <div className="inline-block pb-1 font-semibold">{title}</div>
        <div>{children}</div>
    </div>
)

type TaggedProps = Omit<Props, 'children'> & {
    tags: string | string[]
    tagColor?: TagColor
}

export const DrawerElementWithTags = ({ title, 'data-test': data_test, tags, tagColor }: TaggedProps) => (
    <DrawerElement title={title} data-test={data_test}>
        <WaitingListTags tags={tags} tagColor={tagColor} fallback={'—'} />
    </DrawerElement>
)
