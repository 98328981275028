import { combineReducers, configureStore } from '@reduxjs/toolkit'

import env from '~/env'
import { dipsApi } from '~/store/dipsApi'

import { diApi } from './diApi'
import { reduxIntegrationMiddleware } from './utils/legacyIntegration'

const rootReducer = combineReducers({
    [diApi.reducerPath]: diApi.reducer,
    [dipsApi.reducerPath]: dipsApi.reducer,
})

const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        devTools: env.DEV,
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(diApi.middleware, dipsApi.middleware, reduxIntegrationMiddleware),
    })
}

export const rtkStore = setupStore()
