import { Dayjs } from 'dayjs'
import { createSelector } from 'reselect'

import { getAssistants, getSurgeons, MinimalPractitioner } from '~/utils/dips'
import { format } from '~/utils/extendedDayjs'

import { RuleEvaluation } from '../utils/blockEvaluation'
import { selectGetBlockSchedules } from './blockSchedules'
import { BlockSchedule, Location, ScheduledSurgery } from './entities'
import { evaluateBlock, findMismatchedSurgeries } from './internal/blockEvaluations/blockEvaluation'
import { selectGetResolvedPatientGroups } from './resolvedPatientGroups'
import { selectGetScheduledSurgeries } from './scheduledSurgeries'

function getCacheKey(date: Dayjs, location: Location) {
    return `${format(date, 'YYYY-MM-DD')}-${location.id}`
}

export type OccupancyData = {
    date: Dayjs
    location: Location
    blockSchedule: BlockSchedule | null
    evaluations: RuleEvaluation[]
    bookedSurgeries: ScheduledSurgery[]
    allBookedSurgeons: MinimalPractitioner[]
    mismatchedSurgeries: ScheduledSurgery[]
}

export const selectGetOccupancies = createSelector(
    selectGetResolvedPatientGroups,
    selectGetBlockSchedules,
    selectGetScheduledSurgeries,
    (getResolvedPatientGroups, getBlockSchedules, getScheduledSurgeries) => {
        const cache: Record<string, OccupancyData> = {}

        return {
            byDateAndLocation: (date: Dayjs, location: Location): OccupancyData => {
                const key = getCacheKey(date, location)
                const cached = cache[key]
                if (cached) {
                    return cached
                }

                const locationId = location.id
                const blockSchedule = getBlockSchedules.byDateAndLocationId(date, locationId)
                const bookedSurgeries = getScheduledSurgeries.byDateAndRoomCode(date, location.room_code)
                const allSurgeryResources = bookedSurgeries.flatMap(surgery => surgery.surgeryResources)

                const bookedSurgeons = getSurgeons(allSurgeryResources)
                const bookedAssistants = getAssistants(allSurgeryResources)
                const allBookedSurgeons = [...bookedSurgeons, ...bookedAssistants]

                const evaluations = evaluateBlock(blockSchedule, bookedSurgeries, getResolvedPatientGroups)
                const mismatchedSurgeries = findMismatchedSurgeries(bookedSurgeries, blockSchedule, getResolvedPatientGroups)

                const occupancyData = {
                    date,
                    location,
                    blockSchedule,
                    evaluations,
                    bookedSurgeries,
                    allBookedSurgeons,
                    mismatchedSurgeries,
                }

                cache[key] = occupancyData

                return occupancyData
            },
        }
    }
)
