import clsx from 'clsx'
import { ComponentProps } from 'react'

import { Button, buttonBaseStyles, buttonColorStyles, buttonSizeStyles, linkColorStyles } from './Button'

type PickedButtonProps = Pick<ComponentProps<typeof Button>, 'size' | 'color' | 'link'>

type Props = JSX.IntrinsicElements['a'] & PickedButtonProps

export const LinkButton = ({ children, className, size = 'md', color = 'primary', link, ...props }: Props) => (
    <a
        className={clsx(buttonBaseStyles, buttonSizeStyles[size], link ? linkColorStyles[color] : buttonColorStyles[color], className, 'transition-none')}
        {...props}
    >
        {children}
    </a>
)
