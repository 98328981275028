import { motion } from 'framer-motion'
import { useState } from 'react'

import { Show } from '~/components'
import env from '~/env'
import { useImportOccupancyEntities } from '~/hooks/useImportOccupancyEntities'
import { selectDrawerDates, selectGetLocations, selectGetOccupancies, selectGetScheduledPractitioners } from '~/store/selectors'
import { useStore } from '~/store/store'
import { isNullish } from '~/utils/guards'

import { BlockCard } from '../Calendar/CellBlock/BlockCard'
import { BookingWaitingList } from './BookingWaitingList'
import { LoadingCard } from './LoadingCard'

export const BookingDrawerAvailability = () => {
    const [animationEnded, setAnimationEnded] = useState(false)
    const { location_id } = useStore(state => state.operationalPlanner.drawerProps) ?? {}
    const { date, startTime, endTime } = useStore(selectDrawerDates)
    const getOccupancies = useStore(selectGetOccupancies)
    const getScheduledPractitioners = useStore(selectGetScheduledPractitioners)
    const getLocations = useStore(selectGetLocations)

    const { isLoading } = useImportOccupancyEntities(startTime, endTime)

    const location = location_id ? getLocations.byId(location_id) : null
    if (isNullish(location)) return null

    const occupancyData = location_id ? getOccupancies.byDateAndLocation(date, location) : null
    const practitioners = location_id ? getScheduledPractitioners.byDateAndLocationId(date, location_id) : []

    return (
        <motion.div
            onAnimationComplete={() => {
                setAnimationEnded(true)
            }}
            initial={{ opacity: 0, y: 24 }}
            // .2 is standard delay for perceived 'instant' loading
            animate={{ opacity: 1, y: 0, transition: { delay: 0.25, type: 'linear' } }}
            exit={{ opacity: 0, x: -50, transition: { duration: 0.2, type: 'linear' } }}
            className="flex flex-col gap-4"
        >
            <div data-test="BookingDrawerAvailability">
                <p className="pb-1 text-base font-semibold">Ledige Tider</p>
                <Show
                    condition={!isLoading}
                    fallback={
                        <div className="h-32 w-full">
                            <LoadingCard fullHeight />
                        </div>
                    }
                >
                    {occupancyData && (
                        <>
                            <BlockCard date={date} occupancyData={occupancyData} practitioners={practitioners} style="available" location={location} />
                            {animationEnded && <div data-test="booking-drawer-available-loaded" className="hidden" />}
                        </>
                    )}
                </Show>
            </div>
            <div className="flex flex-col gap-4">
                <Show condition={env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER}>
                    <BookingWaitingList practitioners={practitioners} occupancyData={occupancyData} />
                </Show>
            </div>
        </motion.div>
    )
}
