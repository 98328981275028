import { createBatchPractitionerSchedule } from '~/store/entity.api'
import { Location, PractitionerScheduleLocation, PractitionerScheduleStatus, selectGetPractitionerSchedules } from '~/store/selectors'
import { Status } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { HOSPITAL_TIMEZONE } from '~/utils/extendedDayjs/config'

import { DatePractitionerCell } from '../utils'

export function createGetStatusStateFn(selectedCells: DatePractitionerCell[], scheduleStatuses: PractitionerScheduleStatus[]) {
    return (status: Status) => {
        const numCellsWithStatus = scheduleStatuses.filter(scheduleStatus => scheduleStatus.status_code === status).length
        return numCellsWithStatus === 0 ? 'none' : numCellsWithStatus === selectedCells.length ? 'all' : 'some'
    }
}

export function createGetLocationStateFn(selectedCells: DatePractitionerCell[], scheduleLocations: PractitionerScheduleLocation[]) {
    return (locationId: Location['id']) => {
        const numCellsWithLocation = scheduleLocations.filter(location => location.location_id === locationId).length
        return numCellsWithLocation === 0 ? 'none' : numCellsWithLocation === selectedCells.length ? 'all' : 'some'
    }
}

export function useCreateMissingSchedules(selectedCells: DatePractitionerCell[]) {
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)

    const cellsWithoutPractitionerSchedule = selectedCells.filter(
        ({ date, practitionerId }) => !getPractitionerSchedules.byDateAndPractitionerId(date, practitionerId).at(0)
    )

    return () =>
        createBatchPractitionerSchedule(
            cellsWithoutPractitionerSchedule.map(({ date, practitionerId }) => ({
                practitioner_id: practitionerId,
                start_time: date.format(),
                end_time: date.add(1, 'day').format(),
                tzid: HOSPITAL_TIMEZONE,
            }))
        )
}
