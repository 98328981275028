import { FormEvent, useEffect, useState } from 'react'

import { Button, Show, Spinner } from '~/components'
import { importSurgeryMetadata } from '~/store/entity.api'
import { selectGetScheduledSurgeries, selectGetUnScheduledSurgeries } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { isNullish } from '~/utils/guards'

import { FormattedWaitingListItem } from '../../shared/columns'
import { NotShownInDIPSHint, NotShownInDIPSWarning } from '../Comment/NotShownInDIPSWarning'
import { DrawerElement } from '../DrawerElement'
import { OriginalComment } from './OriginalComment'
import { TextArea } from './TextArea'
import { useSaveComment } from './useSaveComment'

type Props = {
    item: FormattedWaitingListItem
}
export const Comment = ({ item }: Props) => {
    const { isLoading } = useImportEntities(() => [importSurgeryMetadata({ booking_id: item.BookingId })], [item.BookingId])

    const waitingListMutableComments_FF = useStore(state => state.featureFlags.waitingListMutableComments)
    const getUnScheduleSurgeries = useStore(selectGetUnScheduledSurgeries)
    const getScheduleSurgeries = useStore(selectGetScheduledSurgeries)
    const unScheduledSurgery = getUnScheduleSurgeries.byBookingId(item.BookingId)
    const scheduledSurgery = getScheduleSurgeries.byBookingId(item.BookingId)

    const [comment, setComment] = useState(item.Comment)
    const [isWritingNewComment, setIsWritingNewComment] = useState(false)

    const surgery = unScheduledSurgery ?? scheduledSurgery

    const saveComment = useSaveComment(surgery?.surgeryMetadata)
    const diComment = surgery?.surgeryMetadata?.comment
    const deepinsightHasComment = !isNullish(diComment)
    const dipsHasComment = item.DIPSComment.trim() !== ''
    const autosave = isWritingNewComment || deepinsightHasComment || !dipsHasComment // this means that we're in a state that's syncing to our api
    const title = isWritingNewComment ? 'Ny kommentar' : dipsHasComment && !deepinsightHasComment ? 'Oppmøtekommentar fra DIPS' : 'Kommentar'

    useEffect(() => {
        if (!isLoading && deepinsightHasComment) {
            setComment(diComment)
        }
    }, [isLoading, diComment, deepinsightHasComment])

    function handleNewCommentClick() {
        setComment('')
        setIsWritingNewComment(true)
    }

    function handleSaveClick() {
        setIsWritingNewComment(false)
        saveComment({ booking_id: item.BookingId, comment: comment })
    }

    function handleCancelClick() {
        setComment(item.Comment)
        setIsWritingNewComment(false)
    }

    function handleInput(e: FormEvent<HTMLTextAreaElement>) {
        setComment(e.currentTarget.value)

        if (!isWritingNewComment) {
            saveComment({ booking_id: item.BookingId, comment: e.currentTarget.value })
        }
    }

    return (
        <DrawerElement title={title} data-test="waiting-list-comment">
            <div className="relative">
                {isLoading && (
                    <div className="absolute right-2 top-2">
                        <Spinner size="sm" />
                    </div>
                )}
                <TextArea value={comment} onInput={handleInput} disabled={!autosave || isLoading} />
            </div>
            <Show condition={!autosave && waitingListMutableComments_FF}>
                <Button onClick={handleNewCommentClick} data-test="new-comment-button" className="mt-2">
                    Skriv ny kommentar
                </Button>
            </Show>
            <Show condition={isWritingNewComment}>
                <NotShownInDIPSWarning />
                <div className="mx-2 grid grid-cols-5 place-items-end">
                    <Button onClick={handleSaveClick} className="col-start-4 p-2" data-test="save-button">
                        Lagre
                    </Button>
                    <Button onClick={handleCancelClick} color="secondary" className="col-start-5 p-2" data-test="abort-button">
                        Avbryt
                    </Button>
                </div>
            </Show>
            <Show condition={dipsHasComment && deepinsightHasComment && !isWritingNewComment}>
                <NotShownInDIPSHint />
            </Show>
            <Show condition={dipsHasComment && autosave}>
                <OriginalComment value={item.DIPSComment} />
            </Show>
        </DrawerElement>
    )
}
