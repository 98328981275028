import { AnalyticsBrowser } from '@segment/analytics-next'
import { enc, SHA256 } from 'crypto-js'

import env from '~/env'
import { Status } from '~/store/slices/filterSlice'
import { RuleInstanceCreate } from '~/store/utils/ruleInstance'

function createAnalyticsBrowser(): AnalyticsBrowser | null {
    if (!env.VITE_SEGMENT_HOSTNAME || !env.VITE_SEGMENT_WRITE_KEY) {
        return null
    }

    return AnalyticsBrowser.load(
        {
            cdnURL: `https://${env.VITE_SEGMENT_HOSTNAME}`,
            writeKey: env.VITE_SEGMENT_WRITE_KEY ?? '',
        },
        {
            integrations: {
                'Segment.io': {
                    apiHost: `${env.VITE_SEGMENT_HOSTNAME}/v1`,
                },
            },
        }
    )
}

export const analytics = createAnalyticsBrowser()

const UserEvents = {
    OP_SWITCH_AVAILABLE_TIMES_VIEW__CLICK: { eventName: 'OP open available times view', category: 'open available times' },
    OP_SEARCHTEXT_AVAILABLE_TIMES_VIEW__SEARCH: { eventName: 'OP search from the available times view', category: 'search on available times' },
    OP_CARD_BOOKINGS__CLICK: { eventName: 'OP open drawer with bookings', category: 'open drawer' },
    OP_CARD_AVAILABILITY__CLICK: { eventName: 'OP open drawer with availability', category: 'open drawer' },
    OP_CARD_WAITINGLIST_AVAILABILITY__CLICK: { eventName: 'OP open drawer from waiting list availability', category: 'open drawer' },
    OP_BUTTON_SAVE_DRAWER_COMMENT__CLICK: { eventName: 'OP drawer save comment', category: 'add/update/remove comment' },
    OP_BUTTON_REMOVE_DRAWER_COMMENT__CLICK: { eventName: 'OP drawer remove comment', category: 'add/update/remove comment' },
    SS_BUTTON_SAVE_LOCATION_COMMENT__CLICK: { eventName: 'SS locations save comment', category: 'add/update/remove comment' },
    SS_BUTTON_REMOVE_LOCATION_COMMENT__CLICK: { eventName: 'SS locations remove comment', category: 'add/update/remove comment' },
    SS_BUTTON_SAVE_SURGEON_COMMENT__CLICK: { eventName: 'SS surgeons save comment', category: 'add/update/remove comment' },
    SS_BUTTON_REMOVE_SURGEON_COMMENT__CLICK: { eventName: 'SS surgeons remove comment', category: 'add/update/remove comment' },
    SS_SURGEON_LOCATION_ADDED: { eventName: 'Surgeon Location Added', category: 'Surgeon Schedule' },
    SS_SURGEON_LOCATION_REMOVED: { eventName: 'Surgeon Location Removed', category: 'Surgeon Schedule' },
    SS_SURGEON_ACTIVITY_ADDED: { eventName: 'Surgeon Activity Added', category: 'Surgeon Schedule' },
    SS_SURGEON_ACTIVITY_REMOVED: { eventName: 'Surgeon Activity Removed', category: 'Surgeon Schedule' },
    SS_BLOCK_ADDED: { eventName: 'OR-Day Block Added', category: 'Capacity' },
    SS_BLOCK_UPDATED: { eventName: 'OR-Day Block Updated', category: 'Capacity' },
    SS_BLOCK_REMOVED: { eventName: 'OR-Day Block Removed', category: 'Capacity' },
} as const

type UserEventKey = keyof typeof UserEvents

export async function trackEvent(key: UserEventKey, properties: Record<string, unknown> = {}) {
    const event = UserEvents[key]
    await analytics?.track(event.eventName, { category: event.category, ...properties })
}

export function anonymizedUser(userId: string) {
    return SHA256(userId).toString(enc.Hex)
}

type SurgeonAssignmentUpdatedData = {
    numberOfEdits: number
    activity: Status
}

type SurgeonLocationUpdatedData = {
    numberOfEdits: number
    rooms: string[]
}

export type BlockUpdatedData = {
    numberOfEdits: number
    ruleInstance: RuleInstanceCreate
}

type UserEventKeyAndInfo =
    | ['SS_SURGEON_LOCATION_ADDED', SurgeonLocationUpdatedData]
    | ['SS_SURGEON_LOCATION_REMOVED', SurgeonLocationUpdatedData]
    | ['SS_SURGEON_ACTIVITY_ADDED', SurgeonAssignmentUpdatedData]
    | ['SS_SURGEON_ACTIVITY_REMOVED', SurgeonAssignmentUpdatedData]
    | ['SS_BLOCK_ADDED', BlockUpdatedData]
    | ['SS_BLOCK_UPDATED', BlockUpdatedData]
    | ['SS_BLOCK_REMOVED', BlockUpdatedData]

export async function trackUserEvent(...[key, data]: UserEventKeyAndInfo) {
    const event = UserEvents[key]
    let properties = {}
    switch (key) {
        case 'SS_SURGEON_LOCATION_REMOVED':
        case 'SS_SURGEON_LOCATION_ADDED':
            properties = {
                tab: 'location',
                widget: 'dropdown',
                selection: data.numberOfEdits <= 1 ? 'single' : 'multiple',
                number_of_edits: data.numberOfEdits,
                assignment: 'location',
                locations: data.rooms,
            }
            break
        case 'SS_SURGEON_ACTIVITY_ADDED':
        case 'SS_SURGEON_ACTIVITY_REMOVED':
            properties = {
                tab: 'surgeon',
                widget: 'tag',
                selection: data.numberOfEdits <= 1 ? 'single' : 'multiple',
                number_of_edits: data.numberOfEdits,
                assignment: 'activity',
                activity: data.activity,
            }
            break
        case 'SS_BLOCK_ADDED':
        case 'SS_BLOCK_UPDATED':
        case 'SS_BLOCK_REMOVED':
            properties = {
                tab: 'master-scheduler',
                widget: 'block',
                selection: data.numberOfEdits <= 1 ? 'single' : 'multiple',
                number_of_edits: data.numberOfEdits,
                capacity: data.ruleInstance,
            }
            break
        default:
            return
    }
    await analytics?.track(event.eventName, { category: event.category, ...properties })
}
