import { FilterDropdown, PractitionerFilter } from '~/components'
import { selectWaitingListFilters } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

import { AsaFilter } from './AsaFilter'
import { DayFilter } from './DayFilter'
import { OperationTypesFilter } from './OperationTypesFilter'
import { ShortNoticeFilter } from './ShortNoticeFilter'

export const Filters = () => {
    const { removeSingleFilter, emptyFilters } = useStore(state => state.appFilters.actions)
    const filters = useStore(selectWaitingListFilters)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleClearFilters = () => {
        emptyFilters()
        setSelectedPage(1)
    }

    const handleOnDisplayItemRemove = (id: string) => {
        removeSingleFilter(id)
        setSelectedPage(1)
    }

    return (
        <FilterDropdown
            clearFilters={handleClearFilters}
            onDisplayItemRemove={handleOnDisplayItemRemove}
            displayItems={filters.map(filter => ({ id: filter.id, label: filter.label })) ?? []}
        >
            <ShortNoticeFilter />
            <AsaFilter />
            <PractitionerFilter />
            <OperationTypesFilter />
            <DayFilter />
        </FilterDropdown>
    )
}
