import DeepinsightLogo from '/deepinsight-logo.png'
import HeroLogo from '/hero.svg'
import { CopyToClipboard, LinkButton } from '~/components'
import env from '~/env'
import { LinkOutlined } from '~/icons'

function getLoginUrl() {
    const url = new URL(env.VITE_API_BASE_URL + '/oauth/login')
    url.searchParams.set('redirect_url', window.location.href)
    url.searchParams.set('oauth_client', env.VITE_API_OAUTH_CLIENT)

    return url.toString()
}

const AuthPage = () => (
    <div className="grid h-screen grid-cols-1 md:grid-cols-2">
        <div className="hidden items-center justify-center bg-white md:flex">
            <img src={HeroLogo} alt="Hero logo" />
        </div>
        <div className="flex h-full flex-col items-center justify-start bg-slate-50 px-24 py-16" data-test="auth-page">
            <img src={DeepinsightLogo} alt="Deepinsight logo" className="my-[8vh] min-w-[165px]" />
            <h1 className="mb-[10vh] text-center text-5xl font-bold leading-[58px]">
                Velkommen til <br />
                Deepinsight Hero
            </h1>
            <LinkButton
                data-test="login-button"
                href={getLoginUrl()}
                color="indigo"
                size="xl"
                className="mb-auto flex w-full max-w-[476px] justify-center font-semibold"
            >
                Logg inn
            </LinkButton>
            <div className="my-10 flex justify-center">
                <CopyToClipboard copyText="https://help.deepinsight.io">
                    <button className="flex items-center gap-1 p-2 text-lg font-semibold text-indigo-700">
                        <LinkOutlined className="min-h-[24px] min-w-[24px]" />
                        Kopier lenke til brukerdokumentasjon
                    </button>
                </CopyToClipboard>
            </div>
        </div>
    </div>
)

export default AuthPage
