import { Spinner } from '~/components'
import {
    importDepartmentLocationAssignments,
    importDepartmentPractitionerAssignments,
    importDepartments,
    importLocations,
    importPractitioners,
} from '~/store/entity.api'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'

import Department from './Department'

function useActiveDepartments() {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const departmentsList = useStore(state => state.di.entities.departments.allIds.map(id => state.di.entities.departments.byId[id]).filter(Boolean))

    return departmentsList.filter(department => departmentKey === 'all' || department.id === departmentKey)
}

const Departments = () => {
    const activeDepartments = useActiveDepartments()
    const { isError, isLoading } = useImportEntities(
        () => [
            importLocations({ exclude_departments: true }),
            importDepartments({ exclude_locations: true, exclude_practitioners: true }),
            importPractitioners({ exclude_departments: true }),
            importDepartmentLocationAssignments({}),
            importDepartmentPractitionerAssignments({}),
        ],
        []
    )

    return (
        <div className="flex flex-col gap-2">
            {isLoading && <Spinner />}
            {isError && <div className="text-red-500">Error loading departments</div>}
            <ul className="flex gap-2">
                {activeDepartments.map(department => {
                    return (
                        <li key={department.id} className="">
                            <Department department={department} />
                        </li>
                    )
                })}
                <li key="uncategorized">
                    <Department department={'uncategorized'} />
                </li>
            </ul>
        </div>
    )
}

export default Departments
