import { isLikelySomeEntityArray, isSomeEntity, SomeEntity } from '~/clients/api-guards'

/**
 * Calls a function for each entity in a list of entities, including nested entities,
 * but only once per encountered entity. The callback is provided with a shallow
 * entity (without any nested entities).
 */
export function walkEntities(entitiesToTraverse: SomeEntity[], entityEncounterCallback: (entity: SomeEntity) => void): void {
    // Clone to avoid modifying the original object
    const entities = JSON.parse(JSON.stringify(entitiesToTraverse)) as SomeEntity[]
    const processedEntities = new Set<string>()

    function walkEntity(entity: SomeEntity) {
        const identifier = `${entity.entity_type}:${entity.id}`
        if (processedEntities.has(identifier)) {
            return // Skip processing if already processed
        }

        processedEntities.add(identifier)

        Object.keys(entity).forEach(key => {
            const value = entity[key]

            if (isSomeEntity(value)) {
                walkEntity(value)
                delete entity[key]
            } else if (isLikelySomeEntityArray(value)) {
                value.forEach(walkEntity)
                delete entity[key]
            }
        })

        // Call the callback function with the cleaned entity
        entityEncounterCallback(entity)
    }

    entities.forEach(walkEntity)
}
