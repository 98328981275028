import clsx from 'clsx'
import { ReactNode } from 'react'

import { Id } from './utils'

export type HeaderProps<HeaderRows extends { id: Id }> = {
    label: string

    row: HeaderRows[]
    rowRender: (column: HeaderRows) => ReactNode
    rowClassName?: (column: HeaderRows) => string
}

export function Header<T extends { id: Id }>({ label, row, rowRender, rowClassName }: HeaderProps<T>) {
    return (
        <tr>
            <th className="sticky left-0 top-0 z-30 w-[100px] border-b border-r border-[#8391c3] bg-white p-2">
                <span className="flex-start flex font-medium">{label}</span>
            </th>
            {row.map(cell => (
                <th
                    key={cell.id}
                    data-column={cell.id}
                    className={clsx(rowClassName?.(cell), 'sticky top-0 z-20 border-b border-r border-b-[#8391c3] bg-white p-2 font-medium last:border-r-0')}
                >
                    {rowRender(cell)}
                </th>
            ))}
        </tr>
    )
}
