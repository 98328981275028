import { Dayjs } from 'dayjs'

import { day } from './day'
import { format } from './format'

/**
 * Returns the date in the format 'YYYY-MM-DD'.
 *
 * @param {Dayjs | string} date - The date to be formatted.
 * @return {string} The formatted date.
 */
export function getOnlyDate(date: Dayjs | string): string {
    const dayjsDate = typeof date === 'string' ? day(date) : date
    return format(dayjsDate, 'YYYY-MM-DD')
}
