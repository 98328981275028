import { flip, offset, shift } from '@floating-ui/react'
import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import capitalize from 'lodash/capitalize'
import { useEffect, useState } from 'react'

import { day, format, getToday, WeekObject } from '~/utils/extendedDayjs'
import { getFridayInWeek } from '~/utils/extendedDayjs/helpers/getFridayInWeek'

import { Popover } from '../../Popover'
import { DatePickerBottom } from './helpers/DatePickerBottom'
import { DatePickerInput } from './helpers/DatePickerInput'
import { MonthSwitch } from './helpers/DateSwitches'
import { DefaultDatePickerProps } from './helpers/DefaultDatePickerProps'
import { GridMonth } from './helpers/GridMonth'

type WeekProps = {
    week: WeekObject
    value?: Dayjs
    handleClickWeek: (year: number, weekNumber: number) => void
}

const Week = ({ value, week, handleClickWeek }: WeekProps) => {
    const isCurrentWeek = week.weekNumber === value?.isoWeek() && week.year === value?.startOf('isoWeek').year()

    return (
        <div
            key={week.weekNumber}
            onClick={() => handleClickWeek(week.year, week.weekNumber)}
            className={clsx('grid cursor-pointer grid-cols-6 rounded p-1 text-center', {
                'pointer-events-none bg-diBlue-500 text-white': isCurrentWeek,
                'hover:bg-gray-100': !isCurrentWeek,
            })}
        >
            <span className={clsx({ 'text-gray-400': !isCurrentWeek })}>{week.weekNumber}</span>

            {week.weekdays.map((day, i) => (
                <div key={i}>{format(day, 'D')}</div>
            ))}
        </div>
    )
}

function getFormattedDate(value?: Dayjs) {
    if (!value) return

    const firstDayOfWeek = value.startOf('isoWeek')
    const lastDayOfWorkingWeek = getFridayInWeek(value)
    const isOverlappingMonth = firstDayOfWeek.month() !== lastDayOfWorkingWeek.month()

    const monthLabel = isOverlappingMonth
        ? `${capitalize(format(firstDayOfWeek, 'MMM'))} & ${capitalize(format(lastDayOfWorkingWeek, 'MMM'))}`
        : capitalize(format(value, 'MMM'))

    return `${monthLabel} ${format(value, 'YYYY')} / Uke ${value.isoWeek()}`
}

export const WeekPicker = ({ value, onChange, size, placement, allowClear = false }: DefaultDatePickerProps) => {
    const [open, setOpen] = useState(false)
    const [relativeDate, setRelativeDate] = useState(getToday())
    const formattedDate = getFormattedDate(value)

    useEffect(() => {
        if (value) setRelativeDate(value)
    }, [value])

    function closeModal() {
        setOpen(false)
        setRelativeDate(value ?? getToday())
    }

    function setToToday(today: Dayjs) {
        setRelativeDate(today)
        onChange(today)
        closeModal()
    }

    function handleClickWeek(year: number, weekNumber: number) {
        onChange(day().year(year).isoWeek(weekNumber).startOf('isoWeek'))
        closeModal()
    }

    function clearDate() {
        onChange(undefined)
        closeModal()
    }

    return (
        <Popover
            open={open}
            onOpenChange={setOpen}
            placement={placement}
            middleware={[offset(5), shift(), flip()]}
            trigger={<DatePickerInput size={size} formattedDate={formattedDate} onClick={() => setOpen(true)} />}
        >
            <MonthSwitch monthBack={setRelativeDate} month={relativeDate} monthForward={setRelativeDate} />
            <hr className="my-1 h-px bg-gray-200" />
            <GridMonth includeWeekNumber relativeDate={relativeDate}>
                {week => <Week key={week.weekNumber} week={week} value={value} handleClickWeek={handleClickWeek} />}
            </GridMonth>
            <hr className="my-1 h-px bg-gray-200" />
            <DatePickerBottom allowClear={allowClear} clearDate={clearDate} setDateToToday={setToToday} />
        </Popover>
    )
}
