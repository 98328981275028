import { useEffect, useState } from 'react'

import { useStore } from '~/store/store'

/**
 * Shows a generic toast error message (when isError is true), and optionally a message on the dev console.
 * Ensures it's displayed only once per component.
 */
export function useShowError(isError: boolean, consoleError?: string | null): void {
    const [hasShownError, setHasShownError] = useState(false)
    const addToast = useStore(state => state.toaster.actions.addToast)

    useEffect(
        () => {
            if (!hasShownError && isError) {
                if (consoleError) {
                    console.error(consoleError)
                }
                addToast({ type: 'error', message: 'Det oppstod en feil' })
                setHasShownError(true)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isError, hasShownError]
    )
}
