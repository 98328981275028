type StringCode = {
    code: string
}

type NumberCode = {
    code: number | null | undefined
}

export const SurgeryTypeGroupCode = ({ code }: StringCode) => {
    return <code className="rounded border border-green-300 bg-green-100 px-1 py-0.5 text-xs text-green-800">{code}</code>
}

export const HospitalCode = ({ code }: NumberCode) => {
    return <code className="rounded border border-yellow-300 bg-yellow-100 px-1 py-0.5 text-xs text-yellow-800">{String(code)}</code>
}

export const WeightCode = ({ code }: NumberCode) => {
    return <code className="rounded border border-blue-300 bg-blue-100 px-1 py-0.5 text-xs text-blue-800">weight: {code}</code>
}

export const OverriddenWeightCode = ({ code }: NumberCode) => {
    return <code className="rounded border border-orange-300 bg-orange-100 px-1 py-0.5 text-xs text-orange-800">overridden weight: {code}</code>
}
