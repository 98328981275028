import clsx from 'clsx'
import { Dayjs } from 'dayjs'

import { HolidayTag, Table } from '~/components'
import {
    importDepartmentLocationAssignments,
    importDepartmentPractitionerAssignments,
    importPractitioners,
    importPractitionerScheduleLocations,
    importPractitionerSchedules,
    importPractitionerScheduleStatuses,
} from '~/store/entity.api'
import {
    Practitioner,
    PractitionerSchedule,
    selectGetPractitioners,
    selectGetPractitionerScheduleLocations,
    selectGetPractitionerSchedules,
    selectGetPractitionerScheduleStatuses,
    selectMonthIdDates,
    selectTimeArgs,
} from '~/store/selectors'
import { selectPractitionerValues, Status } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { format, getToday, isHoliday, isSameDate } from '~/utils/extendedDayjs'
import { isNotNullish } from '~/utils/guards'
import { getHolidayStyles } from '~/utils/utils'

import { getFridayStyles, keyFromTableCells } from '../shared/utils'
import { Cell } from './Cell'
import { PractitionerScheduleModal } from './Modal/PractitionerScheduleModal'
import { selectedCellsToDatePractitionerCells } from './utils'

const isActivePractitioner = (filteredPractitioner: Practitioner[], schedule?: PractitionerSchedule) => {
    return filteredPractitioner.map(practitioner => practitioner.id).includes(Number(schedule?.practitioner?.id))
}

export const PractitionerTable = () => {
    const timeArgs = useStore(selectTimeArgs)

    const { isLoading } = useImportEntities(
        () => [
            importPractitioners({}),
            importDepartmentPractitionerAssignments({}),
            importDepartmentLocationAssignments({}),
            importPractitionerScheduleStatuses({
                'practitioner_schedule.start_time:gte': timeArgs['start_time:gte'],
                'practitioner_schedule.start_time:lte': timeArgs['start_time:lte'],
            }),
            importPractitionerScheduleLocations({
                'practitioner_schedule.start_time:gte': timeArgs['start_time:gte'],
                'practitioner_schedule.start_time:lte': timeArgs['start_time:lte'],
            }),
            importPractitionerSchedules({ ...timeArgs, exclude_practitioner: true, exclude_locations: true, exclude_statuses: true }),
        ],
        [timeArgs]
    )

    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const monthIdDates = useStore(selectMonthIdDates)
    const filteredPractitioners = useStore(selectPractitionerValues)
    const getPractitioners = useStore(selectGetPractitioners)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const getPractitionerScheduleStatuses = useStore(selectGetPractitionerScheduleStatuses)
    const getPractitionerScheduleLocations = useStore(selectGetPractitionerScheduleLocations)

    const practitionersByDepartment = getPractitioners.byDepartmentKey(departmentKey)
    const activePractitioners = practitionersByDepartment.filter(
        practitioner => filteredPractitioners.length === 0 || filteredPractitioners.includes(practitioner.short_name)
    )

    function getCommentStyle(practitioner: Practitioner, date: Dayjs) {
        if (isHoliday(date)) return false

        const schedule = getPractitionerSchedules.byDateAndPractitionerId(date, practitioner.id).at(0)
        if (!schedule) return false

        const statuses = getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule.id).map(status => status.status_code)
        if (!statuses.includes('OTHER')) return false

        const condition = isNotNullish(schedule.comment) && schedule.comment !== ''

        return condition ? 'bg-emerald-300/10  small-triangle-bookmark' : ''
    }

    function getAmountOf(statuses: Status[], date: Dayjs) {
        const practitionerSchedulesByDate = getPractitionerSchedules.byDate(date)
        const practitionerScheduleStatuses = practitionerSchedulesByDate.flatMap(schedule =>
            getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule.id)
        )

        return practitionerScheduleStatuses
            .filter(({ schedule }) => isActivePractitioner(activePractitioners, schedule))
            .filter(status => statuses.includes(status.status_code)).length
    }

    return (
        <Table
            data-test="practitioner-table"
            isLoading={isLoading}
            header={{
                label: 'Kirurger',
                row: monthIdDates,
                rowClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date), { 'calendar-today': isSameDate(date, getToday()) }),
                rowRender: ({ date }) => (
                    <div className="flex flex-col gap-1">
                        {format(date, 'DD.MM')}
                        {isHoliday(date) && <HolidayTag />}
                    </div>
                ),
            }}
            body={{
                rows: activePractitioners,
                rowRender: practitioner => practitioner.short_name,
                cellClassName: (practitioner, { date }) => clsx(getCommentStyle(practitioner, date), getFridayStyles(date), getHolidayStyles(date)),
                cellRender: (practitioner, { date }) => {
                    if (isHoliday(date)) return null

                    const schedule = getPractitionerSchedules.byDateAndPractitionerId(date, practitioner.id).at(0)
                    if (!schedule) return null

                    const statuses = getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule.id)
                    const locationSchedules = getPractitionerScheduleLocations.byPractitionerScheduleId(schedule.id)

                    return <Cell statuses={statuses} locationSchedules={locationSchedules} />
                },
            }}
            footers={[
                {
                    header: 'Operasjon',
                    footerClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
                    cellRender: ({ date }) => {
                        if (isHoliday(date)) return null

                        const practitionersWithSurgeryOrOnCallStatus = getAmountOf(['SURGERY', 'ON-CALL'], date)
                        return practitionersWithSurgeryOrOnCallStatus > 0 && practitionersWithSurgeryOrOnCallStatus
                    },
                },
                {
                    header: 'Dagkirurgi',
                    footerClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
                    cellRender: ({ date }) => {
                        if (isHoliday(date)) return null

                        const practitionersWithDaySurgeryStatus = getAmountOf(['DAY_SURGERY'], date)
                        return practitionersWithDaySurgeryStatus > 0 && practitionersWithDaySurgeryStatus
                    },
                },
                {
                    header: 'Poliklinikk',
                    footerClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
                    cellRender: ({ date }) => {
                        if (isHoliday(date)) return null

                        const practitionersWithPolyclinicStatus = getAmountOf(['POLICLINIC'], date)
                        return practitionersWithPolyclinicStatus > 0 && practitionersWithPolyclinicStatus
                    },
                },
                {
                    header: 'Kveldspoliklinikk',
                    footerClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
                    cellRender: ({ date }) => {
                        if (isHoliday(date)) return null

                        const practitionersWithEveningPolyclinicStatus = getAmountOf(['EVENING_POLYCLINIC'], date)
                        return practitionersWithEveningPolyclinicStatus > 0 && practitionersWithEveningPolyclinicStatus
                    },
                },
            ]}
            modal={selectedCells => (
                <PractitionerScheduleModal key={keyFromTableCells(selectedCells)} selectedCells={selectedCellsToDatePractitionerCells(selectedCells)} />
            )}
        />
    )
}
