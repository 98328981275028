import { z } from 'zod'

/**
 * This schema exists because the API has not properly defined
 * the schema for the parameters in the patient group. This is
 * a temporary solution until the API is updated, but it also
 * serves as a good example of how to relatively safely define
 * a schema for a response that is not well defined and how to
 * then use it in the store in the type guard.
 */
const hierarchySchema = z.object({
    parents: z.array(z.string()),
    children: z.array(z.string()),
    display_children: z.boolean(),
})

const surgerySchema = z.object({
    weight: z.number().nullable(),
    surgery_type_id: z.number(),
})

const surgeriesSchema = z.array(surgerySchema)

const parametersSchema = z.object({
    hierarchy: hierarchySchema,
    surgeries: surgeriesSchema,
})

export const patientGroupOutZodSchema = z.object({
    created_at: z.string(),
    display_name: z.string(),
    entity_type: z.string(),
    generated: z.boolean(),
    id: z.number(),
    is_active: z.boolean(),
    parameters: parametersSchema,
    patient_group_definition_id: z.string(),
    patient_group_id: z.string(),
    updated_at: z.string(),
    updated_by: z.string().optional(),
})

export type PatientGroupOutZod = z.infer<typeof patientGroupOutZodSchema>
