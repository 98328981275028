import { Dayjs } from 'dayjs'
import { createSelector } from 'reselect'

import { needleToTokens } from '~/utils/highlightNeedlesDebounced'

import { Location } from './entities'
import { selectGetOccupancies } from './occupancy'
import { selectGetScheduledPractitioners } from './practitionerSchedules'

export const selectGetNeedleMatch = createSelector(selectGetScheduledPractitioners, selectGetOccupancies, (getScheduledPractitioners, getOccupancies) => {
    return {
        byAllPractitioners: (date: Dayjs, location: Location, text: string): boolean => {
            const tokens = needleToTokens(text)
            if (tokens.length === 0) return true

            const occupancyData = getOccupancies.byDateAndLocation(date, location)
            const scheduledPractitioners = getScheduledPractitioners
                .byDateAndLocation(date, location)
                .map(practitioner => practitioner.short_name.toLocaleLowerCase())

            const bookedSurgeonsShortNames = occupancyData.allBookedSurgeons.map(surgeon => surgeon.short_name.toLocaleLowerCase())
            const allSurgeonShortNames = [...bookedSurgeonsShortNames, ...scheduledPractitioners].join(' ')
            return tokens.some(token => allSurgeonShortNames.includes(token.toLocaleLowerCase()))
        },
        byScheduledPractitioners: (date: Dayjs, location: Location, text: string): boolean => {
            const tokens = needleToTokens(text)
            if (tokens.length === 0) return true

            const scheduledPractitioners = getScheduledPractitioners
                .byDateAndLocation(date, location)
                .map(practitioner => practitioner.short_name.toLocaleLowerCase())
                .join(' ')

            return tokens.some(token => scheduledPractitioners.includes(token.toLocaleLowerCase()))
        },
    }
})
