type Props = {
    label: string
    tooltip?: string
    children: React.ReactNode
}

export const Action = ({ children, label, tooltip }: Props) => {
    return (
        <label className="inline-flex items-center gap-3 rounded border px-3 py-1" title={tooltip}>
            <div>
                {label}
                {tooltip ? <sup className="text-xs text-gray-500">?</sup> : null}
            </div>
            {children}
        </label>
    )
}
