import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import { ReactNode } from 'react'

import { getGridMonth, WeekObject } from '~/utils/extendedDayjs'

const weekdays = ['ma.', 'ti.', 'on.', 'to.', 'fr.'] as const
const labelStyles = clsx('p-2 text-center text-gray-400')

type Props = {
    includeWeekNumber?: boolean
    relativeDate: Dayjs
    children: (week: WeekObject) => ReactNode
}

export const GridMonth = ({ relativeDate, includeWeekNumber = false, children }: Props) => {
    const gridMonth = getGridMonth(relativeDate)
    const isFourWeeks = gridMonth.length === 4

    return (
        <div className="p-1">
            <div className={clsx('grid', includeWeekNumber ? 'grid-cols-6' : 'grid-cols-5')}>
                {includeWeekNumber && <div className={labelStyles}>Uke</div>}

                {weekdays.map(day => (
                    <div key={day} className={labelStyles}>
                        {day}
                    </div>
                ))}
            </div>

            <div className={clsx('flex flex-col space-y-2', isFourWeeks && 'pb-9')}>{gridMonth.map(week => children(week))}</div>
        </div>
    )
}
