import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import { FormEvent, useEffect, useMemo, useState } from 'react'

import { Button, Show, Spinner } from '~/components'
import { useLocationComments } from '~/hooks/useLocationComments'
import { CommentOutlined } from '~/icons'
import { useStore } from '~/store/store'
import { trackEvent } from '~/utils/analytics'

export const Comment = () => {
    const { location_id, unixDate } = useStore(state => state.operationalPlanner.drawerProps) ?? {}
    const { isLoading, updateComment, isSuccess, deleteComment, comment } = useLocationComments({
        location: { date: dayjs.unix(unixDate ?? 0), locationId: location_id ?? 0 },
    })

    const [commentInput, setCommentInput] = useState(comment)

    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        setIsEditing(false)
    }, [isSuccess])

    const hasComment = useMemo(() => commentInput.length > 0, [commentInput])

    const onInput = (e: FormEvent<HTMLTextAreaElement>) => {
        setCommentInput(e.currentTarget.value)
    }

    const handleSave = async (commentToSave: string) => {
        await updateComment(commentToSave)
        void trackEvent('OP_BUTTON_SAVE_DRAWER_COMMENT__CLICK')
    }

    const handleDelete = async () => {
        await deleteComment()
        setCommentInput('')
        void trackEvent('OP_BUTTON_REMOVE_DRAWER_COMMENT__CLICK')
    }

    return (
        <section className="border-b">
            <div className="px-6">
                <Button
                    fullWidth
                    color="secondary"
                    onClick={() => {
                        // reset to initial state if user cancels editing
                        if (isEditing) setCommentInput(comment)
                        setIsEditing(prev => !prev)
                    }}
                    className={`my-4 flex cursor-pointer items-center gap-2 text-base ${!hasComment ? 'justify-center' : ''}`}
                >
                    <Show condition={!isEditing && hasComment} fallback={<span>Skriv ny kommentar</span>}>
                        <CommentOutlined />
                        {commentInput}
                    </Show>
                </Button>
            </div>
            <AnimatePresence>
                {isEditing && (
                    <motion.div layout initial={{ height: 0 }} animate={{ height: 'auto' }} exit={{ height: 0 }} className="overflow-hidden px-6">
                        <textarea
                            className="w-full rounded-lg border p-2 text-sm"
                            disabled={isLoading}
                            rows={4}
                            placeholder="Skriv her"
                            value={commentInput}
                            onInput={onInput}
                        />
                        <div className="flex items-center justify-end gap-2 py-4 text-right">
                            {isLoading && <Spinner />}
                            {/* disable if initial comment is empty, can still delete as long as there is actually something to delete */}
                            <Button disabled={isLoading || comment.length === 0} color="delete" onClick={handleDelete}>
                                Fjerne
                            </Button>
                            <Button disabled={isLoading || !hasComment} onClick={() => handleSave(commentInput)}>
                                Lagre
                            </Button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </section>
    )
}
