import { useLocationComments } from '~/hooks/useLocationComments'
import { trackEvent } from '~/utils/analytics'

import { Comment } from '../../shared/Comment'
import { DateLocationCell } from '../utils'

export function LocationComment($: { selectedCell: DateLocationCell }) {
    const { isLoading, isSuccess, updateComment, deleteComment, comment, setComment } = useLocationComments({ location: $.selectedCell })

    const handleUpdateComment = async () => {
        await updateComment()
        void trackEvent('SS_BUTTON_SAVE_LOCATION_COMMENT__CLICK')
    }

    const handleDeleteComment = async () => {
        await deleteComment()
        void trackEvent('SS_BUTTON_REMOVE_LOCATION_COMMENT__CLICK')
    }

    return (
        <Comment
            comment={comment}
            setComment={setComment}
            updateComment={handleUpdateComment}
            deleteComment={handleDeleteComment}
            isLoading={isLoading}
            isSuccess={isSuccess}
        />
    )
}
