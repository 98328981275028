export const FilterCheckbox = ($: {
    title: string
    values: string[]
    options: { value: string; label: string }[]
    onChange: (args: { value: string; label: string }) => void
}) => (
    <div key={$.title} className="flex flex-col items-center justify-between px-4 pb-4 pt-2">
        <p className="w-full border-b pb-2">{$.title}</p>
        {$.options.map(value => (
            <label key={value.value} className="mt-2 flex w-full border-b pb-2">
                <input
                    type="checkbox"
                    className="accent-indigo-700"
                    value={value.value}
                    checked={$.values.includes(value.value)}
                    onChange={() => $.onChange({ value: value.value, label: value.label })}
                />
                <p className="ml-2">{value.label}</p>
            </label>
        ))}
    </div>
)
