import debounce from 'lodash/debounce'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

// Debounce time chosen based on manually testing how long it would take
// for me to move my mouse to the url bar and select it
const DEBOUNCE_TIME_MS = 400
const searchParamQueue: { key: string; value: string }[] = []

/**
 * Though the functionality of this hook could be done without a queue,
 * Sentry would send a lot of events for each state change.
 * This queueing allows for reducing the amount of Sentry events.
 */
function consumeQueue() {
    if (searchParamQueue.length === 0) return
    const searchParams = new URLSearchParams(window.location.search)

    searchParamQueue.forEach(({ key, value }) => {
        searchParams.set(key, value)
    })

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    window.history.replaceState({ path: newUrl }, '', newUrl)
    searchParamQueue.length = 0
}

const consumeQueueDebounced = debounce(consumeQueue, DEBOUNCE_TIME_MS)

/**
 * Use this search param hook if you want to move the `useState` value to the URL
 * but you don't want to create more history entries for the back/forward buttons
 * but still return to the same state when the user navigates back to the page.
 *
 * Good use cases:
 *  - a filter that you want to be able to share with someone else
 *  - an e-shop with shirt sizes and colors
 *  - sharable, almost session-like behavior
 */
export function useSessionLikeSearchParam<T>(key: string, defaultFn: (value: string | null) => T): [T, Dispatch<SetStateAction<T>>] {
    const searchParams = new URLSearchParams(window.location.search)
    const paramValue = searchParams.get(key)
    const [value, setValue] = useState<T>(defaultFn(paramValue))
    const [initialOffloadDone, setInitialOffloadDone] = useState(false)

    useEffect(() => {
        if (initialOffloadDone) {
            searchParamQueue.push({ key, value: String(value) })
            consumeQueueDebounced()
        }

        setInitialOffloadDone(true)
    }, [key, value, initialOffloadDone])

    return [value, setValue]
}
