import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { selectEntities, SurgeryTypeGroup, SurgeryTypeGroupHierarchy } from './entities'
import { getSurgeryTypeGroupOutParentChildKey } from './surgeryTypeGroups'

/**
 * This selector returns a getter that allows O(1) access to SurgeryTypeGroupHierarchyOut objects by the relationship between parent and child.
 */
export const selectSurgeryTypeGroupHierarchies = createSelector(selectEntities, ({ surgeryTypeGroupHierarchies }) => {
    const byParentAndChildIds = keyBy(surgeryTypeGroupHierarchies, hierarchy =>
        getSurgeryTypeGroupOutParentChildKey(hierarchy.parent_surgery_type_group_id, hierarchy.child_surgery_type_group_id)
    )

    return {
        byParentAndChildRelationship: (parent?: SurgeryTypeGroup, child?: SurgeryTypeGroup): SurgeryTypeGroupHierarchy | null => {
            if (!parent || !child) {
                return null
            }
            return byParentAndChildIds[getSurgeryTypeGroupOutParentChildKey(parent.id, child.id)] ?? null
        },
    }
})
