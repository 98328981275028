import clsx from 'clsx'

import { PractitionerScheduleLocation, PractitionerScheduleStatus } from '~/store/selectors'
import { selectStatusValues, Status, statusesMap } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { day, humanizeDate } from '~/utils/extendedDayjs'

import { ButtonTag } from '../shared/ButtonTag'

function isStringStatus(status: string): status is Status {
    return status in statusesMap
}

export const Cell = ($: { statuses: PractitionerScheduleStatus[]; locationSchedules: PractitionerScheduleLocation[] }) => {
    const filteredStatuses = useStore(selectStatusValues)

    return (
        <div className="flex flex-col items-center gap-2">
            {$.statuses.map(({ status_code: status, updated_by, updated_at }) => {
                if (!isStringStatus(status)) return null

                const editInfo = `Sist endret av ${updated_by} ${humanizeDate(day(updated_at))}`
                const tooltip = `${statusesMap[status].tooltip}\n${editInfo}`

                return (
                    <div key={status} data-tooltip={tooltip}>
                        <ButtonTag
                            colorType={statusesMap[status].color}
                            fillType="all"
                            className={clsx({ 'opacity-30': filteredStatuses.length > 0 && !filteredStatuses.includes(status) })}
                        >
                            {statusesMap[status].label}
                        </ButtonTag>
                    </div>
                )
            })}
            {$.locationSchedules.filter(Boolean).map(cellLocation => {
                const isPractitionerAndLocationSameDepartment = cellLocation.schedule?.practitioner?.departments.some(department =>
                    cellLocation.location?.departments.some(locationDepartment => department.id === locationDepartment.id)
                )

                const diffDepartmentTooltip = isPractitionerAndLocationSameDepartment ? '' : 'Kirurgen og rommet er fra forskjellige avdelinger'
                const tooltip = `Sist endret av ${cellLocation.updated_by} ${humanizeDate(day(cellLocation.updated_at))} \n ${diffDepartmentTooltip}`

                return (
                    <div key={cellLocation.location?.id} data-tooltip={tooltip}>
                        <ButtonTag
                            key={cellLocation.location?.id}
                            data-tooltip={tooltip}
                            colorType={isPractitionerAndLocationSameDepartment ? 'blue' : 'red'}
                            fillType="all"
                            className={clsx('pointer-events-none', {
                                'opacity-30': filteredStatuses.length > 0,
                                'opacity-80': !isPractitionerAndLocationSameDepartment,
                            })}
                        >
                            {cellLocation.location?.room_code}
                        </ButtonTag>
                    </div>
                )
            })}
        </div>
    )
}
