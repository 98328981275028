import { Dayjs } from 'dayjs'
import { createSelector } from 'reselect'

import { groupByMany } from '~/utils/array'

import { StoreState } from '../store'
import { Location, Practitioner, PractitionerSchedule, selectEntities } from './entities'
import { LocationId } from './locations'
import { PractitionerId, selectGetPractitioners } from './practitioners'
import { getDateAndLocationIdKey, getDateAndPractitionerIdKey, getDateKey } from './utils'

export type PractitionerScheduleId = PractitionerSchedule['id']

/*
 * A Practitioner should only have a single PractitionerSchedule per day.
 * The api should prevent violating that but the fact is that it exists in prod already.
 * We'll warn about them and ignore them from then on.
 * Can be removed if https://deepinsight.atlassian.net/browse/PF-1536 is done.
 */
function deduplicate(practitionerSchedules: PractitionerSchedule[]) {
    const byDateAndPractitionerId = groupByMany(practitionerSchedules, schedule => getDateAndPractitionerIdKey(schedule.start_time, schedule.practitioner_id))

    const duplicateSchedules = new Set<PractitionerSchedule>()
    for (const schedulesBySamePractitionerAndDate of Object.values(byDateAndPractitionerId)) {
        // call the ones with the fewest locations assigned (most likely 0, right)
        for (const duplicate of schedulesBySamePractitionerAndDate.sort((a, b) => b.locations.length - a.locations.length).slice(1)) {
            duplicateSchedules.add(duplicate)
        }
    }

    const deduplicated = practitionerSchedules.filter(schedule => !duplicateSchedules.has(schedule))
    if (duplicateSchedules.size !== 0) {
        function format(schedule: PractitionerSchedule) {
            return (
                '{' +
                [
                    schedule?.practitioner?.short_name ?? '?',
                    getDateKey(schedule.start_time),
                    `locations=${JSON.stringify(schedule.locations.map(location => location.id))}`,
                ].join(',') +
                '}'
            )
        }
        console.warn(`Ignoring these ${practitionerSchedules.length - deduplicated.length} schedules: ${[...duplicateSchedules].map(format).join(',\n')}`)
    }
    return deduplicated
}

/**
 * This selector returns a getter that allows O(1) access to PractitionerSchedule objects by
 * date and location after building an index for it.
 */
export const selectGetPractitionerSchedules = createSelector(selectEntities, ({ practitionerSchedules: possiblyDuplicatePractitionerSchedules }) => {
    const practitionerSchedules = deduplicate(possiblyDuplicatePractitionerSchedules)

    const byDateAndLocationId = groupByMany(practitionerSchedules, schedule => {
        return schedule.locations.map(location => getDateAndLocationIdKey(schedule.start_time, location.id))
    })
    const byDate = groupByMany(practitionerSchedules, schedule => getDateKey(schedule.start_time))
    const byDateAndPractitionerId = groupByMany(practitionerSchedules, schedule => getDateAndPractitionerIdKey(schedule.start_time, schedule.practitioner_id))

    return {
        byDateAndLocationId: (date: Dayjs, locationId: LocationId): PractitionerSchedule[] => {
            return byDateAndLocationId[getDateAndLocationIdKey(date, locationId)] ?? []
        },
        byDateAndLocation: (date: Dayjs, location: Location) => {
            return byDateAndLocationId[getDateAndLocationIdKey(date, location.id)] ?? []
        },
        byDate: (date: Dayjs): PractitionerSchedule[] => {
            return byDate[getDateKey(date)] ?? []
        },
        byDateAndPractitioner(date: Dayjs, practitioner: Practitioner): PractitionerSchedule[] {
            return byDateAndPractitionerId[getDateAndPractitionerIdKey(date, practitioner.id)] ?? []
        },
        byDateAndPractitionerId(date: Dayjs, practitionerId: PractitionerId): PractitionerSchedule[] {
            return byDateAndPractitionerId[getDateAndPractitionerIdKey(date, practitionerId)] ?? []
        },
    }
})

export const selectGetScheduledPractitioners = createSelector(selectGetPractitionerSchedules, getPractitionerSchedules => {
    return {
        byDateAndLocation: (date: Dayjs, location: Location) => {
            return Array.from(new Set(getPractitionerSchedules.byDateAndLocationId(date, location.id).map(schedule => schedule.practitioner))).filter(Boolean)
        },
        byDateAndLocationId: (date: Dayjs, locationId: LocationId) => {
            return Array.from(new Set(getPractitionerSchedules.byDateAndLocationId(date, locationId).map(schedule => schedule.practitioner))).filter(Boolean)
        },
    }
})

export const selectGetActiveOnCallPractitioners = createSelector(
    selectGetPractitioners,
    selectGetPractitionerSchedules,
    (state: StoreState) => state.appFilters.departmentKey,
    (getPractitioners, getPractitionerSchedules, departmentKey) => {
        return {
            byDate: (date: Dayjs) => {
                const activePractitioners = departmentKey === 'all' ? [] : getPractitioners.byDepartmentKey(departmentKey)
                return activePractitioners.filter(practitioner =>
                    getPractitionerSchedules
                        .byDateAndPractitionerId(date, practitioner.id)
                        .some(schedule => schedule.statuses.some(status => status.status_code === 'ON-CALL'))
                )
            },
        }
    }
)
