import { OpUnitType, QUnitType } from 'dayjs'

import { AcceptedDayInput, day } from '~/utils/extendedDayjs'

const cache: Map<string, number> = new Map()

/**
 * Calculate the difference between two dates in a specific unit.
 * @param date1 The first date.
 * @param date2 The second date.
 * @param unit The unit of the difference (e.g., 'year', 'month', 'day', 'hour', 'minute', 'second').
 * @returns The difference between the two dates in the specified unit.
 */
export function diff(date1: AcceptedDayInput, date2: AcceptedDayInput, unit?: QUnitType | OpUnitType, float?: boolean): number {
    date1 = day(date1)
    date2 = day(date2)

    // Check cache
    const key = `${date1.valueOf()}_${date2.valueOf()}_${unit}_${float}`
    const cacheValue = cache.get(key)
    if (cacheValue !== undefined) {
        return cacheValue
    }

    // Set cache
    const diff = date1.diff(date2, unit, float)
    cache.set(key, diff)

    return diff
}
