import { Dayjs } from 'dayjs'

import { getDaysBetween } from './getDaysBetween'
import { getFridayInWeek } from './getFridayInWeek'
import { getGridMonthStartEndDays } from './getGridMonthStartEndDays'
import { isSameDate } from './isSameDate'
import { isWeekend } from './isWeekend'

export type WeekObject = {
    year: number
    weekNumber: number
    weekdays: Dayjs[]
}

/**
 * Retrieves the grid month based on a given date.
 * The grid month consists of the weeks that have at least one weekday in the month.
 *
 * @param {Dayjs} date - The date used to determine the month.
 * @returns {WeekObject[]} - An array of weeks in the grid month, each containing year, week number, and weekdays.
 * */
export function getGridMonth(date: Dayjs): WeekObject[] {
    const { start: startOfGridMonth, end: endOfGridMonth } = getGridMonthStartEndDays(date)

    const days = getDaysBetween(startOfGridMonth, endOfGridMonth)
    let weekdays: Dayjs[] = []

    return days.reduce((weeks: WeekObject[], day) => {
        if (!isWeekend(day)) {
            weekdays.push(day)
        }

        if (isSameDate(day, getFridayInWeek(day))) {
            weeks.push({ year: day.isoWeekYear(), weekNumber: day.isoWeek(), weekdays: weekdays })
            weekdays = []
        }

        return weeks
    }, [])
}
