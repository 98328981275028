import { useState } from 'react'

import { RadioButtons, Show, TextInput } from '~/components'
import env from '~/env'

import Departments from './Panels/Departments/Departments'
import EntityEditor from './Panels/EntityEditor/EntityEditor'
import EnvironmentVariables from './Panels/EnvironmentVariables/EnvironmentVariables'
import HospitalSurgeryTypes from './Panels/HospitalSurgeryTypes/HospitalSurgeryTypes'
import SurgeryTypeGroups from './Panels/SurgeryTypeGroups/SurgeryTypeGroups'
import WebsocketDemo from './Panels/WebsocketDemo/WebsocketDemo'

type View = 'departments' | 'patientGroups' | 'surgeryTypeGroups' | 'surgeryTypes' | 'environmentVariables' | 'entityEditor' | 'wsDemo'

type Props = {
    onChange: (view: View) => void
    view: View
}

const ViewSwitcher = ({ onChange, view }: Props) => {
    return (
        <RadioButtons
            options={[
                {
                    label: 'Environment variables',
                    value: 'environmentVariables',
                },
                {
                    label: 'Departments',
                    value: 'departments',
                },
                {
                    label: 'Surgery types',
                    value: 'surgeryTypes',
                },
                {
                    label: 'Surgery type groups',
                    value: 'surgeryTypeGroups',
                },
                {
                    label: 'Entity editor',
                    value: 'entityEditor',
                },
                {
                    label: 'Websocket demo',
                    value: 'wsDemo',
                },
            ]}
            value={view}
            onChange={onChange}
        />
    )
}

const AdminPanel = () => {
    const [view, setView] = useState<View>('environmentVariables')
    const [adminPassword, setAdminPassword] = useState<string>('')

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            setAdminPassword(event.currentTarget.value)
        }
    }

    if (!env.DEV && adminPassword !== 'telephone')
        return (
            <div className="flex h-full items-center justify-center p-2">
                <label>
                    Admin password:
                    <TextInput type="password" defaultValue={adminPassword} onKeyDown={handleKeyDown} placeholder="••••••••" />
                </label>
            </div>
        )

    return (
        <div className="flex flex-col gap-4 p-2">
            <ViewSwitcher onChange={setView} view={view} />
            <Show condition={view === 'environmentVariables'}>
                <EnvironmentVariables />
            </Show>
            <Show condition={view === 'departments'}>
                <Departments />
            </Show>
            <Show condition={view === 'surgeryTypeGroups'}>
                <SurgeryTypeGroups />
            </Show>
            <Show condition={view === 'surgeryTypes'}>
                <HospitalSurgeryTypes />
            </Show>
            <Show condition={view === 'entityEditor'}>
                <EntityEditor />
            </Show>
            <Show condition={view === 'wsDemo'}>
                <WebsocketDemo />
            </Show>
        </div>
    )
}

export default AdminPanel
