import { Fragment } from 'react'

import { Show } from '~/components'

import { NextPageButton, PageButton, PreviousPageButton } from './Buttons'

const Ellipsis = () => <div className="flex w-[34px] justify-center pb-1 pt-2.5 text-diSecondary">...</div>

type Props = {
    selectedPage: number
    setSelectedPage: (page: number) => void
    totalItems: number
    totalPages: number
    pageNumbersToDisplay: number[]
    offsetStart: number
    offsetEnd: number
}

export const Pagination = ({ selectedPage, setSelectedPage, totalItems, totalPages, pageNumbersToDisplay, offsetStart, offsetEnd }: Props) => {
    const isMultiplePages = totalPages !== 1
    const firstPageNumber = pageNumbersToDisplay[0] ?? 1

    const handlePageClick = (page: number) => {
        setSelectedPage(page)
    }

    const handlePreviousPageClick = () => {
        setSelectedPage(selectedPage - 1)
    }

    const handleNextPageClick = () => {
        setSelectedPage(selectedPage + 1)
    }

    return (
        <div data-test="waiting-list-pagination" className="sticky bottom-0 left-0 flex w-full items-center justify-center gap-4 bg-white py-3">
            <Show condition={totalPages !== 0}>
                <div className="flex gap-2">
                    <Show condition={isMultiplePages}>
                        <PreviousPageButton onClick={handlePreviousPageClick} disabled={selectedPage === 1} />
                    </Show>

                    <PageButton onClick={handlePageClick} pageNumber={firstPageNumber} selectedPage={selectedPage} />
                    {pageNumbersToDisplay.slice(1).map((pageNumber, index) => {
                        const toDisplay = pageNumbersToDisplay[index]

                        if (toDisplay === undefined) {
                            return null
                        }

                        return (
                            <Fragment key={pageNumber + 'i'}>
                                <Show condition={toDisplay + 1 !== pageNumber}>
                                    <Ellipsis />
                                </Show>
                                <PageButton onClick={handlePageClick} pageNumber={pageNumber} selectedPage={selectedPage} />
                            </Fragment>
                        )
                    })}

                    <Show condition={isMultiplePages}>
                        <NextPageButton onClick={handleNextPageClick} disabled={selectedPage === totalPages} />
                    </Show>
                </div>
                <p className="text-slate-900">{`Viser ${offsetStart}-${offsetEnd} av ${totalItems}`}</p>
            </Show>
        </div>
    )
}
