import { AgeGroup, Tag } from '~/components'
import { selectMinMaxNumberOfSurgeries } from '~/store/selectors'
import { useStore } from '~/store/store'
import { CountRuleEvaluation } from '~/store/utils/blockEvaluation'
import { formatSurgeryTypeGroup } from '~/store/utils/resolvedPatientGroups'
import { isNullish } from '~/utils/guards'

type Props = {
    data_test?: string
    evaluation: CountRuleEvaluation
}

export const AvailabilityByPatientGroup = ({ data_test, evaluation }: Props) => {
    const getMinMaxNumberOfSurgeries = useStore(selectMinMaxNumberOfSurgeries)

    const surgeryTypeGroupName = formatSurgeryTypeGroup(evaluation.filteredByPatientGroup)
    if (isNullish(evaluation.remaining)) {
        return <Tag>{surgeryTypeGroupName}</Tag>
    }

    const patientGroup = evaluation.filteredByPatientGroup
    const ageGroupCode = patientGroup?.ageGroup?.age_group_code
    const value = getMinMaxNumberOfSurgeries.byRemainingWeight(patientGroup?.surgeryTypeGroup?.code, evaluation.remaining).value
    return (
        <Tag data-test={`${data_test}-available-slots-count'}`}>
            {`Ledig: ${value} ${surgeryTypeGroupName}`} <AgeGroup ageGroupCode={ageGroupCode} />
        </Tag>
    )
}
