import { ReactNode } from 'react'

type Option<T> = {
    label: string
    value: T
    icon?: ReactNode
}

type Props<T> = {
    options: Option<T>[]
    value: T
    onChange: (value: T) => void
    size?: 'sm' | 'md' | 'lg'
    'data-test'?: string
}

const sizeStyles = {
    sm: 'py-0.5 px-2',
    md: 'py-1 px-3',
    lg: 'py-2 px-5',
}

export function RadioButtons<T>({ value, options, onChange, size = 'md', 'data-test': data_test }: Props<T>) {
    return (
        <div className="flex h-fit w-fit items-center p-0" data-test={data_test}>
            {options.map(option => (
                <div
                    data-test={`${option.value}-radio-button`}
                    key={String(option.value)}
                    className={`flex cursor-pointer items-center gap-1 whitespace-nowrap border first:rounded-l last:rounded-r ${sizeStyles[size]} ${option.value === value ? 'border-diBlue-700 bg-diBlue-100 text-diBlue-700' : ''} ${option.value !== value ? 'hover:text-diBlue-700 [&:not(:last-child)]:border-r-0' : ''}`}
                    onClick={() => onChange(option.value)}
                >
                    {option.icon}
                    {option.label}
                </div>
            ))}
        </div>
    )
}
