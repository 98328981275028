import clsx from 'clsx'
import { useCallback, useState } from 'react'

import { AgeGroup, Show, Spinner } from '~/components'
import { useImportSurgeryTypeGroupEntities } from '~/hooks/useImportSurgeryTypeGroupEntities'
import {
    ResolvedSurgeryTypeGroup,
    selectEntities,
    selectHospitalSurgeryTypes,
    selectSurgeryTypeGroup,
    selectSurgeryTypeGroupHierarchies,
    SurgeryTypeGroup,
    SurgeryTypeMetadata,
} from '~/store/selectors'
import { useStore } from '~/store/store'
import { isCompositeSurgeryTypeGroup, isSpecialitySurgeryTypeGroup } from '~/store/utils/surgeryTypeGroupHierarchy'
import { isNotNullish } from '~/utils/guards'

import { HospitalCode, OverriddenWeightCode, SurgeryTypeGroupCode, WeightCode } from '../components/code'
import { List, ListItem } from '../components/list'

type Props = {
    surgeryTypeGroup: SurgeryTypeGroup
    nbrItems: number
    overriddenWeight?: number
    onClick: () => void
    isExpanded: boolean
}

const SurgeryTypeGroupListItemContent = ({ surgeryTypeGroup, nbrItems, overriddenWeight, onClick, isExpanded }: Props) => {
    const isExpandable = Boolean(surgeryTypeGroup.children.length) || Boolean(surgeryTypeGroup.surgeryTypes.length)

    return (
        <div
            className={clsx(
                {
                    'cursor-pointer hover:text-diBlue-600': isExpandable,
                },
                'flex flex-row items-center gap-2 border-l-2 pl-2'
            )}
            onClick={onClick}
        >
            {isExpandable && <div className="text-xs">{isExpanded ? '▼' : '▶'}</div>}
            <div>({nbrItems}) </div>
            <div>{surgeryTypeGroup.display_name}</div>
            {surgeryTypeGroup.ageRestrictions.map(ageRestriction => {
                return <AgeGroup key={ageRestriction.age_group_code} ageGroupCode={ageRestriction.age_group_code} />
            })}
            <div>
                <SurgeryTypeGroupCode code={surgeryTypeGroup.code} />
            </div>
            <div>({surgeryTypeGroup.group_type})</div>
            <Show condition={Boolean(overriddenWeight)}>
                <div>
                    <OverriddenWeightCode code={overriddenWeight} />
                </div>
            </Show>
        </div>
    )
}

type SurgeryTypeGroupListItemProps = {
    surgeryTypeGroup: SurgeryTypeGroup
    parentSurgeryTypeGroup?: SurgeryTypeGroup
}

const SurgeryTypeGroupListItem = ({ surgeryTypeGroup, parentSurgeryTypeGroup }: SurgeryTypeGroupListItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const getSurgeryTypeGroupHierarchies = useStore(selectSurgeryTypeGroupHierarchies)
    const getHospitalSurgeryTypes = useStore(selectHospitalSurgeryTypes)

    const handleContentClick = useCallback(() => {
        setIsExpanded(!isExpanded)
    }, [isExpanded])

    const hasChildren = Boolean(surgeryTypeGroup.children.length)
    const hasSurgeryTypes = Boolean(surgeryTypeGroup.surgeryTypes.length)
    const overriddenWeight = getSurgeryTypeGroupHierarchies.byParentAndChildRelationship(parentSurgeryTypeGroup, surgeryTypeGroup)?.weight
    return (
        <ListItem>
            <SurgeryTypeGroupListItemContent
                surgeryTypeGroup={surgeryTypeGroup}
                nbrItems={surgeryTypeGroup.children.length + surgeryTypeGroup.surgeryTypes.length}
                overriddenWeight={overriddenWeight ?? undefined}
                onClick={handleContentClick}
                isExpanded={isExpanded}
            />
            <Show condition={hasChildren && isExpanded}>
                <List>
                    {surgeryTypeGroup.children.map(child => {
                        return <SurgeryTypeGroupListItem key={child.id} surgeryTypeGroup={child} parentSurgeryTypeGroup={surgeryTypeGroup} />
                    })}
                </List>
            </Show>
            <Show condition={hasSurgeryTypes && isExpanded}>
                <List>
                    {surgeryTypeGroup.surgeryTypes.map(surgeryType => {
                        const hospital_surgery_type = getHospitalSurgeryTypes.byHospitalId(surgeryType.hospital_surgery_type_id)
                        const isOverriddenWeight = Boolean(overriddenWeight && overriddenWeight !== surgeryType.weight)

                        return (
                            <div key={surgeryType.id}>
                                <HospitalCode code={hospital_surgery_type?.hospital_surgery_type_id} />
                                <Show condition={Boolean(surgeryType.weight) && !isOverriddenWeight}>
                                    <WeightCode code={surgeryType.weight} />
                                </Show>
                                <Show condition={isOverriddenWeight}>
                                    <s>
                                        <WeightCode code={surgeryType.weight} />
                                    </s>
                                    <OverriddenWeightCode code={overriddenWeight} />
                                </Show>
                                {` ${hospital_surgery_type?.hospital_surgery_type_name}`}
                            </div>
                        )
                    })}
                </List>
            </Show>
        </ListItem>
    )
}

type SurgeryTypeListItemProps = {
    resolvedSurgeryTypesGroup: ResolvedSurgeryTypeGroup
}

const SurgeryTypeListItem = ({ resolvedSurgeryTypesGroup }: SurgeryTypeListItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const getHospitalSurgeryTypes = useStore(selectHospitalSurgeryTypes)

    const handleContentClick = useCallback(() => {
        setIsExpanded(!isExpanded)
    }, [isExpanded])

    const surgeryTypes = resolvedSurgeryTypesGroup.resolvedSurgeryTypes
    const entries: [number, SurgeryTypeMetadata][] = []
    for (const key in surgeryTypes) {
        const value = surgeryTypes[Number(key)]
        if (value) {
            entries.push([Number(key), value])
        }
    }

    const hasChildren = Boolean(entries.length || 0)

    return (
        <ListItem>
            <SurgeryTypeGroupListItemContent
                surgeryTypeGroup={resolvedSurgeryTypesGroup}
                nbrItems={entries.length}
                onClick={handleContentClick}
                isExpanded={isExpanded}
            />
            <Show condition={hasChildren && isExpanded}>
                <List>
                    {entries.map(([hospitalId, surgeryType]) => {
                        const hospital_surgery_type = getHospitalSurgeryTypes.byHospitalId(hospitalId)

                        return (
                            <div key={hospitalId}>
                                <HospitalCode code={hospital_surgery_type?.hospital_surgery_type_id} /> <WeightCode code={surgeryType.weight} />
                                {` ${hospital_surgery_type?.hospital_surgery_type_name}`}
                            </div>
                        )
                    })}
                </List>
            </Show>
        </ListItem>
    )
}

const SurgeryTypeGroups = () => {
    const { surgeryTypeGroups } = useStore(selectEntities)
    const { isError, isLoading } = useImportSurgeryTypeGroupEntities()

    const getSurgeryTypeGroup = useStore(selectSurgeryTypeGroup)

    return (
        <div className="flex flex-col gap-2">
            {isLoading && <Spinner />}
            {isError && <div className="text-red-500">Error loading surgery type groups</div>}
            {
                <div key="surgery-type-groups" className="rounded border p-2 shadow">
                    <div className="font-semibold">Surgery type groups by specialities</div>
                    <List>
                        {surgeryTypeGroups.filter(isSpecialitySurgeryTypeGroup).map(surgeryTypeGroup => {
                            return (
                                <div key={surgeryTypeGroup.id} className="border p-2 shadow">
                                    <SurgeryTypeGroupListItem surgeryTypeGroup={surgeryTypeGroup} />
                                </div>
                            )
                        })}
                    </List>
                </div>
            }
            {
                <div key="composite-surgery-type-groups" className="rounded border p-2 shadow">
                    <div className="font-semibold">Composite surgery type groups</div>
                    <List>
                        {surgeryTypeGroups.filter(isCompositeSurgeryTypeGroup).map(surgeryTypeGroup => {
                            return (
                                <div key={surgeryTypeGroup.id} className="border p-2 shadow">
                                    <SurgeryTypeGroupListItem surgeryTypeGroup={surgeryTypeGroup} />
                                </div>
                            )
                        })}
                    </List>
                </div>
            }
            {
                <div key="resolved-surgery-type-groups" className="rounded border p-2 shadow">
                    <div className="font-semibold">Resolved Surgery type groups</div>
                    <List>
                        {surgeryTypeGroups
                            .map(surgeryTypeGroup => getSurgeryTypeGroup.byId(surgeryTypeGroup.id))
                            .filter(isNotNullish)
                            .map(resolvedSurgeryTypesGroup => {
                                return (
                                    <div key={resolvedSurgeryTypesGroup.id} className="border p-2 shadow">
                                        <SurgeryTypeListItem resolvedSurgeryTypesGroup={resolvedSurgeryTypesGroup} />
                                    </div>
                                )
                            })}
                    </List>
                </div>
            }
        </div>
    )
}

export default SurgeryTypeGroups
