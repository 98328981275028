import groupBy from 'lodash/groupBy'
import { createSelector } from 'reselect'

import { HospitalSurgeryType, selectEntities } from '../selectors/entities'

export type HospitalSurgeryTypeId = HospitalSurgeryType['id']

export const selectHospitalSurgeryTypes = createSelector(selectEntities, ({ hospitalSurgeryTypes }) => {
    const groupedByHospitalIds = groupBy(hospitalSurgeryTypes, hospitalSurgeryType => hospitalSurgeryType.hospital_surgery_type_id)

    return {
        byHospitalId: (id: HospitalSurgeryTypeId): HospitalSurgeryType | null => {
            const result = groupedByHospitalIds[id]
            if (!result) {
                return null
            }
            if (result?.length !== 1) {
                console.error(`Expected exactly one hospital surgery type with ID ${id}, but found ${result?.length}`)
            }
            return result?.[0] ?? null
        },
    }
})
