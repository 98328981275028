import { Duration } from 'dayjs/plugin/duration'

import {
    BlockScheduleOutPopulated,
    MaxCountRuleCreate,
    MaxCountRuleOut,
    MaxDurationAndCountRuleCreate,
    MaxDurationAndCountRuleOut,
    MaxDurationRuleCreate,
    MaxDurationRuleOut,
    MaxKnifeDurationAndCountRuleCreate,
    MaxKnifeDurationAndCountRuleOut,
    MaxKnifeDurationRuleCreate,
    MaxKnifeDurationRuleOut,
    MaxWeightRuleCreate,
    MaxWeightRuleOut,
    PatientGroupOut,
    RuleDefinitionOut,
} from '~/store/diApi'

import { SurgeryCounts } from '../rules/countBasedRule'
import { ScheduledSurgery } from '../scheduledSurgeries'
import { OccupancyStatusType } from './OccupancyStatus'

export const CapacityRuleTypes = {
    CountBased: 'CountBased',
    RoomDurationBased: 'RoomDurationBased',
    KnifeTimeBased: 'KnifeTimeBased',
} as const
export type CapacityRuleType = (typeof CapacityRuleTypes)[keyof typeof CapacityRuleTypes]

export type CapacityRuleBase<T, Z, D extends CapacityRuleType> = {
    type: D // discriminator field
    patientGroupFilter: PatientGroupOut | undefined
    getSurgeryOccupancy: (surgery: ScheduledSurgery, allPatientGroups: PatientGroupOut[]) => T
    accumulate(a: T, b: T): T
    subtract(a: Z, b: T): Z
    evaluate(a: T): OccupancyStatusType
    filledBlockThreshold: Z | undefined
    zeroValue: T
}

export type CountBasedRule = CapacityRuleBase<SurgeryCounts, number, typeof CapacityRuleTypes.CountBased>
export type DurationBasedRule = CapacityRuleBase<Duration, Duration, typeof CapacityRuleTypes.RoomDurationBased | typeof CapacityRuleTypes.KnifeTimeBased>
export type CapacityRule = CountBasedRule | DurationBasedRule

export function isCountBasedRule(rule: CapacityRule): rule is CountBasedRule {
    return rule.type === CapacityRuleTypes.CountBased
}

export function isDurationBasedRule(rule: CapacityRule): rule is DurationBasedRule {
    return rule.type === CapacityRuleTypes.RoomDurationBased
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export type BlockRuleOut =
    | (MaxCountRuleOut & { definition: RuleDefinitionOut })
    | (MaxWeightRuleOut & { definition: RuleDefinitionOut })
    | (MaxDurationRuleOut & { definition: RuleDefinitionOut })
    | (MaxDurationAndCountRuleOut & { definition: RuleDefinitionOut })
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    | (MaxKnifeDurationRuleOut & { definition: RuleDefinitionOut })
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents, @typescript-eslint/no-duplicate-type-constituents
    | (MaxKnifeDurationAndCountRuleOut & { definition: RuleDefinitionOut })

export type SchedulableBlock = BlockScheduleOutPopulated & {
    ruleInstance: BlockRuleOut
}

export type BlockRuleCreate =
    | MaxCountRuleCreate
    | MaxWeightRuleCreate
    | MaxDurationRuleCreate
    | MaxDurationAndCountRuleCreate
    | MaxKnifeDurationRuleCreate
    | MaxKnifeDurationAndCountRuleCreate
    | undefined
    | null

export type BlockRules = BlockRuleOut | BlockRuleCreate

export type MaxCountRule = MaxCountRuleOut | MaxCountRuleCreate
export type MaxWeightRule = MaxWeightRuleOut | MaxWeightRuleCreate
export type MaxDurationRule = MaxDurationRuleOut | MaxDurationRuleCreate
export type MaxDurationAndCountRule = MaxDurationAndCountRuleOut | MaxDurationAndCountRuleCreate
export type MaxKnifeDurationRule = MaxKnifeDurationRuleOut | MaxKnifeDurationRuleCreate
export type MaxKnifeDurationAndCountRule = MaxKnifeDurationAndCountRuleOut | MaxKnifeDurationAndCountRuleCreate

export function isMaxCountRule(blockRule: BlockRules): blockRule is MaxCountRule {
    return blockRule?.rule_definition_id === 'max_count_of_surgeries_per_patient_group'
}

export function isMaxWeightRule(blockRule: BlockRules): blockRule is MaxWeightRule {
    return blockRule?.rule_definition_id === 'max_weight_of_surgeries_per_patient_group'
}

export function isMaxDurationRule(blockRule: BlockRules): blockRule is MaxDurationRule {
    return blockRule?.rule_definition_id === 'max_duration_of_surgeries_per_patient_group'
}

export function isMaxDurationAndCountRule(blockRule: BlockRules): blockRule is MaxDurationAndCountRule {
    return blockRule?.rule_definition_id === 'max_duration_and_max_count_of_surgeries_per_patient_group'
}

export function isMaxKnifeDurationRule(blockRule: BlockRules): blockRule is MaxKnifeDurationRule {
    return blockRule?.rule_definition_id === 'max_knife_time_of_surgeries_per_patient_group'
}

export function isMaxKnifeDurationAndCountRule(blockRule: BlockRules): blockRule is MaxKnifeDurationAndCountRule {
    return blockRule?.rule_definition_id === 'max_knife_time_and_max_count_of_surgeries_per_patient_group'
}
