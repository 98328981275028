import { Dayjs } from 'dayjs'

import { getDaysBetween } from './getDaysBetween'
import { getFridayInWeek } from './getFridayInWeek'

/**
 * Get the working days in a week based on a given date.
 *
 * @param {Dayjs} date - The date used to determine the week.
 * @return {Dayjs[]} An array containing the working days of the week.
 */
export function getWorkingDaysInWeek(date: Dayjs): Dayjs[] {
    const firstWorkday = date.startOf('isoWeek')
    const lastWorkday = getFridayInWeek(date)

    return getDaysBetween(firstWorkday, lastWorkday)
}
