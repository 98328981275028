import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'

import { importUnScheduledSurgeries } from '~/store/dips-entity.api'
import { UnscheduledSurgery } from '~/store/dipsApi'
import { OccupancyData, Practitioner, selectGetUnScheduledSurgeries, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { isCountBasedRuleEvaluation } from '~/store/utils/blockEvaluation'
import { getSurgeons } from '~/utils/dips'

const sortByOrderedDate = (surg1: UnscheduledSurgery, surg2: UnscheduledSurgery) =>
    dayjs(surg1.surgeryOrderDetails?.orderedDate)?.unix() - dayjs(surg2.surgeryOrderDetails?.orderedDate).unix()

type UnScheduledSuggestionsProps = { scheduledPractitioners: Practitioner[]; occupancyData: OccupancyData | null }

/**
 * Suggests unscheduled surgeries that can fit a surgery slot in an available block
 * @param scheduledPractitioners practitioners that are scheduled for the given date and location
 * @param occupancyData occupancy data for the given date and location
 * @returns list of unscheduled surgeries that can be scheduled for the given date and location
 */
export const useUnScheduledSuggestions = ({ scheduledPractitioners, occupancyData }: UnScheduledSuggestionsProps) => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const getUnScheduleSurgeries = useStore(selectGetUnScheduledSurgeries)
    const unScheduledSurgeries = getUnScheduleSurgeries.byDepartmentKey(departmentKey)

    const { isError, isLoading } = useImportEntities(
        () => [importUnScheduledSurgeries()],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
        { hasAlreadyData: Boolean(unScheduledSurgeries.length) }
    )

    const scheduledPatientGroups = useMemo(
        () =>
            occupancyData?.evaluations
                .filter(isCountBasedRuleEvaluation)
                .map(evaluation => evaluation.filteredByPatientGroup)
                .filter(Boolean) ?? [],
        [occupancyData]
    )

    const filterByScheduledPractitioners = useCallback(
        (surgery: UnscheduledSurgery) => {
            const surgeryPractitioners = getSurgeons(surgery.surgeryResources)

            return surgeryPractitioners.some(practitioner => {
                return scheduledPractitioners.map(p => p.short_name.toLocaleLowerCase()).includes(practitioner.short_name.toLocaleLowerCase())
            })
        },
        [scheduledPractitioners]
    )

    const filterByScheduledPatientGroups = useCallback(
        (surgery: UnScheduledSurgery) => {
            const surgeryTypeId = surgery.surgeryType?.id
            return surgeryTypeId && scheduledPatientGroups.some(pg => pg?.surgeryTypeGroup?.resolvedSurgeryTypes?.hasOwnProperty(surgeryTypeId))
        },
        [scheduledPatientGroups]
    )

    const unScheduledSuggestions = useMemo(
        () => unScheduledSurgeries.filter(filterByScheduledPractitioners).filter(filterByScheduledPatientGroups).sort(sortByOrderedDate),
        [unScheduledSurgeries, filterByScheduledPatientGroups, filterByScheduledPractitioners]
    )

    return { unScheduledSuggestions, isError, isLoading }
}
