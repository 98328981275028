import isBoolean from 'lodash/isBoolean'
import { useMemo } from 'react'

import { useStore } from '~/store/store'

import { columns, WaitingListColumn } from '../shared/columns'

export const useColumnsToShow = () => {
    const activeView = useStore(state => state.app.activeViews.WAITING_LIST)
    const departmentKey = useStore(state => state.appFilters.departmentKey)

    return useMemo(
        () =>
            Object.entries(columns)
                .filter(([, { showColumn }]) => (isBoolean(showColumn) ? showColumn : showColumn(departmentKey, activeView)))
                .map(([column, { label }]) => ({ column: column as WaitingListColumn, label })),
        [departmentKey, activeView]
    )
}
