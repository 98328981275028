import { FilterCheckbox } from '~/components'
import { dayOvernightMap, Option, selectDayOvernightValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

const dayOptions: Option[] = Object.entries(dayOvernightMap).map(([key, value]) => ({ value: key, label: value.label }))

export const DayFilter = () => {
    const dayOvernightValues = useStore(selectDayOvernightValues)
    const toggleFilter = useStore(state => state.appFilters.actions.toggleFilter)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleOnchange = (value: string, label: string) => {
        toggleFilter({ type: 'day-overnight', value, label })
        setSelectedPage(1)
    }

    return <FilterCheckbox title="Dag/Døgn" values={dayOvernightValues} options={dayOptions} onChange={({ value, label }) => handleOnchange(value, label)} />
}
