import clsx from 'clsx'

import { CheckOutlined } from '~/icons'

import { Show } from '../../Show'

export type Option<T extends string | number> = {
    value: T
    label: string
}

type ListProps<T extends string | number> = {
    options: Option<T>[]
    values: T[] | null
    onChange: (value: T) => void
}

export const OptionsList = <T extends string | number>({ options, values, onChange }: ListProps<T>) => {
    return (
        <div className="p-1">
            <ul className="mb-0 flex list-outside list-none flex-col space-y-1 p-0">
                <Show condition={options.length !== 0} fallback={<li className="px-2 py-1 text-gray-500">Ingen treff</li>}>
                    {options.map(option => {
                        const isActive = values?.includes(option.value) ?? false

                        return (
                            <li
                                key={option.value}
                                onClick={() => onChange(option.value)}
                                className={clsx(
                                    'flex w-full cursor-pointer items-center justify-between space-x-4 rounded px-2 py-1',
                                    isActive ? 'bg-diBlue-100' : 'hover:bg-gray-100'
                                )}
                            >
                                <span>{option.label}</span>
                                <Show condition={isActive}>
                                    <CheckOutlined className="h-5 w-5 text-diBlue-500" />
                                </Show>
                            </li>
                        )
                    })}
                </Show>
            </ul>
        </div>
    )
}
