import { useEffect, useState } from 'react'

import {
    useGetSwaggerV1SwaggerJsonQuery,
    useOrganizationCodeListByCodelistidQuery,
    useOrganizationDepartmentsQuery,
    useOrganizationLocalizationsQuery,
    useOrganizationSectionsQuery,
    useOrganizationWardsQuery,
} from '~/store/dipsApi'

type DownloadProps = {
    label: string
    data: object | undefined
    isFetching: boolean
    setSkip: React.Dispatch<React.SetStateAction<boolean>>
}

const BaseDownloadButton = ({ label, data, isFetching, setSkip }: DownloadProps) => {
    useEffect(() => {
        if (!data) return

        const blob = new Blob([JSON.stringify(data, null, '\t')], { type: 'application/json' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${label}.json`
        a.click()
        a.remove()
    }, [data, label])

    return (
        <button onClick={() => setSkip(false)} disabled={isFetching} className="block cursor-pointer rounded bg-green-200 p-2">
            <div className="flex flex-row items-center justify-center gap-2">
                <div>Download: {label}</div>
                {isFetching && <div className="animate-spin">◒</div>}
            </div>
        </button>
    )
}

type ButtonProps = {
    label: string
}

type DownloadCodeListButtonProps = ButtonProps & {
    codelistid: number
}

const DownloadCodeListButton = ({ label, codelistid }: DownloadCodeListButtonProps) => {
    const [skip, setSkip] = useState(true)
    const { data, isFetching } = useOrganizationCodeListByCodelistidQuery({ codelistid }, { skip })

    return BaseDownloadButton({ label, data, isFetching, setSkip })
}

const DownloadSwaggerButton = ({ label }: ButtonProps) => {
    const [skip, setSkip] = useState(true)
    const { data, isFetching } = useGetSwaggerV1SwaggerJsonQuery(undefined, { skip })

    return BaseDownloadButton({ label, data, isFetching, setSkip })
}

const DownloadLocalizationsButton = ({ label }: ButtonProps) => {
    const [skip, setSkip] = useState(true)
    const { data, isFetching } = useOrganizationLocalizationsQuery({ includeNotAccessTo: true }, { skip })

    return BaseDownloadButton({ label, data, isFetching, setSkip })
}
const DownloadDepartmentsButton = ({ label }: ButtonProps) => {
    const [skip, setSkip] = useState(true)
    const { data, isFetching } = useOrganizationDepartmentsQuery({ includeNotAccessTo: true, includeReplaced: true }, { skip })

    return BaseDownloadButton({ label, data, isFetching, setSkip })
}
const DownloadSectionsButton = ({ label }: ButtonProps) => {
    const [skip, setSkip] = useState(true)
    const { data, isFetching } = useOrganizationSectionsQuery({ includeNotAccessTo: true, includeReplaced: true }, { skip })

    return BaseDownloadButton({ label, data, isFetching, setSkip })
}
const DownloadWardsButton = ({ label }: ButtonProps) => {
    const [skip, setSkip] = useState(true)
    const { data, isFetching } = useOrganizationWardsQuery({ includeNotAccessTo: true, includeReplaced: true }, { skip })

    return BaseDownloadButton({ label, data, isFetching, setSkip })
}
const DownloadPage = () => {
    return (
        <div className="mx-auto flex w-[500px] flex-col gap-2 p-5">
            <div className="text">This page is used for downloading configuration data. None of these downloads contain sensitive patient data.</div>
            <DownloadSwaggerButton label="ProcedurePlanningAPI" />
            <DownloadCodeListButton label="LocationTypes" codelistid={3297} />
            <DownloadCodeListButton label="SurgeryTypes" codelistid={2507} />
            <DownloadCodeListButton label="FinishCodeTypes" codelistid={11} />
            <DownloadCodeListButton label="PostponeCodeTypes" codelistid={1019} />
            <DownloadCodeListButton label="CancellationCodeTypes" codelistid={1115} />
            <DownloadLocalizationsButton label="Localizations" />
            <DownloadDepartmentsButton label="Departments" />
            <DownloadSectionsButton label="Sections" />
            <DownloadWardsButton label="Wards" />
        </div>
    )
}

export default DownloadPage
