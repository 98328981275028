import { memo } from 'react'

import { Spinner } from '~/components'

type Props = {
    onClick: () => void
    isCreating: boolean
    entityName: string
}

const AddNewButton = memo(({ onClick, isCreating, entityName }: Props) => (
    <div className="flex flex-col gap-2">
        {isCreating && (
            <div className="flex items-center justify-center">
                <Spinner size="sm" />
            </div>
        )}
        <div className="flex cursor-pointer items-center justify-center rounded border p-0.5 hover:bg-green-500 hover:text-white" onClick={onClick}>
            Add new {entityName}
        </div>
    </div>
))

export default AddNewButton
