import debounce from 'lodash/debounce'

export function needleToTokens(needle: string): string[] {
    // implement exact-match searching for input between double quotes (but still case-insensitive)
    const indexOfFirstDQ = needle.indexOf('"')
    if (indexOfFirstDQ !== -1) {
        const indexOfSecondDQ = needle.indexOf('"', indexOfFirstDQ + 1)
        if (indexOfSecondDQ !== -1) {
            const exactTerm = needle.substring(indexOfFirstDQ + 1, indexOfSecondDQ)
            return [
                ...needleToTokens(needle.substring(0, indexOfFirstDQ)),
                exactTerm.trim().toLocaleLowerCase(),
                ...needleToTokens(needle.substring(indexOfSecondDQ + 1)),
            ].filter(Boolean)
        } else {
            // this is a bit of an edge case with one double-quote. Best to ignore it:
            return needle.split('"').flatMap(needleToTokens).filter(Boolean)
        }
    }

    return needle
        .split(' ')
        .map(s => s.trim().toLocaleLowerCase())
        .filter(Boolean)
}

const spanCache = new Map<string, HTMLSpanElement>()
/**
 * Create a span element with the needle highlighted in the text.
 *
 * Uses cache to optimise performance of setting span.innerHTML.
 *
 * Returns null if no needle was encountered, i.e. no need to replace the node.
 */
function spanByMatch(lowerCaseNeedles: string[], text: string): HTMLSpanElement | null {
    const cacheKey = `NEEDLE:${lowerCaseNeedles.join('&')}, TEXT:${text}`
    const cachedSpan = spanCache.get(cacheKey)

    if (cachedSpan) {
        return cachedSpan.cloneNode(true) as HTMLSpanElement
    }

    const parts = text.split(new RegExp(lowerCaseNeedles.map(l => `(${l})`).join('|'), 'gi')).filter(Boolean)
    if (parts.length === 1) {
        return null
    }
    const highlightedParts = parts.map(part => {
        if (lowerCaseNeedles.includes(part.toLowerCase())) {
            return `<span data-highlighted="true" class="bg-yellow-200">${part}</span>`
        }

        return part
    })
    const span = document.createElement('span')
    span.innerHTML = highlightedParts.join('')
    spanCache.set(cacheKey, span)
    return span.cloneNode(true) as HTMLSpanElement
}

/**
 * @param needleLowercasedTokens The needle that has been split by space into tokens, and each are trimmed and case lowered — like in the function needleToTokens.
 */
function highlightNeedles(needleLowercasedTokens: string[], elementRef: React.RefObject<HTMLElement>) {
    if (!elementRef.current) {
        return
    }

    // Cleanup any existing highlights
    const highlighted = elementRef.current.querySelectorAll('span[data-highlighted="true"]')
    const parents = new Set<HTMLElement>()
    highlighted.forEach(element => {
        if (element.parentNode instanceof HTMLElement) {
            parents.add(element.parentNode)
        }
        element.replaceWith(...element.childNodes)
    })

    parents.forEach(parent => {
        parent.normalize()
    })

    // Add new highlights
    if (needleLowercasedTokens.length === 0) {
        return
    }

    const elements = elementRef.current.querySelectorAll('*')
    elements.forEach(element => {
        const textNodes = Array.from(element.childNodes).filter(node => node.nodeType === Node.TEXT_NODE)

        textNodes.forEach(node => {
            const text = node.textContent ?? ''
            const newSpan = spanByMatch(needleLowercasedTokens, text)
            if (newSpan) {
                node.replaceWith(newSpan)
            }
        })
    })
}

/**
 * Highlights occurrences of a specified string (needle) within a given HTML table.
 * This function searches for the needle string within the text nodes of the table and wraps them
 * in a span with a class that highlights them. It clears any previous highlights before adding new ones and normalizes the parent nodes for reusability.
 * The highlighting is delayed by 100ms to optimize performance, especially during rapid updates (e.g., typing in a search field).
 */
export const highlightNeedlesDebounced = debounce(highlightNeedles, 100)
