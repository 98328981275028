import { Dayjs } from 'dayjs'
import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { BlockLock, selectEntities } from './entities'
import { LocationId } from './locations'
import { getDateAndLocationIdKey } from './utils'

export const selectGetBlockLocks = createSelector(selectEntities, ({ blockLocks }) => {
    const byDateAndLocationId = keyBy(blockLocks, blockLock => getDateAndLocationIdKey(blockLock.start_time, blockLock.location_id))

    return {
        byDateAndLocationId: (date: Dayjs, location_id: LocationId): BlockLock | null => {
            return byDateAndLocationId[getDateAndLocationIdKey(date, location_id)] ?? null
        },
    }
})
