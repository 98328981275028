import clsx from 'clsx'
import { forwardRef } from 'react'

type Size = 'sm' | 'md' | 'lg' | 'xl' | 'icon' | 'custom'
export const buttonSizeStyles: Record<Size, string> = {
    icon: 'flex items-center p-1',
    sm: 'px-1.5',
    md: 'px-3 py-1',
    lg: 'px-4 py-1.5 text-lg',
    xl: 'px-5 py-3 text-lg',
    custom: '',
}

type ColorType = 'primary' | 'white' | 'delete' | 'custom' | 'secondary' | 'indigo' | 'transparent'
export const buttonColorStyles: Record<ColorType, string> = {
    primary: 'bg-diBlue-500 border border-diBlue-500 hover:bg-diBlue-600 text-white hover:text-white',
    white: 'bg-white border text-black hover:text-diBlue-700',
    delete: 'bg-white border hover:border-diRed-400 hover:bg-diRed-400 hover:text-white',
    secondary: 'border border-diSecondary text-diSecondary hover:bg-diSecondary-hover',
    indigo: 'bg-indigo-700 text-white hover:bg-indigo-800 active:bg-indigo-700',
    transparent: 'bg-transparent border border-transparent text-indigo-700 hover:text-di font-semibold',
    custom: '',
}

export const linkColorStyles: Record<ColorType, string> = {
    primary: 'bg-transparent text-diBlue-500 hover:text-diBlue-600',
    white: 'bg-transparent hover:text-diBlue-700',
    delete: 'bg-transparent text-diRed-400 hover:border-diRed-400 hover:bg-diRed-400 hover:text-white',
    secondary: 'bg-transparent text-diRed-400 hover:text-white',
    indigo: 'bg-indigo-700 text-white hover:bg-indigo-800 active:bg-indigo-700',
    transparent: 'bg-transparent text-indigo-700 hover:text-di font-semibold',
    custom: '',
}

export const buttonBaseStyles = 'rounded cursor-pointer disabled:opacity-50 disabled:pointer-events-none'

type Props = JSX.IntrinsicElements['button'] & {
    size?: Size
    color?: ColorType
    link?: boolean
    fullWidth?: boolean
}

export const Button = forwardRef<HTMLButtonElement, Props>(
    ({ color = 'primary', children, link, size = 'md', className, fullWidth, onClick, ...props }, ref) => (
        <button
            ref={ref}
            className={clsx(
                buttonBaseStyles,
                buttonSizeStyles[size],
                link ? linkColorStyles[color] : buttonColorStyles[color],
                fullWidth ? 'w-full' : '',
                className
            )}
            onClick={e => {
                if (onClick) onClick(e)
            }}
            {...props}
        >
            {children}
        </button>
    )
)
