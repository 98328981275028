import { BaseInputTemplateProps } from '@rjsf/utils'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { format } from '~/utils/extendedDayjs'

import { TextInput } from '../../Inputs/TextInput'

const hoursMinutesRegex = /^(\d{1,2}):(\d{1,2})$/
const hoursDecimalRegex = /^(\d+)\.(\d+)$/

function getDurationFromValue(value?: string) {
    return value ? format(dayjs.duration(value), 'HH:mm') : ''
}

function getHoursMinutesDuration(hours: number, minutes: number) {
    return dayjs.duration({ hours, minutes })
}

function getISODuration(value: string) {
    const hoursMinutesMatch = value.match(hoursMinutesRegex)
    if (hoursMinutesMatch) {
        return getHoursMinutesDuration(Number(hoursMinutesMatch[1]), Number(hoursMinutesMatch[2])).toISOString()
    }

    const hoursDecimalMatch = value.match(hoursDecimalRegex)
    if (hoursDecimalMatch) {
        const minutes = Math.round(Number(`0.${hoursDecimalMatch[2]}`) * 60)
        return getHoursMinutesDuration(Number(hoursDecimalMatch[1]), minutes).toISOString()
    }

    const hours = parseInt(value)
    if (!isNaN(hours)) {
        return dayjs.duration({ hours }).toISOString()
    }

    return ''
}

export const DurationInput = ({ id, onChange, value }: BaseInputTemplateProps) => {
    const [formattedDuration, setFormattedDuration] = useState('')

    useEffect(() => {
        setFormattedDuration(getDurationFromValue(value))
    }, [value])

    function handleOnBlur() {
        if (!formattedDuration) return

        const isoDuration = getISODuration(formattedDuration)

        setFormattedDuration(getDurationFromValue(isoDuration))
        onChange(isoDuration)
    }

    return (
        <div className="grid grid-cols-9">
            <TextInput
                id={id}
                name={id}
                size="sm"
                className="col-span-2"
                placeholder="00:00"
                value={formattedDuration}
                onChange={e => setFormattedDuration(e.target.value)}
                onBlur={handleOnBlur}
                data-1p-ignore /* disable 1password prompt */
            />
        </div>
    )
}
