import { useCallback, useState } from 'react'

type UseAutoHideReturn = [boolean, () => void]

export function useAutoHide(delay: number = 500): UseAutoHideReturn {
    const [visible, setVisible] = useState(false)

    const setVisibleAutoHide = useCallback(() => {
        setVisible(true)
        setTimeout(() => {
            setVisible(false)
        }, delay)
    }, [delay])

    return [visible, setVisibleAutoHide]
}
