/**
 * This file only exists temporarily to integrate the new architecture with the old one.
 * It should be removed once the new architecture is fully implemented.
 * It allows the usage of both the new architecture and the old one in parallel, down to
 * the level of individual components.
 */
import { Middleware } from '@reduxjs/toolkit'
import { z } from 'zod'

import { isLikelySomeEntityArray, isSomeEntity } from '~/clients/api-guards'

import { diApi } from '../diApi'
import { AsyncFn } from '../entity.api'
import { rtkStore } from '../rtkStore'
import { DiEntityKey, useStore } from '../store'

/**
 * Fetch middleware that invalidates the entity cache of RTK after a successful call
 */
type Tag = Parameters<typeof diApi.util.invalidateTags>[0][number]

export function withInvalidation<T extends AsyncFn>(tag: Tag, call: T): (...args: Parameters<T>) => Promise<ReturnType<T>> {
    return async (...args: Parameters<T>) => {
        const result = await call(...args)
        rtkStore.dispatch(diApi.util.invalidateTags([tag]))
        return result
    }
}

const RTKMutationActionSchema = z.object({
    meta: z.object({
        arg: z.object({
            endpointName: z.string(),
            originalArgs: z.object({
                id: z.number().optional(),
            }),
        }),
    }),
    payload: z.any(),
})

const mutationToStoreKey = {
    BlockSchedule: 'blockSchedules',
    Department: 'departments',
    DepartmentLocationAssignment: 'departmentLocationAssignments',
    DepartmentPractitionerAssignment: 'departmentPractitionerAssignments',
    Location: 'locations',
    LocationSchedule: 'locationSchedules',
    PatientGroupDefinition: 'patientGroupDefinitions',
    PatientGroup: 'patientGroups',
    Practitioner: 'practitioners',
    PractitionerSchedule: 'practitionerSchedules',
    PractitionerScheduleStatus: 'practitionerScheduleStatuses',
    PractitionerScheduleLocation: 'practitionerScheduleLocations',
    RuleDefinition: 'ruleDefinitions',
    Section: 'sections',
    BlockLock: 'blockLocks',
    Speciality: 'specialities',
    AgeGroup: 'ageGroups',
    HospitalSurgeryType: 'hospitalSurgeryTypes',
    SurgeryTypeGroup: 'surgeryTypeGroups',
    SurgeryTypeGroupAgeRestriction: 'surgeryTypeGroupAgeRestrictions',
    SurgeryTypeGroupSpeciality: 'surgeryTypeGroupSpecialities',
    SurgeryTypeGroupHierarchy: 'surgeryTypeGroupHierarchies',
    HospitalSurgeryTypeGroupAssociation: 'hospitalSurgeryTypeGroupAssociations',
} satisfies Record<string, DiEntityKey>

type RTKMutationKey = keyof typeof mutationToStoreKey

function isRTKMutationKey(key: string): key is RTKMutationKey {
    return key in mutationToStoreKey
}

function splitEndpointName(s: string): [string | null, RTKMutationKey | null] {
    const parts = s.split(/(?=[A-Z])/)
    const prefix = parts[0]
    const entityName = parts.slice(1).join('')

    if (isRTKMutationKey(entityName) && typeof prefix === 'string') {
        return [prefix, entityName]
    }

    return [null, null]
}

function handleRTKMutation(action: unknown) {
    const parsedAction = RTKMutationActionSchema.safeParse(action)

    if (!parsedAction.success) {
        console.error('Failed to parse RTK mutation action', parsedAction.error, action)
        return
    }

    const endpointName = parsedAction.data.meta.arg.endpointName
    const queryArgs = parsedAction.data.meta.arg.originalArgs
    const payload = parsedAction.data.payload

    const [prefix, entityName] = splitEndpointName(endpointName)

    if (!prefix || !entityName) {
        console.error('Unknown endpoint', { endpointName, payload })
        return
    }

    if (prefix === 'create' || prefix === 'update') {
        if (isSomeEntity(payload)) {
            useStore.getState().di.actions.addEntities([payload])
        }
    } else if (prefix === 'delete') {
        if (payload === null && queryArgs.id !== undefined) {
            const storeKey = mutationToStoreKey[entityName]

            if (storeKey === undefined) {
                console.error('Unknown entity', { endpointName, payload, prefix, entityName })
                return
            }

            useStore.getState().di.actions.removeEntity(storeKey, queryArgs.id)
        }
    } else {
        console.error('Unknown mutation', { endpointName, payload, prefix, entityName })
    }
}

/**
 * Redux middleware notifies the new architecture of RTK changes.
 *
 * The middleware listens for fulfilled actions of the diApi and adds the entities to the entity store.
 * It relies on several assumptions about the structure of the RTK actions which are likely but not guaranteed to be stable.
 * Therefore, this should be considered a temporary solution until the new architecture is fully implemented.
 */
export const reduxIntegrationMiddleware: Middleware = () => next => action => {
    const type = action.type
    const payload = action.payload

    if (type === 'diApi/executeQuery/fulfilled' && isLikelySomeEntityArray(payload)) {
        useStore.getState().di.actions.addEntities(payload)
    }

    if (type === 'diApi/executeMutation/fulfilled') {
        handleRTKMutation(action)
    }

    return next(action)
}
