import sortBy from 'lodash/sortBy'

import { Tag } from '~/components'
import { ResolvedConstraintObject } from '~/utils/blockEvaluation'

function formatTagValue(con: ResolvedConstraintObject) {
    const num = con.maxCount > con.minCount ? `${con.minCount}-${con.maxCount}` : `${con.maxCount}`
    return `${num !== '0' ? num : ''}${con.value} ${con.surgeryTypeGroupLabel}`
}

type Props = {
    groupLevel: string
    specialityConstraints?: ResolvedConstraintObject[]
}

export const TagList = ({ groupLevel, specialityConstraints }: Props) => {
    const groupLevelConstraints = sortBy(
        specialityConstraints?.filter(rc => rc.groupLevel === groupLevel),
        v => -v.maxCount
    )

    return (
        <>
            {groupLevelConstraints?.map((resolved, index) => (
                <Tag key={index} color={resolved.groupLevel} fullWidth size="md">
                    {formatTagValue(resolved)}
                </Tag>
            ))}
        </>
    )
}
