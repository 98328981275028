import { createSelector } from 'reselect'

import { largestFirstSurgeryCountForWeight, maxSurgeryCountForWeight } from '~/utils/blockEvaluation'

import { selectSurgeryTypeGroup, SurgeryTypeGroupCode } from './surgeryTypeGroups'

export const selectMinMaxNumberOfSurgeries = createSelector(selectSurgeryTypeGroup, resolvedSurgeryTypeGroups => {
    return {
        byRemainingWeight: (code: SurgeryTypeGroupCode | undefined, remainingWeight: number) => {
            const resolvedSurgeryTypeGroup = resolvedSurgeryTypeGroups.byCode(code)
            if (!resolvedSurgeryTypeGroup) {
                return {
                    value: String(remainingWeight),
                    minCount: remainingWeight,
                    maxCount: remainingWeight,
                }
            }

            const sortedWeights = resolvedSurgeryTypeGroup.sortedUniqueSurgeryWeights
            const maxCount = maxSurgeryCountForWeight(remainingWeight, sortedWeights)
            const minCount = largestFirstSurgeryCountForWeight(remainingWeight, sortedWeights)
            const value = maxCount > minCount ? `${minCount}-${maxCount}` : `${maxCount}`
            return { value, minCount, maxCount }
        },
    }
})
