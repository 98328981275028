import clsx from 'clsx'
import { useRef } from 'react'

import { Show } from '~/components'
import { PersonAlertOutlined } from '~/icons'
import { getDayOvernightTooltipText, isDayOvernightLabel } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { isEllipsisActive } from '~/utils/utils'

import { columns, FormattedWaitingListItem } from '../shared/columns'
import { WaitingListTags } from '../shared/WaitingListTags'
import { Cell } from './Cell'

type Props = {
    item: FormattedWaitingListItem
    className?: string
    'data-test'?: string
}

export const TableRow = ({ item, className, 'data-test': data_test }: Props) => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const { setDrawerProps } = useStore(state => state.waitingList.actions)
    const activeView = useStore(state => state.app.activeViews.WAITING_LIST)
    const isAll = activeView === '/waiting-list/all'
    const isAllOrUnscheduled = isAll || activeView === '/waiting-list/unscheduled'
    const isAllOrScheduled = isAll || activeView === '/waiting-list/scheduled'

    function openDrawer() {
        setDrawerProps({ item })
    }

    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const isSelectedRow = drawerProps?.item.BookingId === item.BookingId
    const commentRef = useRef(null)

    return (
        <tr
            className={clsx(
                className,
                'cursor-pointer truncate hover:bg-indigo-100',
                { 'opacity-30': drawerProps !== null && !isSelectedRow },
                { 'bg-indigo-100': drawerProps !== null && isSelectedRow }
            )}
            onClick={() => openDrawer()}
            data-test={data_test}
        >
            <Cell scope="row">
                {item.Icon && !isDayOvernightLabel(item.Icon) ? <PersonAlertOutlined data-tooltip={getDayOvernightTooltipText(item.Icon)} /> : null}
            </Cell>
            <Cell>{item.WaitingTime}</Cell>
            <Show condition={isAllOrUnscheduled}>
                <Cell>{item.TentativeDate}</Cell>
            </Show>
            <Show condition={isAllOrScheduled}>
                <Cell>{item.OperationDate}</Cell>
            </Show>
            <Cell>
                <div data-tooltip={item.BirthDate}>{item.Patient}</div>
            </Cell>
            <Cell maxWidth={true} data-tooltip={commentRef?.current && isEllipsisActive(commentRef.current) ? item.Comment : ''}>
                <div ref={commentRef} className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {item.Comment}
                </div>
            </Cell>
            <Show condition={columns.ASA.showColumn(departmentKey)}>
                <Cell>
                    <WaitingListTags tags={item.ASA} tagColor="ASA" />
                </Cell>
            </Show>

            <Show condition={columns.ShortNotice.showColumn(departmentKey, activeView)}>
                <Cell data-test={item.ShortNotice}>
                    <WaitingListTags tags={item.ShortNotice} />
                </Cell>
            </Show>
            <Cell>
                <WaitingListTags tags={item.Surgeons} />
            </Cell>
            <Cell>
                <WaitingListTags tags={item.SurgeryType} />
            </Cell>
            <Cell>{item.KnifeTime}</Cell>
        </tr>
    )
}
