import 'whatwg-fetch' // this removes warning due to fetch is not accessible in test environment

import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import isString from 'lodash/isString'

import env from '~/env'
import { useStore } from '~/store/store'

function prepareHeaders(headers: Headers) {
    const subscriptionKey = env.VITE_DIPS_SUBSCRIPTION_KEY
    if (subscriptionKey && subscriptionKey.trim() !== '') {
        headers.set('dips-subscription-key', subscriptionKey)
    }

    const dipsApiAccessToken = useStore.getState().user.dipsApiAccessToken
    if (isString(dipsApiAccessToken)) {
        headers.set('Authorization', `Bearer ${dipsApiAccessToken}`)
    }

    return headers
}

const baseQuery = fetchBaseQuery({
    baseUrl: env.VITE_DIPS_BASE_URL,
    prepareHeaders,
    mode: 'cors',
})

const baseQueryWithAuthErrorHandling: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)

    const hasDipsCredentials = useStore.getState().user.dipsApiAccessToken !== null
    const isExpiredDipsSession = hasDipsCredentials && result.error?.status === 401

    if (isExpiredDipsSession) {
        const diApiRevocationURL = new URL(env.VITE_API_BASE_URL + '/oauth/revocation')

        fetch(diApiRevocationURL.toString(), {
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.json())
            .then(responseData => {
                const data = responseData as { success: boolean }
                if (data.success === true) {
                    window.location.reload()
                }
            })
            .catch(err => console.error(err))
    }

    return result
}

export const emptyDipsApi = createApi({
    reducerPath: 'dipsApi',
    baseQuery: baseQueryWithAuthErrorHandling,
    endpoints: () => ({}),
})
