import React from 'react'

type Props = { label: string; children?: React.ReactNode | string }

export const BookingInfo = ({ label, children }: Props) => {
    return (
        <div className="grid grid-cols-[120px_1fr] text-base">
            <dt className="text-gray-500">{label}</dt>
            {React.isValidElement(children) ? children : <dd>{children}</dd>}
        </div>
    )
}
